import AxiosServices from './axios-service';
import {endpoints} from './api-end-points';

export class OrderService {

  checkout(body){    
    const res = AxiosServices.post(endpoints.order.checkout, body);
    return res;
  }

  get_new_orders(){
    const res = AxiosServices.get(endpoints.order.get_new_orders);
    return res;
  }

  update_noti_status(){
    const res = AxiosServices.get(endpoints.order.update_noti_status);
    return res;
  }

}
const orderService = new OrderService();
export default orderService;
