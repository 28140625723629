import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Modal from '@mui/material/Modal';
import logo from '../../../../../assets/images/logo/logo.png';
import router from '../../../../../routePaths/routePaths';

const modelStyle = {
    position: 'absolute',
    top: '-280px',
    left: '50%',
    transform: 'translate(-50%, 17%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 0,
  };

export default function HeaderView(props) {
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    return (
        <AppBar position="static">
            <div className="container d-none d-xl-block">
                <Toolbar>
                    <div className='row'>
                        <div className="col-md-4 inline-flex align-items-center">
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon 
                                    onClick={()=>props.onOpenDrawer('Men')}
                                /> 
                            </IconButton>
                            <Typography component="div" className='me-4' sx={{cursor:'pointer'}} onClick={()=>props.onOpenDrawer('Men')}>
                                SHOP NOW
                            </Typography>
                        </div>
                        <div className="col-md-4 inline-flex place-content-center">
                            <Link to={{pathname: router.home}} style={{ textDecoration: 'none', display: 'flex' }}>
                                <Typography component="div" className='me-4' sx={{cursor:'pointer'}}>
                                    <img src={logo} width='60px' alt='loading'/> 
                                </Typography>
                            </Link>
                            {/* <Typography variant="subtitle1" component="div" className='me-4' sx={{cursor:'pointer'}}>
                                MEN
                            </Typography>
                            <Typography variant="subtitle1" component="div" className='me-4' sx={{cursor:'pointer'}}>
                                WOMEN
                            </Typography>
                            <Typography variant="subtitle1" component="div" className='me-4' sx={{cursor:'pointer'}}>
                                KIDS
                            </Typography>
                            <Typography variant="subtitle1" component="div" className='me-4' sx={{cursor:'pointer'}}>
                                MUSIC
                            </Typography>
                            <Typography variant="subtitle1" component="div" className='me-4' sx={{cursor:'pointer'}}>
                                SPORT
                            </Typography>
                            <Typography variant="subtitle1" component="div" className='me-4' sx={{cursor:'pointer'}}>
                                RELEASES
                            </Typography>
                            <Typography variant="subtitle1" component="div" className='me-4' sx={{cursor:'pointer'}}>
                                SALE
                            </Typography> */}
                        </div>
                        <div className="col-md-4 inline-flex justify-content-end">
                            <Paper
                                component="form"
                                sx={{ display: 'flex', alignItems: 'center', width: 500, borderRadius: '30px', height: '40px', alignSelf: 'center' }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search brands or products"
                                    onClick={()=>props.setOpenSearchModal()}
                                    onChange={props.handleChange}
                                />
                                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={(e)=>setAnchorEl(e.currentTarget)}
                                color="inherit"
                            >
                                <PermIdentityOutlinedIcon />
                            </IconButton>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={()=>props.setCartDrawer()}
                                color="inherit"
                            >
                                <ShoppingCartOutlinedIcon />
                            </IconButton>
                        </div>
                    </div>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={()=>setAnchorEl(null)}
                    >
                        {props.isLogged === null && <>
                            <MenuItem onClick={()=>navigate(router.signup)}>Register</MenuItem>
                            <MenuItem onClick={()=>navigate(router.login)}>Login</MenuItem>
                        </>}
                        {props.isLogged !== null && JSON.parse(props.isLogged).user_type !== 'admin' && <>
                            <MenuItem onClick={()=>navigate(router.profile)}>Profile</MenuItem>
                            <MenuItem onClick={()=>navigate(router.history)}>Order History</MenuItem>
                            <MenuItem onClick={props.logout}>Logout</MenuItem>
                        </>}
                        {props.isLogged !== null && JSON.parse(props.isLogged).user_type === 'admin' && <>
                            <MenuItem onClick={()=>navigate(router.dashboard)}>Dashboard</MenuItem>
                            <MenuItem onClick={props.logout}>Logout</MenuItem>
                        </>}
                    </Menu>
                    <Modal
                        backdrop="static"
                        open={props.openSearchModal}
                        onClose={()=>props.setCloseSearchModal()}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        disableScrollLock={true}
                        disableEnforceFocus={true}
                        disableAutoFocus={true}
                        disableRestoreFocus={true}
                        sx={{top:'42%'}}
                        BackdropProps= {{
                            classes: {
                                root: "modal-backdrop"
                            }
                        }}
                    >
                        <Box sx={modelStyle}>
                            <div className="row">
                                <div className='col-9 p-5'>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" className="font-bold">
                                        POPULAR PRODUCTS
                                    </Typography>
                                    <div className='row'>
                                        {props.homeRecord.featured_week !== undefined && 
                                        props.homeRecord.featured_week.map((x,index)=>{
                                            return(
                                                <div className='col-2 text-center my-2 me-3 p-0'>
                                                    <Paper elevation={0} sx={{cursor: 'pointer'}} onClick={()=>navigate(router.product+"/"+x.id)}>
                                                        <img src={JSON.parse(x.pro_image)[0]} width='100%' height="160px" className='my-2' alt={index}/>
                                                        <img src={x.brand_image} width='50%' height="35px" className='my-2' alt={index}/>
                                                        <Typography variant="subtitle2" component="h2" className="font-bold text-nowrap my-1">
                                                            {x.pro_name}
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="h2" className="text-nowrap my-1">
                                                            Rs.{x.pro_price} /-
                                                        </Typography>
                                                    </Paper>
                                                </div>
                                            )
                                        })}
                                        {props.homeRecord.fit_rotation !== undefined && 
                                        props.homeRecord.fit_rotation.map((x,index)=>{
                                            return(
                                                index !== 2 && <div className='col-2 text-center my-2 me-3 p-0'>
                                                    <Paper elevation={0} sx={{cursor: 'pointer'}} onClick={()=>navigate(router.product+"/"+x.id)}>
                                                        <img src={JSON.parse(x.pro_image)[0]} width='100%' height="160px" className='my-2' alt={index}/>
                                                        <img src={x.brand_image} width='50%' height="35px" className='my-2' alt={index}/>
                                                        <Typography variant="subtitle2" component="h2" className="font-bold text-nowrap my-1">
                                                            {x.pro_name}
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="h2" className="text-nowrap my-1">
                                                            Rs.{x.pro_price} /-
                                                        </Typography>
                                                    </Paper>
                                                </div>
                                            )
                                        })}
                                        {props.homeRecord.featured_week !== undefined && 
                                        props.homeRecord.featured_week.length === 0 &&
                                            <div className='col-2 text-center my-2 me-3 p-0'>
                                                <Paper elevation={0} sx={{cursor: 'pointer'}}>
                                                    <Typography variant="subtitle2" component="h2" className="text-nowrap my-1">
                                                        Products not found...
                                                    </Typography>
                                                </Paper>
                                            </div>
                                        }
                                    </div>
                                    <Typography 
                                        variant="subtitle1" 
                                        component="h2" 
                                        className="font-bold text-nowrap my-1 pointer"
                                        onClick={()=>navigate(router.collections)}>
                                        VIEW ALL NEW ARRIVALS
                                    </Typography>
                                </div>
                                <div className='col-3 p-0 text-center'>
                                    <Paper elevation={0} className='py-5 px-4 bg-gray' sx={{height: '100%'}}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2" className="font-bold">
                                            POPULAR BRANDS
                                        </Typography>
                                        <div className='row mt-1'>
                                        {props.homeRecord.brands !== undefined && 
                                        props.homeRecord.brands.map((x,index)=>{
                                            return(
                                            index !==6 && <div className="col-6 p-1">
                                                <Paper className='mt-1' sx={{cursor: 'pointer'}} onClick={()=>navigate(router.collections+"/brand="+x.id)}>
                                                    <img src={x.brand_image} width='100%' height='70px' alt={index}/>
                                                </Paper>
                                            </div>
                                            )
                                        })}
                                        {props.homeRecord.brands !== undefined && 
                                        props.homeRecord.brands.length === 0 && 
                                            <div className="col-6 p-1">
                                                <Typography variant="subtitle2" component="h2" className="text-nowrap my-1">
                                                    Brands not found...
                                                </Typography>
                                            </div>
                                        }
                                            <Button className="btn-black mt-3" onClick={()=>navigate(router.brands)}>
                                                VIEW ALL BRANDS
                                            </Button>
                                        </div>
                                    </Paper>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </Toolbar>
            </div>
            <Toolbar className="d-none d-xl-block" sx={{background: props.color, lineHeight: 4, minHeight: 0}}>
                <div className="row px-2">
                    <div className="col-md-4 align-self-center text-start">
                        <Button type='button' className='btn-transparent font-14' onClick={()=>navigate(router.collectionsList+'/exclusive_heat')}>
                            SHOP EXCLUSIVE HEAT
                        </Button>
                    </div>
                    <div className="col-md-4 align-self-center text-center">
                        <Typography variant="subtitle1" className="font-14 font-bold nowrap pointer" onClick={()=>navigate(router.collections)}>
                            500+ New Arrivals Weekly. Shop Now!
                        </Typography>
                    </div>
                    <div className="col-md-4 align-self-center text-end">
                        <Button type='button' className='btn-transparent font-14' onClick={()=>navigate(router.collectionsList+'/sale')}>
                            SHOP sALE
                        </Button>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
}
