import React, { useState, useEffect } from "react";
import productsService from "../../../../../services/products-service";
import HeaderMobileView from "./HeaderMobileView";
import HeaderView from "./HeaderView";
import LeftDrawer from "./LeftDrawer";
import Spinner from "../../../../../shared/Spinner";
import searchService from "../../../../../services/search-service";
import CartDrawer from "./CartDrawer";

export default function Header(prop) {
    const quantity = 1;
    const [isLogged, setLogged] = useState(null);
    const [leftDrawer, setLeftDrawer] = useState(false);
    const [cartDrawer, setCartDrawer] = useState(false);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    const [openMobileSearchModal, setOpenMobileSearchModal] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [tabData, setTabData] = useState([]);
    const [searchRecord, setSearchRecord] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    
    const getRecord=async() => {
        setSpinner(true);
        const res = await productsService.getHomeRecord();
        setSearchRecord(res.data);
        setFilteredData(res.data);
        setSpinner(false);
    }

    const onOpenDrawer = async(cat) => {
        setOpenSearchModal(false)
        setSpinner(true);
        const res = await productsService.getCollectionsByCat({category: cat});
        setTabData(res.data);
        setSpinner(false);
        setLeftDrawer(true)
    }

    const handleChange = async(e) => {
        if(e.target.value === ""){
            setFilteredData(searchRecord);
            return;
        }
        const res = await searchService.searchBrandProducts({query: e.target.value});
        setFilteredData(res.data);
    }

    const getCartItem=()=>{
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if(items !== null){
            setCartItems(items);
        }
    }

    const removeItem=(index)=>{
        let array = cartItems;
        array.splice(index, 1)
        localStorage.setItem('cartItems',JSON.stringify(array));
        getCartItem();

    }

    const updateQuantity=(index,value)=>{
        console.log(index,value);
        let array = cartItems;
        array[index].quantity = (value === 'increase') ? array[index].quantity + 1 : array[index].quantity - 1
        array[index].total_price = (array[index].dis_price !== 0) ? 
            parseInt(array[index].dis_price)*array[index].quantity : 
            parseInt(array[index].pro_price)*array[index].quantity;
        localStorage.setItem('cartItems',JSON.stringify(array));
        getCartItem();
    }

    const logout = () =>{
        localStorage.removeItem('isLogged');
        setLogged(localStorage.getItem('isLogged'))
    }

    useEffect(()=>{
        getRecord();
        getCartItem();
        setLogged(localStorage.getItem('isLogged'))
    },[])
    
    return (
        <div>
            <HeaderView
                openSearchModal={openSearchModal}
                homeRecord = {filteredData}
                color={prop.color}
                isLogged={isLogged}
                
                onOpenDrawer={(cat)=>onOpenDrawer(cat)}
                setOpenSearchModal={()=>setOpenSearchModal(true)}
                setCloseSearchModal={()=>setOpenSearchModal(false)}
                handleChange={(e)=>handleChange(e)}
                logout={()=>logout()}
                setCartDrawer={()=>{setCartDrawer(!cartDrawer);getCartItem();}}
            />
            <HeaderMobileView
                openMobileSearchModal={openMobileSearchModal}
                homeRecord = {filteredData}
                color={prop.color}
                isLogged={isLogged}

                onOpenDrawer={(cat)=>onOpenDrawer(cat)}
                setOpenMobileSearchModal={()=>setOpenMobileSearchModal(true)}
                setCloseMobileSearchModal={()=>setOpenMobileSearchModal(false)}
                handleChange={(e)=>handleChange(e)}
                setCartDrawer={()=>{setCartDrawer(!cartDrawer);getCartItem();}}
                logout={()=>logout()}
            />
            <LeftDrawer
                leftDrawer={leftDrawer}
                tabData={tabData}
                isLogged={isLogged}

                onOpenDrawer={(cat)=>onOpenDrawer(cat)}
                setLeftDrawer={()=>setLeftDrawer(false)}
                setOpenSearchModal={()=>{setOpenSearchModal(true);setLeftDrawer(false)}}
                setOpenMobileSearchModal={()=>{setOpenMobileSearchModal(true);setLeftDrawer(false)}}
                handleChange={(e)=>handleChange(e)}
                logout={()=>logout()}
            />
            <CartDrawer
                cartDrawer={cartDrawer}
                leftDrawer={leftDrawer}
                tabData={tabData}
                cartItems={cartItems}
                quantity={quantity}
                
                updateQuantity={(index,value)=>updateQuantity(index,value)}
                onOpenDrawer={(cat)=>onOpenDrawer(cat)}
                setCartDrawer={()=>setCartDrawer(!cartDrawer)}
                removeItem={(index)=>{removeItem(index)}}
            />
            <Spinner
                spinner = {spinner}
            />
        </div>
    );
}
