import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {Paper,CircularProgress,Box,Typography,Button} from '@mui/material';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import logoSpinner from "../../../../assets/images/logo/logo_spinner.png";
import router from "../../../../routePaths/routePaths";

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

export default function CollectionsView(props) {
    let navigate = useNavigate();
    useEffect(async()=>{
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    },[])

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
    
    const onScroll = e => {
        const wrappedElement = document.getElementById('scrollDiv');
        if (isBottom(wrappedElement)) {
            props.bottomReached();
        }
    };
    return (
        <div className="container" id='scrollDiv' style={{height: (props.collections.length === 0) ? '100vh' : ''}}>
            {props.collections.map((x,index) => {
                console.log(x);
                return(
                    <div>
                        <div className="py-5">
                            <h4 className="font-bold black-outline m-0 mb-4">
                                <img src={logoSpinner} width="50px" alt='loading'/>
                            </h4>
                            <Paper 
                                elevation={0} 
                                sx={{cursor: 'pointer', height: '50vh', overflow: 'hidden'}} 
                                square
                                onClick={()=>navigate(router.collections+"/"+x.c_id)}
                            >
                                <img src={x.col_image} width='100%' className='zoom p-0' alt={index} />
                            </Paper>
                        </div>
                        <div>
                            <Carousel 
                                autoPlay={false}
                                responsive={responsive}>
                                    {x.products.map((y,z)=>{
                                        return(
                                            <div className="m-3 text-center pointer" onClick={()=>navigate(router.product+"/"+y.p_id)}>
                                                <img src={JSON.parse(y.pro_image)[0]} width='100%' height= "200px" alt={y}/>
                                                <img src={y.brand_image} width='100px' height={'40px'} className="my-2" alt={y}/>
                                                <h5>{y.pro_name}</h5>
                                                <div className="sale-price">
                                                    {y.dis_price === '0' && <p style={{margin: 0}}>Rs. {y.pro_price} /- </p>}
                                                    {y.dis_price !== '0' && <span>Sale Rs. {y.dis_price} /- </span>}
                                                    {y.dis_price !== '0' && <del>Rs. {y.pro_price} /-</del>}
                                                </div>
                                            </div>
                                        )
                                    })}
                            </Carousel>
                            <Button type='button' className='btn-white m-3' sx={{width: '150px'}} onClick={()=>navigate(router.collections+"/"+x.c_id)}>
                                SHOP ALL
                            </Button>
                        </div>
                    </div>
                )
            })}
            {props.isLoading && <div className="text-center" style={{width:'100%'}}>
                <Box position="relative" display="inline-flex">
                    <CircularProgress sx={{color: '#734d03'}}/>
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="caption" component="div" color="textSecondary">
                            <img src={logoSpinner} width= '50px' alt='loading'/>
                        </Typography>
                    </Box>
                </Box>
            </div>}
        </div>
    );
}
