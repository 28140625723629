import React from "react";
import { useNavigate  } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {
    Paper, Table, TableBody, 
    TableCell, TableHead, TableRow, Button,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { 
    BrandingWatermarkOutlined,
    Inventory2Outlined,
    Category
  } from '@mui/icons-material';
import Sidenav from "../Common/Sidenav/Sidenav";
import SmallCard from "../../../../components/SmallCard";
import router from "../../../../routePaths/routePaths";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

export default function DashboardView(prop) {
    const navigate = useNavigate ();

    return (
        <div className="main-container my-3">
            <div className="row m-2">
                <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                    <Sidenav/>
                </div>
                <div className="col-lg-10 col-xl-10 black-corner">
                    <div className="row">
                        <div className="col-md-4">
                            <SmallCard
                                icon = {<Category sx={{fontSize: '60px'}}/>}
                                heading = {prop.dashboard.collectionsLength}
                                caption = {'Collections'}
                                buttonText = {'Add New Collection'}
                                router = {router.adminCollections}
                            />                      
                        </div>
                        <div className="col-md-4">
                            <SmallCard
                                icon = {<BrandingWatermarkOutlined sx={{fontSize: '60px'}}/>}
                                heading = {prop.dashboard.brandsLength}
                                caption = {'Brands'}
                                buttonText = {'Add New Brand'}
                                router = {router.adminBrands}
                            />                      
                        </div>
                        <div className="col-md-4">
                            <SmallCard
                                icon = {<Inventory2Outlined sx={{fontSize: '60px'}}/>}
                                heading = {prop.dashboard.productsLength}
                                caption = {'Products'}
                                buttonText = {'Add New Product'}
                                router = {router.adminProducts}
                            />                      
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Paper elevation={3} sx={{ display: 'flex', p: 1, my:1, overflow: 'auto', height: '97%'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={5} className="text-center font-para px-1 py-2 text-nowrap text-blue font-bold">COLLECTIONS</TableCell>
                                        </TableRow>
                                        <TableRow className="bg-grey">
                                            <StyledTableCell>NAME</StyledTableCell>
                                            <StyledTableCell align="right">IMAGE</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {prop.dashboard['collections'].map((x)=>{
                                            return(
                                            <TableRow>
                                                <TableCell>{x.col_name}</TableCell>
                                                <TableCell align="right">
                                                    <img src={x.col_image} width='60px' alt="loading"/>
                                                </TableCell>
                                            </TableRow>
                                            )
                                        })}
                                        <TableRow>
                                            <TableCell colSpan={5} className="border-0 text-center font-para px-1 py-3 text-nowrap font-bold">
                                                <Button className="font-bold" onClick={()=>navigate(router.adminCollections)} sx={{verticalAlign: 'bottom'}}>
                                                    View All Collections
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </div>
                        <div className="col-md-6">
                            <Paper elevation={3} sx={{ display: 'flex', p: 1, my:1, overflow: 'auto', height: '97%'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={5} className="text-center font-para px-1 py-2 text-nowrap text-blue font-bold">BRANDS</TableCell>
                                        </TableRow>
                                        <TableRow className="bg-grey">
                                            <StyledTableCell>NAME</StyledTableCell>
                                            <StyledTableCell align="right">IMAGE</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {prop.dashboard['brands'].map((x)=>{
                                            return(
                                            <TableRow>
                                                <TableCell>{x.brand_name}</TableCell>
                                                <TableCell align="right">
                                                    <img src={x.brand_image} width='60px' alt="loading"/>
                                                </TableCell>
                                            </TableRow>
                                            )
                                        })}
                                        <TableRow>
                                            <TableCell colSpan={5} className="border-0 text-center font-para px-1 py-3 text-nowrap font-bold">
                                                <Button className="font-bold" onClick={()=>navigate(router.adminBrands)} sx={{verticalAlign: 'bottom'}}>
                                                    View All Brands
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </div>
                        <div className="col-md-6">
                            <Paper elevation={3} sx={{ display: 'flex', p: 1, my:1, overflow: 'auto', height: '97%'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={5} className="text-center font-para px-1 py-2 text-nowrap text-blue font-bold">
                                                PRODUCTS
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="bg-grey">
                                            <StyledTableCell>NAME</StyledTableCell>
                                            <StyledTableCell align="right">IMAGE</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {prop.dashboard['products'].map((x)=>{
                                            let images = JSON.parse(x.pro_image);
                                            return(
                                            <TableRow>
                                                <TableCell>{x.pro_name}</TableCell>
                                                <TableCell align="right">
                                                    {images.map(y => {
                                                        return(
                                                            <img src={y} width='60px' alt="loading"/> 
                                                        )
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                            )
                                        })}
                                        <TableRow>
                                            <TableCell colSpan={5} className="border-0 text-center font-para px-1 py-3 text-nowrap font-bold">
                                                <Button className="font-bold" onClick={()=>navigate(router.adminProducts)} sx={{verticalAlign: 'bottom'}}>
                                                    View All Products
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
