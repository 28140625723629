import React from "react";
import {
    Paper, Divider
} from '@mui/material';


export default function PrivacyView(props) {

    return (
        <div className="container">
            <h3 className="my-4 text-center font-bold">PRIVACY POLICY</h3>
            <Divider />
            <Paper elevation={1} sx={{p: 2, mb: 3}} square>
                <div className="row" style={{fontSize: '13px'}}>
                <p>At Kultur King, trust is the cornerstone of a great br/and and respecting the privacy rights of our customers is an integral part of building that trust. In this privacy policy, the terms &ldquo;Kultur King,&rdquo; &ldquo;we,&rdquo; and &ldquo;us&rdquo; refers to Kultur King, its parent company a.k.a. br/ands Holding Corp., and a.k.a. br/ands Holding Corp. respective subsidiaries and affiliated companies. This privacy policy explains how we collect and use your personal information to serve you. &nbsp;By accessing or using this Website, you agree to this privacy policy. If you do not agree to this privacy policy, please discontinue your use of this website (&ldquo;Website&rdquo;).</p>
<h2>YOUR RIGHT TO CONTROL HOW YOUR PERSONAL INFORMATION IS USED</h2>
<p><strong>In Short:</strong> <em>You have the right to make requests regarding your personal information. While these rights may vary based on your residency, we strive to allow you to control how your personal information is used.&nbsp;</em></p>
<p>You can:</p>
<ul>
    <li>Review the personal information we have about you or request a copy of it;</li>
    <li>Ask&nbsp;us to update or correct personal information if it is inaccurate;</li>
    <li>Request that we delete your personal information;</li>
    <li>Opt-out of sharing your personal information with third parties for some purposes, including sharing that may be defined as a sale under applicable laws;</li>
    <li>Object to certain types of uses of your personal information;</li>
    <li>Make choices about receiving email marketing; and&nbsp;</li>
    <li>Tell us which cookies you want us to use when you shop online by clicking on the &quot;Interest Based Ads&quot; link at the bottom of&nbsp;our Canadian websites or using the cookie banners on our United Kingdom or European Union websites.</li>
</ul>
<p>These rights vary depending on where you reside. But we will make reasonable efforts to honor your request, even if your country or state does not require us to do so. &nbsp;If you ask us to delete or stop using your personal information, we may not be able to honor that request if the information is required to process your payments or returns, fulfill your order, or comply with tax, audit or regulatory requirements.</p>
<h2>TYPES OF INFORMATION WE COLLECT</h2>
<p><strong>In Short:</strong> <em>We collect information about you such as your contact information, payment information, demographic information, shopping preferences and information about the devices you use to shop online.&nbsp;</em></p>
<p>We do not knowingly&nbsp;collect personal information directly from children under the age of 13 (16 in the United Kingdom/European Union) without parental consent. Our websites are general audience sites and are not specifically&nbsp;targeted to or intended for use by children.</p>
<p>We need to collect personal information in order to provide the products and services you request. &nbsp;If you do not provide the information requested, we may not be able to provide you with these products and services. &nbsp; &nbsp;&nbsp;</p>
<p><strong>What information do we collect?</strong> We may collect:&nbsp;</p>
<ul>
    <li>Contact information, such as name, email address, postal address, phone number;</li>
    <li>Payment information, such as credit card number, bank card numbers, cheque, and other payment details;</li>
    <li>Order and refund history, including sizing;</li>
    <li>Demographic information, such as age, estimated income, household information, and occupation;</li>
    <li>Information about your interests, preferences, and purchase history;</li>
    <li>Information may be collected from you if you interact with us on social media sites, such as Facebook, Instagram, and Snapchat;</li>
    <li>Information related in signing up to become a br/and ambassador, influencer</li>
    <li>Device and br/owser information including location information, MAC addresses, IP addresses, Cookies, and other online identifiers;</li>
    <li>If you disclose any personal information relating to other people to us or to our service providers, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy; and</li>
    <li>Any other information that you provide to us.</li>
</ul>
<h2>HOW WE COLLECT YOUR INFORMATION&nbsp;</h2>
<p><strong>In short:</strong> <em>We collect information about you in a variety of different ways as you navigate through and interact with our Website.&nbsp;</em></p>
<ul>
    <li>Information you provide to us, such as&nbsp;while making a purchase or signing up for a loyalty or rewards program.</li>
    <li>Information we collect while you interact with our websites, mobile or other apps, social media sites, and other products and services. &nbsp;This often involves information about your device such as IP addresses and MAC addresses, as well as information collected through <a href="https://www.kulturking.com.au/pages/privacy-policy#cookies-and-similar-technologies" title="Cookies">cookies</a>.&nbsp;</li>
    <li>Information we collect about you from other sources,&nbsp;such as credit card issuers and data analytics firms.</li>
    <li>Information we create ourselves after we analyze the information we collected about you, such as your shopping preferences.</li>
</ul>
<h3>Information You provide to Us</h3>
<ul>
    <li><strong>Online Order Information</strong><br/>When you make a purchase from our websites or mobile apps, we may ask you to provide us with information about your purchase such as your name, email address, shipping address, phone number, and payment information.</li>
    <li><strong>Online Accounts</strong><br/>If you sign up for an online account through our websites or mobile applications, we may ask you to provide your username, password, email address, phone number, payment card information, and product preferences. You do not have to create an online account to make a purchase. If you connect your social media account to your online account, you will share certain personal information from your social media account with us, for example, your name, email address, photo, list of social media contacts, and any other information that may be accessible to us when you connect your social media account to your online account.</li>
    <li><strong>Marketing Communications</strong><br/>If you would like to receive marketing communications from us, you may do so by signing up on our websites, or mobile applications. We will ask you to provide your email address or phone number so we can fulfill your request by sending you marketing materials.</li>
    <li><strong>Communicating with us</strong><br/>When you contact us about a customer service issue, or to make other inquiries, we collect the content of those communications, and any additional information about the method of communication such as phone, SMS, email, chat, or through social media platforms. For example, if you email us, we will collect your email address, if you send us a text message, we will collect your phone number, and so on.</li>
    <li><strong>Promotions</strong><br/>If you sign up for promotions or a contest, you may need to provide certain information such as your name, email, telephone number, or mailing address.</li>
</ul>
<h3>Information We Collect Through Your Interactions With Us</h3>
<ul>
    <li><strong>Websites and Mobile Apps</strong><br/>When you visit one of our websites or use our mobile apps we may collect your device identifier such as IP address or MAC address, as well as br/owser and settings information, shopping preferences, and information about how you interact with our websites and other digital products. We collect this information through our use of cookies or web beacons.</li>
    <li><strong>Investigations</strong><br/>We may collect information about you in connection with investigations into fraudulent or criminal activities on our websites, or mobile apps.</li>
    <li><strong>Other Sources</strong><br/>We may also collect information from other sources, including for example, joint marketing partners, data analytics firms, public databases, publicly available social media pages, and other third parties we work with.</li>
</ul>
<h3>Information We Aggregate and Derive</h3>
<p>We may aggregate and/or anonymize personal information so that it will no longer be considered personal information. We do so to generate other data for our use, which we may use and disclose for any purpose. For example, we may calculate the percentage of our site users who have a particular telephone area code.</p>
<h2>COOKIES AND SIMILAR TECHNOLOGIES</h2>
<p><strong>In Short:</strong> <em>Cookies are little bits of computer code that collect data by tagging along with you while you br/owse, but most importantly they allow us to provide you with the best br/owsing experience possible.</em></p>
<p>Most websites, including ours, wouldn&rsquo;t work very well without certain cookies. They allow us to prevent crashes, display information, fix bugs, and ensure the security of our site (and your account). These types of cookies are often called &ldquo;strictly necessary&rdquo; cookies. Because they are needed for our site to work, we don&rsquo;t provide you with an ability to opt-out of these cookies. But you can remove them by using your br/owser settings. Keep in mind that certain features of our site may not be available to you.</p>
<p>Some cookies provide important services but are not strictly necessary for our website to work. These cookies use information about your interactions with us so we can suggest clothes and special offers for you. If your shopping is interrupted, they remember where you left off and send you reminders that you truly deserve that new outfit. These reminders might be sent to you in an email, on social media or while you&rsquo;re br/owsing another website or on another device. We think these cookies are useful and will make it easier for you to shop with us. But you might disagree. If so, you can tell us not to use certain cookies by using the opt-out tools we have placed on our ecommerce websites. &nbsp;These options vary by geographic region.&nbsp;</p>
<h3>Third-Party Use of Cookies and Other Tracking Technologies</h3>
<p>Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
<p>We do not control these third parties&rsquo; tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see Your Choice.</p>
<h2>How We Use Your Information</h2>
<p><strong>In short:</strong> <em>We use the information that we collect to conduct our business and to provide you with the best possible products, services, and experiences. Which result in a combination of any or all of the information that we collect or obtain. Examples of how we use information include:</em></p>
<ul>
    <li><strong>To provide you with products and services</strong>, such as: to fulfill your orders and process your payments; process, maintain, and service your account(s) and memberships; and to provide you with products and services that we think you will like;</li>
    <li><strong>To respond to you</strong>, such as when you: place an order; call us; make a request or inquiry; enter a contest or sweepstakes; complete a survey; or share a comment or concern;</li>
    <li><strong>For personalization</strong> of your experiences with us, including to: remember your interests and preferences; customize the products and services we share with you; facilitate your interactions with our stylists and salespeople; track and categorize your activity and interests on our websites and mobile applications, including by identifying the different devices you use; and to enrich your experience on our websites, and through our mobile applications;</li>
    <li><strong>For marketing and advertising</strong>, such as: to send you marketing and advertising communications; and to customize the marketing and advertising that we show you (we offer choices about marketing and advertising as noted in the &quot;Your Choice&quot; section below);</li>
    <li><strong>For analytics</strong>, such as: to understand how you use our websites and mobile applications; understand how you shop with us; determine the methods and devices used to access our websites and mobile applications; and improve our websites and mobile applications;</li>
    <li><strong>For our business purposes</strong>, such as: to operate and improve upon our business and lawful business activities; to maintain our programs, accounts, and records; for research; to determine your satisfaction with our products and services; to detect and prevent fraud or misuse of our services; and for any other business purpose that is permitted by law; and</li>
    <li><strong>For legal and safety purposes</strong>, such as to: defend or protect us, our customers, you, or third parties, from harm or in legal proceedings; protect our rights; protect our security and the security of our customers, employees, and property; respond to court orders, lawsuits, subpoenas, and government requests; address legal and regulatory compliance; and notify you of product recalls or safety issues.</li>
    <li><strong>Anonymity</strong>
        <p>Where possible, we will allow you to interact with us anonymously. However, to perform many of our services and functions we may need your name and contact information.&nbsp;</p>
    </li>
</ul>
<h2>HOW WE SHARE PERSONAL INFORMATION&nbsp;</h2>
<p><strong>In Short:</strong> <em>We may share your personal information with&nbsp;our affiliated companies and&nbsp;br/ands, with third parties who provide services to you or us, with promotional partners, with social media companies, or with other third parties as needed to carry out our business, or comply with a legal obligation.</em></p>
<p><strong>Non-Personal Information</strong>. We may disclose aggregated information or other information that does not identify any individual without restriction.</p>
<p><strong>Personal Information</strong>. We may disclose personal information that we collect, or you provide as described in this privacy policy:</p>
<ul>
    <li><strong>Public posting and social sharing.</strong> When you post public comments on our websites, on public forums like our social media sites, blogs, and online reviews, your comments are accessible by other members of the public.</li>
    <li><strong>Social&nbsp;media logins and other integrations.</strong> When you log into your customer account using your social media account credentials, or use other social media services with us, your personal information will be disclosed to the social media account provider in connection with your login. By doing so, you authorize us to facilitate this sharing of information, and you understand that this&nbsp;sharing is governed by the social media site&rsquo;s privacy policy that you used to log into your account.</li>
    <li><strong>Kultur King service providers.</strong> Kultur King may use third parties to provide services to you or us. This may include managing customer information; fulfilling promotions; sending marketing communications; conducting surveys; hosting websites; analyzing data; processing payments; fulfilling and shipping orders; or providing network security, accounting, auditing, and other services. We would not authorize these service providers to use or disclose your information for purposes other than why we have retained them.</li>
    <li><strong>Third party partners</strong>. We may share information with other companies when we work with them to promote our products, services, offers, contests, or other promotions to our customers.</li>
    <li><strong>To fulfill legal obligations and with government agencies</strong>. We may share information about you if necessary or appropriate, in our good faith judgment, to comply with laws or regulations, or in response to a valid subpoena, order, or government request, or to protect the operations, privacy, safety, property or rights of Kultur King or others.</li>
    <li><strong>During a sale or business transaction</strong>. In the event of any potential or actual reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings), your personal information may be shared with third parties such as the acquiring entity and its advisors.</li>
    <li><strong>Providing you with Products and Services</strong>. We use your information to process, fulfill and communicate with you about your orders, respond to your customer service inquiries, allow you to use in-store and online technologies, enroll you in rewards and loyalty programs, and to inform you of policy or product changes that may impact you. When we use information in this way, we do so for the purpose of fulfilling a contract with you, or to comply with a legal obligation.</li>
</ul>
<h2>YOUR CHOICE</h2>
<p><strong>In Short:</strong> <em>We want you to be comfortable with the information we collect and use, therefore, we strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</em></p>
<ul>
    <li><strong>Tracking Technologies and Advertising.</strong> You can set your br/owser to refuse all or some br/owser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of this site may become inaccessible or not function properly. &nbsp; &nbsp;&nbsp;</li>
    <li><strong>Promotional Offers.</strong> If you do not wish to have your email address/contact information used by Kultur King to promote our own or third parties&rsquo; products or services, you can opt-out by unchecking the relevant box located on the Contact Information form during the checkout process. If you don&rsquo;t want us to send you marketing messages anymore, you can reach out to us here:&nbsp;<a href="mailto:info@kulturking.com">info@kulturking.com</a> or just unsubscribe from the link in one of our email messages or reply &ldquo;STOP&rdquo; for SMS. This opt out does not apply to information provided to Kultur King as a result of a product purchase, warranty registration, product service experience or other transactions.</li>
    <li><strong>Non-promotional Purposes.</strong> If you have opted out of receiving marketing or promotional communications from us, we may still contact you for transactional or informational purposes (such as for customer service). We may also still contact you to comply with our legal or other compliance obligations. &nbsp; &nbsp; &nbsp;</li>
    <li><strong>Text marketing and Notifications.</strong> By entering your phone number in the checkout and initializing a purchase, subscribing via our subscription form or a keyword, you agree that we may send you text notifications (for your order, including abandoned cart reminders) and text marketing offers. Text marketing messages will not exceed 30 a month. You acknowledge that consent is not a condition for any purchase. If you wish to unsubscribe from receiving text marketing messages and notifications reply with STOP to any mobile message sent from us or use the unsubscribe link we provided you with in any of our messages. You understand and agree that alternative methods of opting out, such as using alternative words or requests will not be accounted as a reasonable means of opting out. Message and data rates may apply.&nbsp;<strong>For any questions please text HELP to the number you received the messages from. You can also contact us for more information. If you wish to opt out, please follow the procedures above.</strong></li>
</ul>
<h3>Accessing and Correcting Information.</h3>
<p>You can review and change your personal information by logging into the Website and visiting your account profile page.</p>
<p>You may also send us an email at&nbsp;<a href="mailto:info@kulturking.com">info@kulturking.com</a> to request access to, correct or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
<h2>YOUR OBLIGATIONS AND RIGHTS</h2>
<p>Under circumstances, you have the right of access, rectification, erasure, restriction of and objection to processing of Personal Information, the right to data portability, the right to withdraw any consent at any time without affecting the lawfulness of processing based on consent before such withdrawal and the right to lodge a complaint with a supervisory authority.</p>
<p>To invoke your rights please refer to the contact information under Contact Information.&nbsp;</p>
<p>Unless specified otherwise, Personal Information requested when using the Platforms are voluntary, which means that it is not a statutory or contractual requirement, or a requirement necessary to enter into a contract. Users are free not to provide such Personal Information without consequences to the availability or the functioning of the Platforms or the Services. Where the provision of Personal Information is mandatory, such a requirement is stated. In that case, failure to provide such Personal Information may make it impossible for the Platform(s) to work properly or for us to provide any Services to you.</p>
<p>Kultur King does not conduct any automated decision making through or for the Platforms or Services, including profiling.&nbsp;</p>
<h2>HOW WE STORE AND SECURE YOUR INFORMATION</h2>
<p><strong>In Short:</strong> <em>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.&nbsp;</em></p>
<p>Kultur King is a global company with a global footprint, but the majority of the Personal Information that we collect is stored in the United States and Australia. &nbsp;Your personal information may be subject to the laws of the countries in which it is stored or used. By providing us with any personal information, you understand that your information may be transferred, processed, or stored outside of your country of residence, including the United States, which may have a different data protection regime from the country in which you reside. In certain circumstances, courts, law enforcement agencies, regulatory agencies, or security authorities in those other countries may be entitled to access your personal information. Non-US based customers can read more about this topic below.</p>
<h3>Data Transfers outside of the US.</h3>
<p>Personal Information that is generated through the use of the Platforms may be stored on locations other than where it was initially collected. When transferred, we will ensure this policy and applicable laws and legislations, such as the GDPR, the United Kingdom Data Protection Act of 2018, and the Australian Privacy Act, are complied with. Where applicable, you may obtain a copy of the applicable safeguards. Please consult our Data Protection Officer for more information. &nbsp;&nbsp;</p>
<h3>Information Retention.</h3>
<p>We will retain your personal information for as long as needed to provide the service to you (for example, as long as you have an online account with us) or as needed to fulfill our own obligations, such as preventing fraud, meeting regulatory requirements, resolving disputes, improving our services or maintaining security, and in each case, as consistent with applicable law. We may retain non-personal information that has been sufficiently aggregated or anonymized for a longer period.</p>
<h3>Information Security.&nbsp;</h3>
<p>We do our best to provide you with a safe and convenient shopping experience. We have put in place a wide variety of technical and organizational security measures to help protect you and the personal information we collect about you. Please note that we will never email you asking for account information like your username, date of birth, credit card information, or other personal information. Finally, we will never email you with attachments that can be opened. If you received a suspicious email that appears to be from us, please&nbsp;contact us.</p>
<h3>Third Party Services.</h3>
<p>We are not responsible for, and this policy does not address, the privacy practices of other third parties, such as Facebook, Apple, Google, Microsoft, or any other app developers, social media platforms, operating system providers, wireless or telecommunications service providers, or device manufacturers. The inclusion of a link on our services does not imply an endorsement of the linked sites or services by us or our affiliates.</p>
<h3>Sensitive Information.&nbsp;</h3>
<p>Unless we request it, we ask that you not send us, and you not disclose, any sensitive personal information (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background, or trade union membership) on or through our services or otherwise to us.</p>
<h3>Third Party Payment Service.</h3>
<p>We make certain third-party payment service processors available to you such as PayPal or Apple Pay. &nbsp;If you wish to use one of these services, your personal information will be collected by such a third party and will be subject to the third party&rsquo;s privacy policy. We have no control over, and are not responsible for this third party&rsquo;s collection, use, and disclosure of your personal information.</p>
<h2>CHANGES TO OUR PRIVACY POLICY</h2>
<p>We will post updates to our privacy policy on our website. Please check our Privacy Policy periodically for changes. We will post the date our Policy was last updated at the top of the Privacy Policy.</p>
<h2>CONTACT INFORMATION</h2>
<p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
<p>Email:&nbsp;<a href="mailto:info@kulturking.com">info@kulturking.com</a>&nbsp;</p>
<p>Please note that the legal department of Kultur King also receives a copy of your message via this email address. We aim to respond within 30 days from the date we receive privacy-related communications.</p>
<p><br/></p>
                </div>
            </Paper>
        </div>
    );
}
