import React, { useState, useEffect } from "react";
import SnackAlert from "../../../../shared/SnackAlert";
import Spinner from "../../../../shared/Spinner";
import Header from "../Common/Header/Header";
import authServices from "../../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import router from "../../../../routePaths/routePaths";
import LoginView from "./LoginView";

export default function Login() {
    let navigate = useNavigate();
    const color = '#a2a2a2';
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [spinner, setSpinner] = useState(false);
    const [forget, setForget] = React.useState(false);
    const [inputField, setInputField] = useState({});

    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        if(!forget){
            const res = await authServices.login(inputField);
            if(res.data.error){
                setAlert(true);
                setSeverity('error');
                setMsg(res.data.message)
            }else{
                localStorage.setItem('isLogged', JSON.stringify(res.data[0]))
                if(res.data[0].user_type === 'admin'){
                    navigate(router.dashboard)
                }else{
                    navigate(router.home)
                }
            }
        }else{
            const res = await authServices.resetPassword({email: inputField['email']});
            if(res.data.error){
                setAlert(true);
                setSeverity('error');
                setMsg(res.data.message)
            }else{
                setAlert(true);
                setSeverity('success');
                setMsg(res.data.message)
                setForget(false)
            }
        }
        setSpinner(false);
    }

    useEffect(()=>{
    },[])
    
    return (
        <div>
            <Header 
                color={color}
            />
            <LoginView
                inputField={inputField}
                forget = {forget}

                setForget = {()=>setForget(!forget)}
                handleChange = {(e)=>handleChange(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}
