import React, {useState} from 'react';
import { Link } from "react-router-dom";
import {
  Paper,TextField,Button,InputAdornment, IconButton
} from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import router from '../../../../routePaths/routePaths';

export default function LoginView(props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className='container'>
      <div className='row place-content-center'>
        <div className="col-md-8 cart-detail">
          <Paper elevation={1} sx={{background: '#eee'}}>
            <p className="new-to">WELCOME BACK</p>
            <form className='pt-3' onSubmit={props.handleSubmit}>
              {props.forget && <p className='reg-with'>RESET PASSWORD</p>}
              {!props.forget && <p className='reg-with'>LOGIN IN</p>}
              <div className='container'>
                <div className="row">
                  <div className='col-lg-12'>
                    <TextField
                      id="outlined-basic" 
                      label="Email" variant="outlined" 
                      name="email" value={props.inputField['email']} 
                      className="width-100 mt-2" onChange={props.handleChange}
                      required size='small'/>
                  </div>
                  {!props.forget && <div className='col-lg-12'>
                    <TextField
                      id="outlined-basic" 
                      label="Password" variant="outlined" 
                      name="password" 
                      type={(showPassword) ? 'text':'password'} value={props.inputField['password']} 
                      className="width-100 mt-2" onChange={props.handleChange}
                      required size='small'
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                            onClick={()=>setShowPassword(!showPassword)}
                            edge="end"
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }}/>
                  </div>}
                  <div className='col-12 text-end'>
                    {!props.forget && <Button onClick={props.setForget}>Forget Password</Button>}
                  </div>
                </div>
                <div className='text-center'>
                  {!props.forget && <Button type="submit" className="btn-black px-3 fit-content mt-2">
                    Login
                  </Button>}
                  {props.forget && <Button type="submit" className="btn-black px-3 fit-content mt-3">
                    Reset Password
                  </Button>}
                </div>
                <div className='row accept-terms text-center py-3'>
                    {props.forget && <Button onClick={props.setForget}>Back to login</Button>}
                    {!props.forget && <span><Link to={router.signup}>Don't have account? Register here</Link></span>}
                </div>
              </div>
            </form>
          </Paper>
        </div>
      </div>
    </div>
  );
}
