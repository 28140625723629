import React, {useState, useEffect} from "react";
import commonHelper from "../../../../helper/common_helper";
import brandsService from "../../../../services/brands-service";
import commonService from "../../../../services/common-service";
import ConfirmDiallog from "../../../../shared/ConfirmDiallog";
import SnackAlert from "../../../../shared/SnackAlert";
import Spinner from "../../../../shared/Spinner";
import Header from "../Common/Header/Header";
import AddBrand from "./AddBrand";
import BrandsView from "./BrandsView";

export default function AdminBrands() {
    const [spinner, setSpinner] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [btn, setBtn] = React.useState('Add Brand');
    const [editId, setEditId] = React.useState();
    const [inputField, setInputField] = useState({});
    const [image, setImage] = useState({});
    const [brands, setBrands] = useState([]);


    useEffect(()=>{
        getBrands();
    },[])

    const getBrands=async() => {
        setSpinner(true);
        const res = await commonService.get_record({table_name: 'brands'});
        setBrands(res.data);
        setSpinner(false);
    }
    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        let formData = new FormData();
        if(btn === 'Add Brand'){
            if(image.name === undefined){
                setMsg('Please Select image');
                setSeverity('error');
                setAlert(true);
                return;
            }
            formData = commonHelper.createFormData(formData,inputField);
            formData.append('brand_image', image);
            const res = await brandsService.addBrand(formData);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setBrands(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setImage({});
                setOpen(false);
                setMsg('Brand added successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }else{
            inputField['id'] = editId;
            formData = commonHelper.createFormData(formData,inputField);
            formData.append('brand_image', image);
            const res = await brandsService.editBrand(formData);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setBrands(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setImage({});
                setOpen(false);
                setMsg('Brand updated successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }
        setBtn('Add Brand');
        setSpinner(false);
    }

    const delItem=async()=>{
        setSpinner(true);
        const res = await commonService.del_record({id: editId, table_name: 'brands'});
        if(res.data.status){
            setBrands(brands.filter(function( obj ) {
                return obj.id !== editId;
            }))
        }
        setSpinner(false);
        setOpenDialog(false);
        setMsg('Brand deleted successfuly')
        setSeverity('success')
        setAlert(true)
    }

    const editItem=(item)=>{
        console.log(item)
        inputField['brand_name'] = item.brand_name;
        setOpen(true);
        setBtn('Update Brand');
        setEditId(item.id);
    }

    return (
        <div>
            <Header />
            <BrandsView 
                brands = {brands}

                handleOpen = {() => setOpen(true)}
                delItem = {(id)=>{setEditId(id); setOpenDialog(true)}}
                editItem = {(item)=>editItem(item)}
            />
            <AddBrand
                open = {open}
                inputField = {inputField}
                btn = {btn}
                
                setImage = {(e)=>setImage(e)}
                handleClose = {() => {setOpen(false); setBtn('Add Brand')}}
                handleChange = {(e)=>handleChange(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <ConfirmDiallog
                openDialog = {openDialog}

                closeDialog = {()=>setOpenDialog(false)}
                delItem = {()=>delItem()}
            />
        </div>
    );
}
