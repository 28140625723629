import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import router from "../../../../../routePaths/routePaths";
import orderService from "../../../../../services/order-service";
import MobileNavbar from "../Sidenav/MobileNavbar";
import HeaderView from "./HeaderView";

export default function Header(prop) {
    const [openMobileNav, setOpenMobileNav] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notiLength, setNotiLength] = useState(0);
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem("isLogged");
        navigate(router.home);
    }

    const getNewOrders = async () => {
        const res = await orderService.get_new_orders();
        setNotifications(res.data)
        setNotiLength(res.data.length);
    }

    const updateNotiStatus = async ()=>{
        await orderService.update_noti_status();
        setNotiLength(0);
    }

    useEffect(()=>{
        getNewOrders();
    },[])


    return (
        <div>
            <MobileNavbar
                openMobileNav = {openMobileNav}
                
                setOpenMobileNav = {()=>setOpenMobileNav(!openMobileNav)}
            />
            <HeaderView
                pageName = {prop.pageName}
                notifications = {notifications}
                notiLength = {notiLength}

                updateNotiStatus = {()=>updateNotiStatus()}
                setOpenMobileNav = {()=>setOpenMobileNav(true)}
                logout = {()=>logout()}
            />
        </div>
    );
}
