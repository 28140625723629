import AxiosServices from './axios-service';
import {endpoints} from './api-end-points';

export class BrandsService {

  getBrands(body){    
    const res = AxiosServices.post(endpoints.brands.getBrands, body);
    return res;
  }

  addBrand(body){    
    const res = AxiosServices.post(endpoints.brands.addBrand, body);
    return res;
  }

  editBrand(body){    
    const res = AxiosServices.post(endpoints.brands.editBrand, body);
    return res;
  }

}
const brandsService = new BrandsService();
export default brandsService;
