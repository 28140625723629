import React, {useState} from 'react';
import { Link } from "react-router-dom";
import {
  Paper,TextField,Autocomplete,Button,InputAdornment, IconButton
} from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputMask from 'react-input-mask';
import router from '../../../../routePaths/routePaths';


export default function RegisterView(props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className='container'>
      <div className='row'>
        <div className="col-md-12 cart-detail">
          <Paper elevation={1} sx={{ width: '100%', background: '#eee'}}>
            <p className="new-to">NEW TO KULTUR KING?</p>
            <form className='pt-3' onSubmit={props.handleSubmit}>
              <p className='reg-with'>REGISTER WITH EMAIL</p>
              <div className='container'>
                <div className="row">
                  <div className='col-lg-6'>
                    <TextField
                      id="outlined-basic" 
                      label="Email" variant="outlined" 
                      name="email" value={props.inputField['email']} 
                      className="width-100 mt-2" onChange={props.handleChange}
                      required size='small'/>
                  </div>
                  <div className='col-lg-6'>
                    <TextField
                      id="outlined-basic" 
                      label="Password" variant="outlined" 
                      name="password" 
                      type={(showPassword) ? 'text':'password'} value={props.inputField['password']} 
                      className="width-100 mt-2" onChange={props.handleChange}
                      required size='small'
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                            onClick={()=>setShowPassword(!showPassword)}
                            edge="end"
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }}/>
                  </div>
                  <div className='col-lg-12'>
                    <p className='my-2 p-0'>Shipping Address</p>
                  </div>
                  <div className='col-lg-6'>
                    <TextField
                      id="outlined-basic" 
                      label="First Name" variant="outlined" 
                      name="fname" value={props.inputField['fname']} 
                      className="width-100 mt-2" onChange={props.handleChange}
                      required size='small'/>
                  </div>
                  <div className='col-lg-6'>
                    <TextField
                      id="outlined-basic" 
                      label="Last Name" variant="outlined" 
                      name="lname" value={props.inputField['lname']} 
                      className="width-100 mt-2" onChange={props.handleChange}
                      required size='small'/>
                  </div>
                  <div className='col-lg-6'>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={props.states}
                      getOptionLabel={(option) => option.name}
                      className='mt-2'
                      value={props.selectedState}
                      onChange={(event, newValue)=>props.setSelectedState(newValue)}
                      renderInput={(params) =>
                        <TextField 
                          {...params} id="outlined-basic" 
                          label="Province" variant="outlined" 
                          className="width-100" 
                          required size='small'
                        />}
                    />
                  </div>
                  <div className='col-lg-6'>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={props.cities}
                      getOptionLabel={(option) => option.name}
                      className='mt-2'
                      value={props.selectedCity}
                      onChange={(event, newValue)=>props.setSelectedCity(newValue)}
                      renderInput={(params) =>
                        <TextField 
                          {...params} id="outlined-basic" 
                          label="City" variant="outlined" 
                          className="width-100" 
                          required size='small'
                        />}
                    />
                  </div>
                  <div className='col-lg-12'>
                    <TextField
                      id="outlined-basic" 
                      label="Address" variant="outlined" 
                      name="address" value={props.inputField['address']} 
                      className="width-100 mt-2" onChange={props.handleChange}
                      required size='small'/>
                  </div>
                  <div className='col-lg-12'>
                  <InputMask
                    mask="0399-9999999"
                    maskChar=""
                    value={props.inputField['ph_no']}
                    onChange={props.handleChange}
                  >
                  {() => <TextField 
                    id="standard-basic" 
                    label="Phone Number" 
                    variant="outlined" 
                    name="ph_no"
                    error={props.lenError}
                    helperText={props.lenError ? 'Please enter valid phone number' : ' '} 
                    className="width-100 mt-2"
                    required size="small"
                      />}
                  </InputMask>
                  </div>
                </div>
                <div className='text-center'>
                  <Button type="submit" className="btn-black px-3 fit-content" disabled={props.lenError}>
                    Register
                  </Button>
                </div>
                <div className='row accept-terms text-center py-3'>
                    <span>Creating an account means you accept Kultur King <Link to={router.termsOfService}>Terms of Service </Link>
                      and <Link to={router.privacyPolicy}> Privacy Policy.</Link><br/>
                      <Link to={router.login}>Already have an account? Login</Link></span>
                </div>
              </div>
            </form>
          </Paper>
        </div>
      </div>
    </div>
  );
}
