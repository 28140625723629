import React from "react";
import { alpha, styled } from '@mui/material/styles';
import { 
    Button, Typography, Table, TableBody, TableContainer,
    TableHead, TableRow, Paper, TablePagination, Switch,
    Collapse, IconButton, InputBase, Grid
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Sidenav from "../Common/Sidenav/Sidenav";
import { DeleteForeverOutlined, EditOutlined, KeyboardArrowDown, KeyboardArrowUp, Search } from "@mui/icons-material";


const SearchBar = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
        border: '1px solid #c9c6c6',
        borderRadius: '5px'
    },
  }));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const GoldSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#c6a215',
      '&:hover': {
        backgroundColor: alpha('#c6a215', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#c6a215',
    },
  }));  

export default function ProductsView(prop) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState('');

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    
    return (
        <div className="main-container my-3">
            <div className="row m-2">
                <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                    <Sidenav/>
                </div>
                <div className="col-lg-10 col-xl-10 black-corner">
                    <div className="row">
                        <div className="col-6 p-0">
                            <Typography variant="subtitle1" className="font-bold m-2 font-gray">PRODUCTS</Typography>
                        </div>
                        <div className="col-6 p-0 align-self-center text-end">
                            <Button 
                                type="button"
                                className='btn-black'
                                sx={{minWidth:110}}
                                onClick={prop.handleOpen}
                            >
                                    Add +
                            </Button>
                        </div>
                    </div>
                    <Grid xs={12} sm={12} md={6} lg={6} sx={{mb: 2}}>
                        <SearchBar>
                            <SearchIconWrapper>
                                <Search />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                value={prop.searched}
                                onChange={(e) => prop.requestSearch(e.target.value)}
                            />
                        </SearchBar>
                    </Grid>
                    <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ height: '65vh' }}>
                            <Table aria-label="collapsible table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell sx={{py: '16px'}}>Name</StyledTableCell>
                                        <StyledTableCell>In Stock</StyledTableCell>
                                        <StyledTableCell>Featured</StyledTableCell>
                                        <StyledTableCell>Featured this week</StyledTableCell>
                                        <StyledTableCell>Fit on rotation</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {prop.products
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        let images = (prop.productDetail.length > 0) ? JSON.parse(prop.productDetail[0].pro_image) : [];
                                        return (
                                            <>
                                                <TableRow key={row.name}>
                                                    <TableCell>
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => {
                                                                if(open !== row.id){prop.getProductDetail(row.id)};
                                                                setOpen((row.id === open ? "" : row.id))
                                                            }}
                                                        >
                                                            {open === row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.pro_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.items_in_stock}
                                                    </TableCell>
                                                    <TableCell>
                                                        <GoldSwitch
                                                            checked={row.pro_featured === '1'}
                                                            onChange={(e)=> prop.changeFeature(e,row.id)}
                                                            color="primary"
                                                            name="pro_featured"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <GoldSwitch
                                                            checked={row.pro_sale === '1'}
                                                            onChange={(e)=> prop.changeSwitch(e,row.id)}
                                                            sx={{color: '#eee'}}
                                                            name="pro_sale"
                                                            // inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <GoldSwitch
                                                            checked={row.pro_releases === '1'}
                                                            onChange={(e)=> prop.changeSwitch(e,row.id)}
                                                            color="primary"
                                                            name="pro_releases"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell >
                                                        <Button 
                                                            type="button" 
                                                            onClick={()=>prop.delItem(row.id)} 
                                                            sx={{color: '#bc0000', background: '#bc00002e', p: '2px', minWidth: 0, m: 1}}
                                                        >
                                                            <DeleteForeverOutlined />
                                                        </Button>
                                                        <Button
                                                            type="button" 
                                                            onClick={()=>{prop.editItem(row);setOpen("")}}
                                                            sx={{color: '#027c92', background: '#027c924d', p: '2px', minWidth: 0, m: 1}}
                                                        >
                                                            <EditOutlined/>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                        <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                                                            {prop.productDetail.length>0 && <Paper elevation={1} sx={{ margin: 1 }}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <Paper elevation={0} sx={{ margin: 1 }}>
                                                                            <Table size="small" aria-label="purchases">
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <StyledTableCell colSpan={2}>Detail</StyledTableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            Gender
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {prop.productDetail[0].gender_name}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            Category
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {prop.productDetail[0].category_name}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            Collection
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {prop.productDetail[0].col_name}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            Brand
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {prop.productDetail[0].brand_name}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            Sizes
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {prop.productDetail[0].size.map(x=>{
                                                                                                return x.size_name + ', ';
                                                                                            })}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            Colours
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {prop.productDetail[0].colours.map(x=>{
                                                                                                return x.colour_name + ', ';
                                                                                            })}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            Items Purchased
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {prop.productDetail[0].item_purchased}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            Price
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {prop.productDetail[0].pro_price}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            Discounted Price
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {prop.productDetail[0].dis_price}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            Description
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {prop.productDetail[0].pro_description}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableBody>
                                                                            </Table>
                                                                        </Paper>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className='row'>
                                                                            {images.map(x=>{
                                                                                return(
                                                                                    <div className="col-6">
                                                                                    <Paper elevation={1} sx={{ margin: 1 }}>
                                                                                        <img src={x} width='100%' height={155} alt="loading"/>
                                                                                    </Paper>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                            </Paper>}
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                            component="div"
                            count={prop.products.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            SelectProps={{
                                MenuProps: { classes: "selectDropdown" }
                            }}
                        />
                    </Paper>
                </div>
            </div>
        </div>
    );
}
