import React, {useState, useEffect} from "react";
import commonService from "../../../../services/common-service";
import SnackAlert from "../../../../shared/SnackAlert";
import Spinner from "../../../../shared/Spinner";
import Header from "../Common/Header/Header";
import HistoryView from "./HistoryView";

export default function History() {
    const [spinner, setSpinner] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [searched, setSearched] = useState("");
    const [orders, setOrders] = useState([]);
    const [user, setUser] = useState({});
    const [rows, setRows] = useState([]);


    useEffect(()=>{
        getOrders();
    },[])

    const getOrders=async() => {
        setSpinner(true);
        const res = await commonService.get_record({column: 'user_id', value: JSON.parse(localStorage.getItem('isLogged')).id, table_name: 'orders'});
        setOrders(res.data);
        setRows(res.data)
        setSpinner(false);
    }

    const handleChange=async(e,id)=>{
        setSpinner(true);
        const res = await commonService.edit_record({table_name: 'orders', id: id, status: e.target.value});
        setOrders(res.data);
        setRows(res.data)
        setSpinner(false);
    }

    const getUserDetail=async (item)=>{
        setSpinner(true);
        const res = await commonService.get_record_by_id({table_name: 'users', id: item.user_id});
        setUser(res.data[0]);
        setSpinner(false);
    }

    const requestSearch = (searchedVal) => {
        const filteredRows = rows.filter((row) => {
          return row.order_id.toLowerCase().includes(searchedVal.toLowerCase()) 
          || row.shipping_method.toLowerCase().includes(searchedVal.toLowerCase())
          || row.status.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setSearched(searchedVal);
        setOrders(filteredRows);
    };

    return (
        <div>
            <Header />
            <HistoryView 
                orders = {orders}
                user = {user}
                searched = {searched}

                getUserDetail = {(item)=>getUserDetail(item)}
                handleChange = {(e,id)=>handleChange(e,id)}
                requestSearch = {(e)=>requestSearch(e)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}
