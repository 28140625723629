import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import TabHeader from '../../../../../components/TabHeader';
import { useNavigate } from 'react-router-dom';
import router from '../../../../../routePaths/routePaths';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function LeftDrawer(prop) {
    let navigate = useNavigate();

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    console.log(newValue)
    let cat = (newValue === 0) ? 'Men' : (newValue === 1) ? 'Women' : (newValue === 2) ? 'Kids' : 'Sale'
    prop.onOpenDrawer(cat)
  };

  return (
    <div>
        <Drawer
            open={prop.leftDrawer}
            onClose={prop.setLeftDrawer}
        >
              <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="MENS" {...a11yProps(0)} />
                  <Tab label="WOMENS" {...a11yProps(1)} />
                  <Tab label="KIDS" {...a11yProps(2)} />
                  <Tab label="SALE" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <TabHeader
                  tabData={prop.tabData}
                  setLeftDrawer={prop.setLeftDrawer}
                  setOpenSearchModal={prop.setOpenSearchModal}
                  setOpenMobileSearchModal={prop.setOpenMobileSearchModal}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <TabHeader
                  tabData={prop.tabData}
                  setLeftDrawer={prop.setLeftDrawer}
                  setOpenSearchModal={prop.setOpenSearchModal}
                  setOpenMobileSearchModal={prop.setOpenMobileSearchModal}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <TabHeader
                  tabData={prop.tabData}
                  setLeftDrawer={prop.setLeftDrawer}
                  setOpenSearchModal={prop.setOpenSearchModal}
                  setOpenMobileSearchModal={prop.setOpenMobileSearchModal}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <TabHeader
                  tabData={prop.tabData}
                  setLeftDrawer={prop.setLeftDrawer}
                  setOpenSearchModal={prop.setOpenSearchModal}
                  setOpenMobileSearchModal={prop.setOpenMobileSearchModal}
                />
              </TabPanel>
              <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {prop.isLogged === null && <>
                  <ListItemButton onClick={()=>navigate(router.login)}>
                    <ListItemIcon>
                      <PermIdentityIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign In" />
                  </ListItemButton>
                  <ListItemButton onClick={()=>navigate(router.signup)}>
                    <ListItemIcon>
                      <HowToRegIcon />
                    </ListItemIcon>
                    <ListItemText primary="Register" />
                  </ListItemButton>
                </>}
                <ListItemButton>
                  <ListItemIcon>
                    <InfoOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="About" />
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon>
                    <ContactSupportOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Help" />
                </ListItemButton>
                {prop.isLogged !== null && <>
                  <ListItemButton onClick={prop.logout}>
                    <ListItemIcon>
                      <PermIdentityIcon />
                    </ListItemIcon>
                    <ListItemText primary="LOGOUT" />
                  </ListItemButton>
                </>}
              </List>
            </Box>
        </Drawer>
    </div>
  );
}
