import React, {useState, useEffect} from "react";
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import HomeView from "./HomeView";
import commonService from "../../../../services/common-service";
import Spinner from "../../../../shared/Spinner";
import productsService from "../../../../services/products-service";
import SnackAlert from "../../../../shared/SnackAlert";


export default function Home() {
    const [spinner, setSpinner] = useState(false);
    const [color, setColor] = useState('');
    const [inputField, setInputField] = useState({});
    const [homeRecord, setHomeRecord] = useState([]);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');

    const getHomeRecord=async() => {
        setSpinner(true);
        const res = await productsService.getHomeRecord();
        setHomeRecord(res.data);
        setColor(res.data.mainColor[Math.floor(Math.random() * res.data.mainColor.length)])
        setSpinner(false);
    }

    const handleChange=(e) => {
        console.log(e.target)
        setInputField({...inputField, [e.target.name]: (e.target.name === 'email')? e.target.value : e.target.checked})
    }

    const handleSubmit=async (e) => {
        e.preventDefault();
        setSpinner(true);
        inputField['table_name'] = 'subscriptions';
        const res = await commonService.save_record(inputField);
        if(res.data.length > 0){
            setMsg('Thank you to subscribe us');
            setSeverity('success');
            setAlert(true);
        }else{
            setMsg('Something went wrong. Please try again!');
            setSeverity('error');
            setAlert(true);
        }
        setSpinner(false);

    }

    useEffect(()=>{
        getHomeRecord();
    },[])

    return (
        <>
            <Header 
                color={color}
            />
            <HomeView 
                homeRecord = {homeRecord}
                inputField = {inputField}

                handleChange = {(e)=>handleChange(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
            />
            <Footer />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </>
    );
}
