import AxiosServices from './axios-service';
import {endpoints} from './api-end-points';

export class CollectionsService {

  getCollections(body){    
    const res = AxiosServices.post(endpoints.collections.getCollections, body);
    return res;
  }

  addCollection(body){    
    const res = AxiosServices.post(endpoints.collections.addCollection, body);
    return res;
  }

  editCollection(body){    
    const res = AxiosServices.post(endpoints.collections.editCollection, body);
    return res;
  }

  editFeatured(body){    
    const res = AxiosServices.post(endpoints.collections.editFeatured, body);
    return res;
  }

  getCollectionPagination(body){
    const res = AxiosServices.post(endpoints.collections.getCollectionPagination,body);
    return res;
  }

  getCollectionsByCol(body){
    const res = AxiosServices.post(endpoints.collections.getCollectionsByCol,body);
    return res;
  }

}
const collectionsService = new CollectionsService();
export default collectionsService;
