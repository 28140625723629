import React, {useState, useEffect} from "react";
import commonService from "../../../../services/common-service"
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import BrandsView from "./BrandsView";
import Spinner from "../../../../shared/Spinner";

export default function Brands() {
    const color = '#a2a2a2';
    const [spinner, setSpinner] = useState(false);
    const [brands, setBrands] = useState([]);
    const [searched, setSearched] = useState("");
    const [rows, setRows] = useState([]);
    
    useEffect(async ()=>{
        setSpinner(true);
        getBrands();
    },[])

    const getBrands=async() =>{
        const res = await commonService.get_record({table_name: 'brands'})
        setRows(res.data)
        setBrands(res.data);
        setSpinner(false);
    }

    const requestSearch = (searchedVal) => {
        const filteredRows = rows.filter((row) => {
          return row.brand_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setSearched(searchedVal);
        setBrands(filteredRows);
    };

    return (
        <>
            <Header 
                color={color}
            />
            <BrandsView
                brands = {brands}
                searched = {searched}

                requestSearch = {(e)=>requestSearch(e)}
            />
            <Footer />
            <Spinner
                spinner = {spinner}
            />
        </>
    );
}
