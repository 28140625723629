import React from "react";
import { alpha,styled } from '@mui/material/styles';
import { 
    Table, TableBody, TableContainer,
    TableHead, TableRow, Paper, TablePagination, InputBase, Grid 
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Sidenav from "../Common/Sidenav/Sidenav";
import { Search } from "@mui/icons-material";

const SearchBar = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
        border: '1px solid #c9c6c6',
        borderRadius: '5px'
    },
  }));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

export default function UsersView(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    
    return (
        <div className="main-container my-3">
            <div className="row m-2">
                <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                    <Sidenav/>
                </div>
                <div className="col-lg-10 col-xl-10 black-corner">
                    <Grid xs={12} sm={12} md={6} lg={6} sx={{my: 2}}>
                        <SearchBar>
                            <SearchIconWrapper>
                                <Search />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                value={props.searched}
                                onChange={(e) => props.requestSearch(e.target.value)}
                            />
                        </SearchBar>
                    </Grid>
                    <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ height: '65vh' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell align="right">Email</StyledTableCell>
                                        <StyledTableCell align="right">Contact</StyledTableCell>
                                        <StyledTableCell align="right">Address</StyledTableCell>
                                        <StyledTableCell align="right">City</StyledTableCell>
                                        <StyledTableCell align="right">State</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.users
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row">
                                                    {row.fname} {row.lname}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.email}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.ph_no}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.address}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.city}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.state}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                            component="div"
                            count={props.users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            SelectProps={{
                                MenuProps: { classes: "selectDropdown" }
                            }}
                        />
                    </Paper>
                </div>
            </div>
        </div>
    );
}
