import * as React from 'react';
import {List,ListItem,ListItemButton,ListItemText,Typography,Divider,Button} from '@mui/material';
import {ArrowForwardIos,Facebook,Twitter,Instagram,Pinterest} from '@mui/icons-material';
import logo from '../../../../../assets/images/logo/logo.png';
import { useNavigate } from 'react-router-dom';
import router from '../../../../../routePaths/routePaths';

export default function FooterView(props) {
    let navigate = useNavigate();

  return (
    <>
        <div className='bg-black d-none d-lg-block d-xl-block'>
            <div className='container py-5'>
                <div className='row'>
                    <div className='col-2'>
                        <img src={logo} width='100%' alt='loading'/>
                    </div>
                    <div className='col-2'>
                        <h5 className='ps-3 font-white'>SHOP</h5>
                        {props.collections.map((x,index)=>{
                            if(index < 4){
                                return(
                                    <List sx={{p:0, pt:2, color: '#fff'}}>
                                        <ListItem disablePadding>
                                            <ListItemButton sx={{py: 0}} onClick={()=>navigate(router.collections+"/"+x.id)}>
                                                <ListItemText primary={x.col_name} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                )
                            }
                        })}
                        <List sx={{p:0, pt:2, color: '#fff'}}>
                            <ListItem disablePadding>
                                <ListItemButton sx={{py: 0}} onClick={()=>navigate(router.brands)}>
                                    <ListItemText primary="BRANDS" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                    <div className='col-2'>
                        <h5 className='ps-3 font-white'>ABOUT</h5>
                        <List sx={{p:0, pt:2, color: '#fff'}}>
                            <ListItem disablePadding>
                                <ListItemButton sx={{py: 0}}>
                                    <ListItemText primary="OUR STORY" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                    <div className='col-2'>
                        <h5 className='ps-3 font-white'>HELP</h5>
                        <List sx={{p:0, pt:2, color: '#fff'}}>
                            <ListItem disablePadding href="mailto:info@kulturking.com">
                                <ListItemButton sx={{py: 0}}>
                                    <ListItemText primary="info@kulturking.com" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                    <div className='col-2'>
                        <h6 className='ps-2 pb-4 font-white font-14' style={{lineHeight: 2}}>Latest & Greatest</h6>
                        <div className="pointer center-image" style={{height: "140px"}}>
                            {props.collections.length > 0 && <img 
                                src={props.collections[props.collections.length - 1].col_image} 
                                width='100%' 
                                alt='loading'
                                onClick={()=>navigate.collections+"/"+props.collections[props.collections.length - 1].id}
                                />}
                        </div>
                    </div>
                    <div className='col-2'>
                        <Typography 
                            className='ps-3 pb-4 font-white font-11 text-end font-gray pointer' 
                            style={{lineHeight: 2}} variation={'caption'}
                            onClick={()=>navigate.collections}
                        >
                            View All <ArrowForwardIos fontSize='inherit'/><ArrowForwardIos fontSize='inherit'/>
                        </Typography>
                        <div className="mt-3 pointer center-image" style={{height: "140px"}}>
                            {props.collections.length > 0 && <img 
                                src={props.collections[0].col_image} 
                                width='100%' 
                                alt='loading'
                                onClick={()=>navigate.collections+"/"+props.collections[0].id}
                                />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='bg-black'>
            <div className='container py-5 d-none d-lg-block d-xl-block'>
                <div className='row'>
                    <div className='col-md-4 mt-3 font-gray font-14 text-center'>
                        <Button className='btn-link font-gray font-14' sx={{textTransform:'none'}}>
                            KulturKing {new Date().getFullYear()}
                        </Button>
                        <Button 
                            className='btn-link font-gray font-14' 
                            sx={{textTransform:'none'}} 
                            onClick={()=>navigate(router.termsOfService)}
                        >
                            Terms
                        </Button>| 
                        <Button 
                            className='btn-link font-gray font-14' 
                            sx={{textTransform:'none'}} 
                            onClick={()=>navigate(router.privacyPolicy)}
                            >
                            Privacy
                        </Button>
                    </div>
                    <div className='col-md-4 mt-3 font-gray font-14 text-center'>
                        <Facebook /> <Twitter /> <Pinterest /> <Instagram />
                    </div>
                    <div className='col-md-4 mt-3 font-gray font-14 text-center'>
                        <Facebook /> <Twitter /> <Pinterest /> <Instagram />
                    </div>
                </div>
           </div>
           <div className='d-sm-block d-xs-block d-md-block d-lg-none d-xl-none'>
                <div className='row'>
                    <div className='col-12 my-3 font-gray font-14 text-center'>
                        <Facebook /> <Twitter /> <Pinterest /> <Instagram />
                    </div>
                </div>
               <Divider className='font-gray'/>
           </div>
           <div className='container pt-4 d-sm-block d-xs-block d-md-block d-lg-none d-xl-none'>
                <div className='row'>
                    <div className='col-12 mt-3 font-gray font-14 text-center'>
                        <Facebook /> <Twitter /> <Pinterest /> <Instagram />
                    </div>
                    <div className='col-12 mt-3 font-gray font-14 text-center'>
                        <Button className='btn-link font-gray font-14' sx={{textTransform:'none'}}>
                            KulturKing 2022
                        </Button>
                        <Button className='btn-link font-gray font-14' sx={{textTransform:'none'}}>
                            Terms
                        </Button>| 
                        <Button className='btn-link font-gray font-14' sx={{textTransform:'none'}}>
                            Privacy
                        </Button>
                    </div>
                </div>
           </div>
       </div>
    </>
  );
}
