import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import {
  Box,Divider,Button, Paper,Stepper,Step,StepLabel,
  ListItemButton,ListItemText,Collapse,List,
  Radio,RadioGroup,FormControlLabel,FormControl
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { EditOutlined } from '@mui/icons-material';


const steps = ['Contact Information', 'Confirm'];

export default function CheckoutView(props) {
  let total = (props.cartItems.reduce((n, {total_price}) => (n + parseInt(total_price)), 0));
  const [isOpen, setIsOpen] = useState([
    {id:1, isOpen: false},
  ])

  const handleClick = (item) =>{
    setIsOpen(
      isOpen.map(e => e.id === item.id ? ({...e, isOpen: !e.isOpen} ) : (e))
    )
  };

  return (
    <div className='row'>
      <Paper elevation={1} className="d-sm-block d-xs-block d-md-none d-lg-none d-xl-none">
        <ListItemButton onClick={() => handleClick(isOpen[0])}>
            <ListItemText primary={'Order Summary Rs. '+total+' /-'} />
            {isOpen[0].isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isOpen[0].isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            <div>
              {props.cartItems.map((x,index)=>{
                return(
                  <div className="row">
                    <div className="col-3 py-2">
                      <div style={{position: 'relative'}}>
                        <div className="top-right-note">
                            <span>{x.quantity}</span>
                        </div>
                        <div className='center-image' style={{height: '70px'}}>
                          <img src={x.pro_image[0]} width="100%" alt="loading"/>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <span className="font-bold">{x.pro_name}</span><br/><span>{x.sizeSelect.size_name}</span>
                    </div>
                    <div className="col-3">
                      <span>Rs. {(x.dis_price === '0') ? x.pro_price*x.quantity : x.dis_price*x.quantity} /- </span>
                    </div>
                  </div>
                )
              })}
              <Divider className="my-3"/>
              <div className="row">
                <div className="col-6"><span>Subtotal</span></div>
                <div className="col-6 text-end">
                  <span>Rs. {props.cartItems.reduce((n, {total_price}) => (n + parseInt(total_price)), 0)} /-</span>
                </div>
              </div>
              <div className="row">
                <div className="col-6"><span>Shipping</span></div>
                <div className="col-6 text-end">
                  <span>Rs. 0.00 /-</span>
                </div>
              </div>
              <Divider className="my-3"/>
              <div className="row">
                <div className="col-6"><p className="font-bold">Total</p></div>
                <div className="col-6 text-end">
                  <p className="font-bold">Rs. {(props.cartItems.reduce((n, {total_price}) => (n + parseInt(total_price)), 0))} /-</p>
                </div>
              </div>
            </div>
            </List>
        </Collapse>
      </Paper>
      <div className="col-md-6 cart-detail">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={props.activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <React.Fragment>
            {props.activeStep === 0 && props.form}
            {props.activeStep === 1 && 
              <>
                <Paper elevation={1} className="my-3" square>
                  <div className='row' style={{alignItems: 'center'}}>
                    <div className='col-2'>
                      <span className='font-para'>Contact</span>
                    </div>
                    <div className='col-8'>
                      {props.inputField['ph_no']}
                    </div>
                    <div className='col-2'>
                      <Button type="button" onClick={props.handleBack} sx={{minWidth: 0}}>
                        <EditOutlined/>
                      </Button>
                    </div>
                  </div>
                  <Divider className="m-1"/>
                  <div className='row' style={{alignItems: 'center'}}>
                    <div className='col-2'>
                      <span className='font-para'>Ship to</span>
                    </div>
                    <div className='col-8'>
                      {props.inputField['address']}
                    </div>
                    <div className='col-2'>
                      <Button type="button" onClick={props.handleBack} sx={{minWidth: 0}}>
                        <EditOutlined/>
                      </Button>
                    </div>
                  </div>
                </Paper>
                <h6>SHIPPING METHOD</h6>
                <form onSubmit={props.handleCheckout}>
                  <Paper elevation={1} className="my-3 p-2" square>
                    <FormControl sx={{width: '100%'}}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="shipping_method"
                        required
                      >
                        <FormControlLabel value="pay_on_delivery" onChange={props.handleChange} control={<Radio size='small'/>} sx={{fontSize: '13px'}} label="Cash on Delivery (3-5 Working Days)" />
                        <Divider className="m-1"/>
                        <FormControlLabel value="paid" onChange={props.handleChange} control={<Radio size='small'/>} sx={{fontSize: '13px'}} label="VISA/MASTER card (3-5 Working Days)" />
                      </RadioGroup>
                    </FormControl>
                  </Paper>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={props.activeStep === 0}
                      onClick={props.handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button type="submit" className="btn-black px-3" disabled={props.inputField['shipping_method'] === undefined}>
                      Checkout
                    </Button>
                  </Box>
                </form>
              </>
            }
          </React.Fragment>
        </Box>
      </div>
      <div className="col-md-6 cart-detail d-none d-xl-block d-lg-block d-md-block" style={{background: '#eee'}}>
        <div className="container">
          {props.cartItems.map((x,index)=>{
            return(
              <div className="row">
                <div className="col-3 py-2">
                  <div style={{position: 'relative'}}>
                    <div className="top-right-note">
                        <span>{x.quantity}</span>
                    </div>
                    <img src={x.pro_image[0]} width="100%" height="70px" alt="loading"/>
                  </div>
                </div>
                <div className="col-6">
                  <span className="font-bold">{x.pro_name}</span><br/><span>{x.sizeSelect.size_name} | {x.colorSelect.colour_name}</span>
                </div>
                <div className="col-3">
                  <span>Rs. {(x.dis_price === '0') ? x.pro_price*x.quantity : x.dis_price*x.quantity} /- </span>
                </div>
              </div>
            )
          })}
          <Divider className="my-3"/>
          <div className="row">
            <div className="col-6"><span>Subtotal</span></div>
            <div className="col-6 text-end">
              <span>Rs. {props.cartItems.reduce((n, {total_price}) => (n + parseInt(total_price)), 0)} /-</span>
            </div>
          </div>
          <div className="row">
            <div className="col-6"><span>Shipping</span></div>
            <div className="col-6 text-end">
              <span>Rs. 0.00 /-</span>
            </div>
          </div>
          <Divider className="my-3"/>
          <div className="row">
            <div className="col-6"><p className="font-bold">Total</p></div>
            <div className="col-6 text-end">
              <p className="font-bold">Rs. {(props.cartItems.reduce((n, {total_price}) => (n + parseInt(total_price)), 0))} /-</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
