import React, {useState, useEffect} from "react";
import {
    Paper,InputLabel,Select,MenuItem,List,
    Box,ListItemButton,ListItemText,Collapse,
    Typography,Button
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

export default function Filter(props){
    const [isOpen, setIsOpen] = useState([
        {id:1, isOpen: true},
        {id:2, isOpen: false},
        {id:3, isOpen: false},
        {id:4, isOpen: false},
        {id:5, isOpen: false},
        {id:6, isOpen: false},
    ])

    const handleClick = (item) =>{
        setIsOpen(
        isOpen.map(e => e.id === item.id ? ({...e, isOpen: !e.isOpen} ) : (e))
    )
    };

    useEffect(() => {
        
    },[])

    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper', height: '100%', overflow:'overlay'}}
            component="nav"
            aria-labelledby='{item.id}'
            >
                <Box component="section" className='filterList'>
                    <Typography variant="h5" sx={{width: 'fit-content', borderBottom: '2px solid black'}} className='font-bold mb-3 d-sm-block d-xs-block d-md-none d-lg-none d-xl-none'>
                        Filter List
                        <IconButton 
                            type="button"
                            sx={{ p: '0px', position: 'absolute', right: 0, top: '13px'}} 
                            aria-label="search"
                            onClick={()=>props.setOpenFilterModal()}>
                            <CloseIcon />
                        </IconButton>
                    </Typography>
                    <FormControl sx={{width:'300px'}} size="small" className='d-sm-block d-xs-block d-md-none d-lg-none d-xl-none'>
                        <InputLabel id="demo-simple-select-label">Sort By:</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.sortBy}
                            label="Sort By:"
                            onChange={props.handleChange}
                        >
                            <MenuItem value={0}>Date: New to Old</MenuItem>
                            <MenuItem value={1}>Date: Old to New</MenuItem>
                            <MenuItem value={2}>Price: Low to High</MenuItem>
                            <MenuItem value={3}>Price: High to Low</MenuItem>
                        </Select>
                    </FormControl>
                    <ListItemButton onClick={() => handleClick(isOpen[1])}>
                        <ListItemText primary={'Categories'} />
                        {isOpen[1].isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={isOpen[1].isOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {props.filtersTable.categories !== undefined && props.filtersTable.categories.map(x=>{
                            return(
                                <ListItemButton sx={{ pl: 4 }} onClick={()=>props.handleFilter('p.category_id',x.id,x.category_name)}>
                                    <ListItemText primary={x.category_name} />
                                </ListItemButton>
                            )
                        })}
                        </List>
                    </Collapse>
                    {!props.id.includes("=") && <ListItemButton onClick={() => handleClick(isOpen[2])}>
                        <ListItemText primary={'Brands'} />
                        {isOpen[2].isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>}
                    <Collapse in={isOpen[2].isOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {props.filtersTable.brands !== undefined && props.filtersTable.brands.map(x=>{
                            return(
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText primary={x.brand_name} onClick={()=>props.handleFilter('p.brand_id',x.id,x.brand_name)}/>
                                </ListItemButton>
                            )
                        })}
                        </List>
                    </Collapse>
                    <ListItemButton onClick={() => handleClick(isOpen[3])}>
                        <ListItemText primary={'Genders'} />
                        {isOpen[3].isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={isOpen[3].isOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {props.filtersTable.genders !== undefined && props.filtersTable.genders.map(x=>{
                            return(
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText primary={x.gender_name} onClick={()=>props.handleFilter('p.gender_id',x.id,x.gender_name)}/>
                                </ListItemButton>
                            )
                        })}
                        </List>
                    </Collapse>
                    <ListItemButton onClick={() => handleClick(isOpen[4])}>
                        <ListItemText primary={'Colour'} />
                        {isOpen[4].isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={isOpen[4].isOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {props.filtersTable.colours !== undefined && props.filtersTable.colours.map(x=>{
                            return(
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText primary={x.colour_name} onClick={()=>props.handleFilter('p.colour_id',x.id,x.colour_name)}/>
                                </ListItemButton>
                            )
                        })}
                        </List>
                    </Collapse>
                    <ListItemButton onClick={() => handleClick(isOpen[5])}>
                        <ListItemText primary={'Size'} />
                        {isOpen[5].isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={isOpen[5].isOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {props.filtersTable.size !== undefined && props.filtersTable.size.map(x=>{
                            return(
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText primary={x.size_name} onClick={()=>props.handleFilter('p.size_id',x.id,x.size_name)}/>
                                </ListItemButton>
                            )
                        })}
                        </List>
                    </Collapse>
                    {props.id !== 'sale' && <ListItemButton onClick={()=>props.handleFilter('p.pro_sale','1','sale')}>
                        <ListItemText primary='Sale'/>
                    </ListItemButton>}
                    <Paper elevation={0} className='text-center mt-3 d-sm-block d-xs-block d-md-none d-lg-none d-xl-none'>
                        <Button type='button' className='btn-black width-90' onClick={()=>props.setOpenFilterModal()}>
                            Apply
                        </Button>
                    </Paper>
                </Box>
        </List>
    )   
}