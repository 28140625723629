import React, {useState, useEffect} from "react";
import commonHelper from "../../../../helper/common_helper";
import collectionsService from "../../../../services/collections-service";
import commonService from "../../../../services/common-service";
import ConfirmDiallog from "../../../../shared/ConfirmDiallog";
import SnackAlert from "../../../../shared/SnackAlert";
import Spinner from "../../../../shared/Spinner";
import Header from "../Common/Header/Header";
import AddCollection from "./AddCollection";
import CollectionsView from "./CollectionsView";

export default function AdminCollections() {
    const [spinner, setSpinner] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [btn, setBtn] = React.useState('Add Collection');
    const [editId, setEditId] = React.useState();
    const [inputField, setInputField] = useState({});
    const [image, setImage] = useState({});
    const [collections, setCollections] = useState([]);


    useEffect(()=>{
        getCollections();
    },[])

    const getCollections=async() => {
        setSpinner(true);
        const res = await commonService.get_record({table_name: 'collections'});
        setCollections(res.data);
        setSpinner(false);
    }
    
    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        let formData = new FormData();
        if(btn === 'Add Collection'){
            if(image.name === undefined){
                setMsg('Please Select image');
                setSeverity('error');
                setAlert(true);
                return;
            }
            formData = commonHelper.createFormData(formData,inputField);
            formData.append('col_image', image);
            formData.append('col_featured', 0);
            const res = await collectionsService.addCollection(formData);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setCollections(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setImage({});
                setOpen(false);
                setMsg('Collection added successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }else{
            inputField['id'] = editId;
            formData = commonHelper.createFormData(formData,inputField);
            formData.append('col_image', image);
            const res = await collectionsService.editCollection(formData);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setCollections(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setImage({});
                setOpen(false);
                setMsg('Collection updated successfuly')
                setSeverity('success')
                setAlert(true)
            }
            console.log(inputField, image.name)
        }
        setSpinner(false);
    }

    const delItem=async()=>{
        setSpinner(true);
        const res = await commonService.del_record({id: editId, table_name: 'collections'});
        if(res.data.status){
            setCollections(collections.filter(function( obj ) {
                return obj.id !== editId;
            }))
        }
        setSpinner(false);
        setOpenDialog(false);
        setMsg('Collection deleted successfuly')
        setSeverity('success')
        setAlert(true)
    }

    const editItem=(item)=>{
        console.log(item)
        inputField['col_name'] = item.col_name;
        setOpen(true);
        setBtn('Update Collection');
        setEditId(item.id);
    }

    const changeFeature=async(e,id)=>{
        if(e.target.checked === true){
            setInputField({ ...inputField, [e.target.name]: e.target.checked });
            let false_id = collections.filter(x=>x.col_featured === 1);
            console.log(false_id);
            setSpinner(true);
            let res;
            if(false_id.length > 0){
                res = await collectionsService.editFeatured({
                    true_id : id,
                    [e.target.name] : e.target.checked,
                    false_id : false_id[0].id,
        
                });
            }else{
                res = await commonService.edit_record({
                    id : id,
                    [e.target.name] : e.target.checked,
                    table_name : 'collections'
                });
            }
            setCollections(res.data);
            setSpinner(false);
            setInputField({})
        }else{
            setMsg('This is already featured, Please select another one to disable it.')
            setSeverity('error')
            setAlert(true)
        }
    }

    return (
        <div>
            <Header />
            <CollectionsView 
                collections = {collections}
                inputField = {inputField}

                handleOpen = {() => setOpen(true)}
                delItem = {(id) => {setEditId(id); setOpenDialog(true)}}
                editItem = {(item) => editItem(item)}
                changeFeature = {(e,id) => changeFeature(e,id)}
            />
            <AddCollection
                open = {open}
                inputField = {inputField}
                btn = {btn}
                
                setImage = {(e)=>setImage(e)}
                handleClose = {() => {setOpen(false); setBtn('Add Collection')}}
                handleChange = {(e)=>handleChange(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <ConfirmDiallog
                openDialog = {openDialog}

                closeDialog = {()=>setOpenDialog(false)}
                delItem = {()=>delItem()}
            />
        </div>
    );
}
