const router = {
  admin: '/',
  login: '/login',
  signup: '/signup',
  termsOfService: '/terms-of-service',
  privacyPolicy: '/privacy-policy',
  profile: '/profile',
  history: '/order-history',
  home: '/home',
  brands: '/brands',
  collections: '/collections',
  collectionsList: '/collections',
  product: '/product',
  cart: '/cart',
  checkout: '/checkout',
  dashboard: '/admin/dashboard',
  adminCollections: '/admin/collections',
  adminBrands: '/admin/brands',
  adminProducts: '/admin/products',
  adminOrders: '/admin/orders',
  adminProfile: '/admin/profile',
  adminSize: '/admin/size',
  adminCategory: '/admin/category',
  adminUsers: '/admin/users',
  adminSubscriptions: '/admin/subscriptions',
};

export default router;
