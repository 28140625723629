import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Sidenav from './Sidenav';

export default function MobileNavbar(prop) {

  return (
    <div>
      <Drawer
        anchor={'top'}
        open={prop.openMobileNav}
        onClose={prop.setOpenMobileNav}
      >
        <Sidenav
          setOpenMobileNav={prop.setOpenMobileNav}
        />
      </Drawer>
    </div>
  );
}