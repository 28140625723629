import * as React from 'react';
import { 
  Autocomplete, Backdrop, Box, Modal, 
  Fade, TextField, Typography, Button, Divider, InputAdornment 
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: '7 !important',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

export default function AddProduct(prop) {
  
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={prop.open}
        onClose={prop.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={prop.open}>
          <Box sx={style} className='width-50'>
              <Typography className='font-bold'>{prop.btn}</Typography>
              <Divider className='my-2'/>
                <form onSubmit={prop.handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-6 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Name" variant="standard" 
                                name="pro_name" value={prop.inputField['pro_name']} 
                                className="width-100" onChange={prop.handleChange}
                                required/>
                        </div>
                        <div className='col-lg-6 inline-flex'>
                            <label class="file-label" for="input-file" className='mt-4'>Image:&nbsp;</label>
                            <input type="file" name="pro_name" style={{borderBottom: '1px solid #afafaf'}}
                                className='mt-3'
                                onChange={prop.fileSelectedHandler}
                                accept="image/png, image/jpg, image/jpeg"
                                multiple
                            />
                        </div>
                        <div className='col-lg-6 text-center'>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={prop.categories}
                            getOptionLabel={(option) => option.category_name}
                            name="category_id"
                            value={prop.selectedCat}
                            className='mt-2'
                            onChange={(event, newValue)=>prop.setSelectedCat(newValue)}
                            renderInput={(params) =>
                              <TextField 
                                {...params} id="standard-basic" 
                                label="Category" variant="standard" 
                                className="width-100" 
                                required
                              />}
                          />
                        </div>
                        <div className='col-lg-6 text-center'>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={prop.genders}
                            getOptionLabel={(option) => option.gender_name}
                            name="gender_id"
                            value={prop.selectedGen}
                            className='mt-2'
                            onChange={(event, newValue)=>prop.setSelectedGen(newValue)}
                            renderInput={(params) =>
                              <TextField 
                                {...params} id="standard-basic" 
                                label="Gender" variant="standard" 
                                className="width-100" 
                                required
                              />}
                          />
                        </div>
                        <div className='col-lg-6 text-center'>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={prop.collections}
                            getOptionLabel={(option) => option.col_name}
                            name="pro_collection"
                            className='mt-2'
                            value={prop.selectedCol}
                            onChange={(event, newValue)=>prop.setSelectedCol(newValue)}
                            renderInput={(params) =>
                              <TextField 
                                {...params} id="standard-basic" 
                                label="Collection" variant="standard"
                                className="width-100" 
                                required
                              />}
                          />
                        </div>
                        <div className='col-lg-6 text-center'>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={prop.brands}
                            getOptionLabel={(option) => option.brand_name}
                            name="pro_brand"
                            className='mt-2'
                            value={prop.selectedBrand}
                            onChange={(event, newValue)=>prop.setSelectedBrand(newValue)}
                            renderInput={(params) =>
                              <TextField 
                                {...params} id="standard-basic" 
                                label="Brand" variant="standard" 
                                className="width-100" 
                                required
                              />}
                          />
                        </div>
                        <div className='col-lg-6 text-center'>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={prop.sizes}
                            getOptionLabel={(option) => option.size_name}
                            name="size_name"
                            className='mt-2'
                            value={prop.selectedSize}
                            onChange={(event, newValue)=>prop.setSelectedSize(newValue)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Sizes"
                                placeholder="Sizes"
                              />
                            )}
                          />
                        </div>
                        <div className='col-lg-6 text-center'>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={prop.colours}
                            getOptionLabel={(option) => option.colour_name}
                            name="colour_name"
                            className='mt-2'
                            value={prop.selectedColour}
                            onChange={(event, newValue)=>prop.setSelectedColour(newValue)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Colours"
                                placeholder="Colours"
                              />
                            )}
                          />
                        </div>
                        <div className='col-lg-6 text-center'>
                            <TextField
                                id="standard-basic" 
                                type="number" 
                                onKeyDown={ (evt) => 
                                  (evt.key === 'e' || evt.key === '-' || evt.key === '+')  
                                  && evt.preventDefault() 
                                }
                                label="Price" variant="standard" 
                                name="pro_price" value={prop.inputField['pro_price']} 
                                className="width-100 mt-2" onChange={prop.handleChange}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                                }}
                                required/>
                        </div>
                        <div className='col-lg-6 text-center'>
                            <TextField
                                id="standard-basic" 
                                type="number" 
                                onKeyDown={ (evt) => 
                                  (evt.key === 'e' || evt.key === '-' || evt.key === '+')  
                                  && evt.preventDefault() 
                                }
                                label="Discounted Price" variant="standard" 
                                name="dis_price" value={prop.inputField['dis_price']} 
                                className="width-100 mt-2" onChange={prop.handleChange}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                                }}
                                required/>
                        </div>
                        <div className='col-lg-6 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Items in stock" variant="standard" 
                                type="number"
                                name="items_in_stock" value={prop.inputField['items_in_stock']} 
                                className="width-100 mt-2" onChange={prop.handleChange}
                                required/>
                        </div>
                        <div className='col-lg-6 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Item Purchased" variant="standard" 
                                type="number"
                                name="item_purchased" value={prop.inputField['item_purchased']} 
                                className="width-100 mt-2" onChange={prop.handleChange}
                                required/>
                        </div>
                        <div className='col-lg-12 text-center'>
                            <textarea
                                name="pro_description" value={prop.inputField['pro_description']} 
                                className="width-100 mt-3" onChange={prop.handleChange}
                                placeholder="Description"
                                required/>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <Button type='submit' className='btn-black mt-4'>{prop.btn}</Button>
                    </div>
                </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
