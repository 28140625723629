import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import ProductView from "./ProductView";
import Spinner from "../../../../shared/Spinner";
import productsService from "../../../../services/products-service";
import SnackAlert from "../../../../shared/SnackAlert";
import router from "../../../../routePaths/routePaths";

export default function Product() {
    const {id} = useParams()
    let navigate = useNavigate();
    const color = '#a2a2a2';
    const [spinner, setSpinner] = useState(false);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [product, setProduct] = useState([]);
    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedColour, setSelectedColour] = useState(null);

    useEffect(async()=>{
        getProduct();
    },[id])

    const getProduct=async() =>{
        setSpinner(true);
        const res = await productsService.getProductById({id: id})
        setProduct(res.data)
        setSpinner(false);
    }

    const addToCart=async(e)=>{
        e.preventDefault();
        let items = JSON.parse(localStorage.getItem('cartItems'));
        let cartItem = items !== null ? items : [];
        var obj = Object.assign({}, product[0]);
        obj['sizeSelect'] = selectedSize;
        obj['colorSelect'] = selectedColour;
        obj['quantity'] = 1;
        obj['total_price'] = (obj.dis_price !== '0') ? obj.dis_price : obj.pro_price;
        if(checkCart(obj)){
            setMsg('Already added in cart with the same size. Please increase quantity if you want more than 1');
            setSeverity('info');
            setAlert(true);
            return;
        }
        cartItem.push(obj);
        localStorage.setItem('cartItems',JSON.stringify(cartItem));
        setMsg('Added in cart');
        setSeverity('success');
        setAlert(true);
    }

    const buyNow=async()=>{
        let items = JSON.parse(localStorage.getItem('cartItems'));
        let cartItem = items !== null ? items : [];
        var obj = Object.assign({}, product[0]);
        obj['sizeSelect'] = selectedSize;
        obj['colorSelect'] = selectedColour;
        obj['quantity'] = 1;
        obj['total_price'] = (obj.dis_price !== '0') ? obj.dis_price : obj.pro_price;
        if(checkCart(obj)){
            navigate(router.checkout)
            return;
        }
        cartItem.push(obj);
        localStorage.setItem('cartItems',JSON.stringify(cartItem));
        navigate(router.checkout)
    }

    const checkCart=(item)=>{
        let cartItems = JSON.parse(localStorage.getItem('cartItems'));
        if(cartItems === null){
            return false   
        }
        var i;
        for (i = 0; i < cartItems.length; i++) {
            if (cartItems[0].p_id === item.p_id  && cartItems[0].sizeSelect.id === item.sizeSelect.id) {
                return true;
            }
        }
        return false;
    }

    return (
        <>
            <Header 
                color={color}
            />
            <ProductView
                product = {product}
                selectedSize = {selectedSize}
                selectedColour = {selectedColour}

                setSelectedSize = {(e)=>setSelectedSize(e)}
                setSelectedColour = {(e)=>setSelectedColour(e)}
                addToCart = {(e)=>addToCart(e)}
                buyNow = {()=>buyNow()}
            />
            <Footer />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <Spinner
                spinner = {spinner}
            />
        </>
    );
}
