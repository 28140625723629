import React from "react";
import {
    Paper, Divider
} from '@mui/material';


export default function TermsView(props) {

    return (
        <div className="container">
            <h3 className="my-4 text-center font-bold">TERMS OF USE</h3>
            <Divider />
            <Paper elevation={1} sx={{p: 2, mb: 3}} square>
                <div className="row" style={{fontSize: '13px'}}>
                <h4>INTRODUCTION</h4>
<p>Welcome to Kultur King. These Terms of Use and our Privacy Policy govern your access and the use of culturekings.com, culturekings.au, and culturekings.co.nz including any content, functionality, and services offered on or through culturekings.com, culturekings.au, and culturekings.co.nz (the “Site”) or any related social media platforms by Kultur King. In these Terms of Use, the terms “Kultur King,” “we,” and “us” refers to Kultur King, its parent company a.k.a. Brands, Inc., and a.k.a. Brands Inc.’s respective subsidiaries and affiliated companies.</p>
<h4>ACCEPTANCE OF THE TERMS OF USE</h4>
<p>Please read the Terms of Use carefully before you use the Site. These Terms of Use apply to all visitors, Users and others who access or use the Site (collectively, “Users”). <strong>By using the Site, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at <a href="https://www.kulturking.com/privacy-policy" title="Privacy Policy">https://www.kulturking.com/privacy-policy</a>, incorporated herein by reference.</strong> If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Site. </p>
<p>This is a contract between you and Kultur King. Any use of the Site by anyone under thirteen (13) years of age is strictly prohibited and in violation of these Terms of Use. If you are under 18, you represent that you have your parent or guardian’s permission to use the Site and that your parent or guardian is agreeing to these Terms of Use. If you are a parent or legal guardian of a User of the Site under the age of 18, by allowing your child to use the Site, you are subject to the terms of these Terms of Use, and responsible for your child’s activity on the Site. Our Site is not available to any Users we previously removed from our Site. </p>
<h4>PRIVACY</h4>
<p>Please take time to read our <a href="/privacy-policy">Privacy Policy,</a> which provides a transparent view of how we collect, use, and share personal information. </p>
<h4>CHANGES TO THE TERMS OF USE</h4>
<p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them. </p>
<p>Your continued use of the Site following the posting of revised Terms of Use means that you accept and agree to the changes. We recommend that you check this page from time to time, so you are aware of any changes. </p>
<h4>ACCESSING THE SITE AND ACCOUNT SECURITY</h4>
<p>Subject to your compliance with the terms and conditions of these Terms of Use, you may access and use our Site solely for our personal, non-commercial use. We reserve all rights not expressly granted by these Terms of Use in and to our Site and our intellectual property. We may suspend or terminate your access to our Site at any time for any reason or no reason. </p>
<p>We may withdraw or amend the Site, and any service or material on the Site, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Site, or the entire Site, to Users, including registered Users, as applicable.</p>
<p>You are responsible for both:</p>
<ul>
<li>Making all arrangements necessary for you to have access to the Site.</li>
<li>Ensuring that all persons who access the Site through your internet connection are aware of these Terms of Use and comply with them.</li>
</ul>
<h4>USER ACCOUNTS</h4>
<p>Your account on our Site (your "User Account") gives you access to the services and functionality that we may establish and maintain from time to time and in our sole discretion. We may maintain different types of User Accounts for different types of Users.</p>
<p>You may never use another User’s User Account without permission. When creating your User Account, you must provide accurate and complete profile information, and you must keep this information up to date. You are solely responsible for the activity that occurs on your User Account, and you must keep your User Account password secure. We encourage you to use "strong" passwords (passwords that use a combination of upper and lowercase letters, numbers, and symbols) with your User Account. You must notify us immediately of any breach of security or unauthorized use of your User Account. We will not be liable for any losses caused by any unauthorized use of your User Account.</p>
<p>You agree that all information you provide to register with this Site or otherwise, including, but not limited to, through the use of any interactive features on the Site, is governed by our <a href="/privacy-policy">Privacy Policy</a> , and you consent to all actions we take with respect to your information consistent with our <a href="/privacy-policy">Privacy Policy</a>.</p>
<p>You may control certain aspects of your User Account and how you interact with our Site by changing the settings in your settings page. By providing us with your email address you consent to our using the email address to send you service-related notices, including any notices required by law, in lieu of communication by postal mail. We may also use your email address to send you other promotional messages, such as changes to features of our Site and special offers. If you do not want to receive such email messages, you may opt out or change your preferences by contacting us at <a href="mailto:info@kulturking.com">info@kulturking.com</a> or by clicking the unsubscribe link within each promotional message. Opting out may prevent you from receiving email messages regarding updates, improvements, or offers; however, opting out will not prevent you from receiving service-related notices.</p>
<p>You acknowledge that you do not own the User Account you use to access our Site. Notwithstanding anything in these Terms of Use to the contrary, you agree that we have the absolute right to manage, regulate, control, modify and/or eliminate any data stored by us or on our behalf on our (including by our third party hosting providers’) servers as we see fit in our sole discretion, in any general or specific case, and that we will have no liability to you based on our exercise of such right. All data on our servers are subject to deletion, alteration, or transfer. NOTWITHSTANDING ANY VALUE ATTRIBUTED TO SUCH DATA BY YOU OR ANY THIRD PARTY, YOU UNDERSTAND AND AGREE THAT ANY DATA, USER ACCOUNT HISTORY AND USER ACCOUNT CONTENT RESIDING ON OUR SERVERS, MAY BE DELETED, ALTERED, MOVED OR TRANSFERRED AT ANY TIME FOR ANY REASON IN OUR DISCRETION, WITH OR WITHOUT NOTICE AND WITH NO LIABILITY OF ANY KIND. WE DO NOT PROVIDE OR GUARANTEE, AND EXPRESSLY DISCLAIMS, ANY VALUE, CASH OR OTHERWISE, ATTRIBUTED TO ANY DATA RESIDING ON OUR SERVERS.</p>
<p>By connecting to our Site with a third-party service, you give us permission to access and use your information from that service as permitted by that service, and to store your log-in credentials for that service.</p>
<h4>INTELLECTUAL PROPERTY RIGHTS</h4>
<p>Except for your User Content, you understand and accept that our Site and all materials therein or transferred thereby, including, without limitation, all information, data, text, software, music, sound, photographs, graphics, logos, patents, trademarks, service marks, copyrights, audio, video, message or other material appearing on this Site, including User Content belonging to other Users (collectively, “Kultur King Content”),and all Intellectual Property rights related thereto, are the exclusive property of Kultur King and its licensors (including other Users who post User Content to our Site).You are expressly prohibited from using any Kultur King Content without the express written consent of Kultur King or its licensors. Except as otherwise stated in these Terms, none of the material may be reproduced, distributed, republished, downloaded, displayed, posted, transmitted, or copied in any form or by any means, without the prior written permission of Kultur King, and/or the appropriate licensor. Permission is granted to display, copy, distribute, and download the materials on this Site solely for personal, non-commercial use provided that you make no modifications to the materials and that all copyright and other proprietary notices contained in the materials are retained. You may not, without Kultur King' express written permission, 'mirror' any material contained on this Site or any other server. Any permission granted under these Terms terminates automatically without further notice if you breach any of the above terms. Upon such termination, you agree to immediately destroy any downloaded and/or printed materials. Any unauthorized use of any material contained on this Site may violate domestic and/or international copyright laws, the laws of privacy and publicity, and communications regulations and statutes.</p>
<p>ANY USE OF THE SERVICES NOT SPECIFICALLY PERMITTED UNDER THESE TERMS IS STRICTLY PROHIBITED.</p>
<h3>PROHIBITED USES</h3>
<p>You may use the Site only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Site:</p>
<ul>
<li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries). </li>
<li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</li>
<li>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
<li>To impersonate or attempt to impersonate Kultur King, a Kultur King employee, another User, or any other person or entity (including, without limitation, by using email addresses associated with any of the foregoing).</li>
<li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Site, or which, as determined by us, may harm Kultur King or Users of the Site, or expose them to liability.</li>
</ul>
<p>Additionally, you agree not to:</p>
<ul>
<li>Use the Site in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Site, including their ability to engage in real time activities through the Site.</li>
<li>Use any robot, spider, or other automatic device, process, or means to access the Site for any purpose, including monitoring or copying any of the material on the Site.</li>
<li>Use any manual process to monitor or copy any of the material on the Site, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</li>
<li>Use any device, software, or routine that interferes with the proper working of the Site.</li>
<li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
<li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Site, the server on which the Site is stored, or any server, computer, or database connected to the Site. </li>
<li>Attack the Site via a denial-of-service attack or a distributed denial-of-service attack.</li>
<li>Otherwise attempt to interfere with the proper working of the Site.</li>
<li>Use the Site in competition with us, to develop competing products or services, or otherwise to our detriment or commercial disadvantage.</li>
<li>Use the Site for benchmarking or competitive analysis of the Site. </li>
<li>Identify us or display any portion of our Site on any site or service that disparages us or our products or services, or infringes any of our intellectual property or other rights.</li>
<li>Identify or refer to us or our Site in a manner that could reasonably imply an endorsement, relationship or affiliation with or sponsorship between you or a third party and us, other than your permitted use of our Site under these Terms, without our express written consent.</li>
</ul>
<h4>USER CONTENT</h4>
<p>The Site allows Users to submit, post, display, provide, or otherwise disclose, or offer in connection with your use of this Site, content, including content from or via third parties or third-party services or other websites such as Facebook or Instagram that may interact with this Site, including comments, ideas, images, photographs, video clips, audio clips, graphics, tags, data, materials, information, and other submissions, including submissions with any hashtags (collectively, “User Content”). User Content may include personal information. WE CLAIM NO OWNERSHIP RIGHTS OVER USER CONTENT. However, you specifically grant us a non-exclusive, transferable, sub-licensable, royalty-free, fully paid up, worldwide license (but not the obligation) to use any User Content (“IP License”). The IP License includes, for example and without limitations, the right and license to use, reproduce, modify, edit, adapt, publish, translate, create derivative works from, distribute, perform and display such material (in whole or part) worldwide and/or to incorporate it in other works in any form, media, or technology now known or later developed, in both digital and physical owned channels, and will not be limited in any way in its use or modifications to the submission, whether for commercial purposes or not, of the User Content. In certain circumstances Kultur King may also share your contribution with trusted third parties. You are also granting us a non-exclusive, transferable, sub-licensable, royalty-free, fully paid up, worldwide license (but not the obligation) to use your name, likeness, personality, voice, or any other materials or information you provide to Kultur King in connection with your content.</p>
<p>You further grant, and you represent and warrant that you have all rights necessary to grant, Kultur King an irrevocable, transferable, sublicensable (through multiple tiers), fully paid, royalty-free, and worldwide right and license to use, copy, store, modify, and display your User Content: (a) to maintain and provide the Site to you; (b) solely in de-identified form, to improve our products and services and for our other business purposes, such as data analysis, customer research, developing new products or features, and identifying usage trends (and we will own such de-identified data); and (c) to perform such other actions as authorized by you in connection with your use of the Site.</p>
<p>You understand and agree that it is your obligation to make sure the User Content you submit to the Site must not violate any law or infringe any rights of any third party, including but not limited to any Intellectual Property rights and privacy rights, and you have obtained and are solely responsible for obtaining all consents as may be required by law to post any User Content relating to third parties. You also understand and agree that User Content you submit to the Site must not be and will not contain libelous or otherwise unlawful, abusive, obscene, or otherwise objectionable material in Kultur King’s sole discretion. For example, and without limitation, you may not post violent, nude, partially nude, discriminatory, unlawful, infringing, hateful, pornographic or sexually suggestive photos or other content via the Site or other websites such as Facebook or Instagram that may interact with this Site.</p>
<p>Kultur King is not and shall not be under any obligation (1) to maintain any User Content in confidence; (2) to pay you any compensation for any User Content; (3) to credit or acknowledge you for User Content; or (4) to respond to any User Content. We take no responsibility and assume no liability for any User Content that you or any other User or third-party posts, sends, or otherwise makes available over our Site. You shall be solely responsible for your User Content and the consequences of posting, publishing it, sharing it, or otherwise making it available on our Site, and you agree that we are only acting as a passive conduit for your online distribution and publication of your User Content. You understand and agree that you may be exposed to User Content that is inaccurate, objectionable, inappropriate for children, or otherwise unsuited to your purpose, and you agree that Kultur King shall not be liable for any damages you allege to incur as a result of or relating to any User Content.</p>
<p>If you do not want to grant Kultur King the permission set out above on these terms, please do not submit User Content. </p>
<h4>MONITORING AND ENFORCEMENT; TERMINATION</h4>
<p>We have the right to:</p>
<ul>
<li>Remove or refuse to post any User Content for any or no reason in our sole discretion.</li>
<li>Take any action with respect to any User Content that we deem necessary or appropriate in our sole discretion, including if we believe that such User Content violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of Users of the Site or the public, or could create liability for Kultur King.</li>
<li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
<li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Site. </li>
<li>Terminate or suspend your access to all or part of the Site for any or no reason, including without limitation, any violation of these Terms of Use.</li>
</ul>
<p>Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Site. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY/ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER THE COMPANY/SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</p>
<p>However, we do not undertake to review all material before it is posted on the Site and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any User or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section. </p>
<h4>CONTENT STANDARDS</h4>
<p>These content standards apply to any and all User Content. User Content must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Content must not:</p>
<ul>
<li>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
<li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</li>
<li>Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.</li>
<li>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our <a href="/privacy-policy">Privacy Policy</a>.</li>
<li>Be likely to deceive any person.</li>
<li>Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
<li>Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.</li>
<li>Impersonate any person or misrepresent your identity or affiliation with any person or organization. </li>
<li>Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising.</li>
<li>Give the impression that they emanate from or are endorsed by us or any other person or entity if this is not the case.</li>
</ul>
<h4>INTELLECTUAL PROPERTY/COPYRIGHT INFRINGEMENT</h4>
<p>We respect the intellectual property rights of others. If you are a copyright owner or an agent thereof and believe that any User Content featured on our Site infringes your copyright, or other intellectual property rights, please notify us by providing the following information:</p>
<ul>
<li>Your address, telephone number, and email address.</li>
<li>A description of the copyrighted work that you claim has been infringed.</li>
<li>A description of where the alleged infringing material is located on our Site.</li>
<li>A statement by you that you have a good faith belief that the disputed use is not authorized by you, the copyright owner, its agents, or otherwise allowed for applicable law.</li>
<li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyrighted material.</li>
<li>A statement by you that the above information is accurate and that you are the copyright owner or authorized to act on behalf of the copyright owner.</li>
</ul>
<p>Failure to provide us with notice in accordance with the terms above may render your notice invalid.  </p>
<p>Please send your notice to <a href="info@kulturking.com">info@kulturking.com</a>.  All other inquiries or complaints should be directed to customer service. </p>
<h4>RELIANCE ON INFORMATION POSTED</h4>
<p>The information presented on or through the Site is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Site, or by anyone who may be informed of any of its contents.</p>
<p>THE SITE MAY CONTAIN LINKS TO THIRD-PARTY MATERIALS, SITES OR SERVICES THAT ARE NOT OWNED OR CONTROLLED BY Kultur King AND CERTAIN FUNCTIONALITY OF THE SITE MAY REQUIRE INTEGRATION WITH OR YOUR USE OF THIRD-PARTY SERVICES. IF YOU USE A THIRD-PARTY SERVICE, YOU ARE SUBJECT TO AND AGREE TO THE THIRD PARTY’S TERMS OF SERVICE AND PRIVACY POLICY MADE AVAILABLE ON THEIR SERVICES. WE DO NOT ENDORSE OR ASSUME ANY RESPONSIBILITY FOR ANY SUCH THIRD-PARTY SITES, INFORMATION, MATERIALS, PRODUCTS, OR SERVICES. IF YOU ACCESS A THIRD-PARTY WEBSITE OR SERVICE FROM OUR SERVICE OR SHARE YOUR USER CONTENT ON OR THROUGH ANY THIRD-PARTY WEBSITE OR SERVICE, YOU DO SO AT YOUR OWN RISK, AND YOU UNDERSTAND THAT THIS AGREEMENT AND OUR PRIVACY POLICY DO NOT APPLY TO YOUR USE OF SUCH SERVICES OR SITES. YOU EXPRESSLY RELIEVE Kultur King FROM ANY AND ALL LIABILITY ARISING FROM YOUR USE OF ANY THIRD-PARTY WEBSITE, SERVICE, OR CONTENT, INCLUDING WITHOUT LIMITATION USER CONTENT SUBMITTED BY OTHER USERS. </p>
<h4>CHANGES TO THE SITE</h4>
<p>We may update the content on this Website from time to time, but its content is not necessarily complete or up to date. Any of the material on the Site may be out of date at any given time, and we are under no obligation to update such material. </p>
<h4>INFORMATION ABOUT YOU AND YOUR VISITS TO THE SITE</h4>
<p>All information we collect on this Website is subject to our <a href="/privacy-policy">Privacy Policy</a>. By using the Site, you consent to all actions taken by us with respect to your information in compliance with the <a href="/privacy-policy">Privacy Policy</a>. </p>
<h4>LINKING TO THE SITE AND SOCIAL MEDIA FEATURES</h4>
<p>You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express consent. </p>
<p>This Website may provide certain social media features that enable you to:</p>
<ul>
<li>Link from your own or certain third-party websites to certain content on this Website.</li>
<li>Send emails or other communications with certain content, or links to certain content, on this Website.</li>
<li>Cause limited portions of content on this Website to be displayed or appear to be displayed on your own or certain third-party websites.</li>
</ul>
<p>You may use these features solely as they are provided by us, and solely with respect to the content they are displayed with, and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:</p>
<ul>
<li>Establish a link from any website that is not owned by you.</li>
<li>Cause the Site or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.</li>
<li>Link to any part of the Site other than the homepage.</li>
<li>Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use.</li>
</ul>
<p>The website from which you are linking, or on which you make our content accessible, must comply in all respects with the Content Standards set out in these Terms of Use.</p>
<p>We reserve the right to withdraw linking permission without notice.</p>
<p>We may disable all or any social media features and any links at any time without notice in our discretion. </p>
<h4>LINKS FROM THE SITE</h4>
<p>If the Site contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>
<h4>DISCLAIMER OF WARRANTIES</h4>
<p>FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO THE BELOW EXCLUSIONS MAY NOT APPLY TO YOU. THESE TERMS GIVE YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS AND EXCLUSIONS UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.</p>
<p>THIS SITE AND ALL CONTENTS OF THE SITE ARE PROVIDED ON AN 'AS IS' BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE, NONINFRINGEMENT, IMPLIED WARRANTIES OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM Kultur King OR THROUGH OUR SITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE FOREGOING, Kultur King, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR CORRECT; THAT OUR SITE WILL MEET YOUR REQUIREMENTS; THAT OUR SITE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF THE SITE. WE ATTEMPT TO ENSURE THAT INFORMATION PROVIDED ON OR IN CONNECTION WITH THE SERVICE COMPLETE, ACCURATE AND CURRENT. DESPITE OUR EFFORTS, SUCH INFORMATION MAY OCCASIONALLY BE INACCURATE, INCOMPLETE OR OUT OF DATE. ACCORDINGLY, WE MAKE NO REPRESENTATION AS TO THE COMPLETENESS, ACCURACY OR CURRENTNESS OF SUCH INFORMATION, INCLUDING ALL DESCRIPTIONS, IMAGES, REFERENCES, FEATURES, CONTENT, SPECIFICATIONS AND SERVICES DESCRIBED OR DEPICTED ON THE SERVICE. SUCH INFORMATION IS ALSO SUBJECT TO CHANGE AT ANY TIME WITHOUT NOTICE.</p>
<p>FURTHER, Kultur King DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH OUR SITE OR ANY HYPERLINKED WEBSITE OR SERVICE, AND Kultur King WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
<h4>LIMITATION ON LIABILITY</h4>
<p>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. </p>
<p>IN NO EVENT SHALL Kultur King, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO Kultur King HEREUNDER OR $100.00, WHICHEVER IS GREATER.</p>
<p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
<h4>INDEMNIFICATION</h4>
<p>You agree to defend, indemnify, and hold harmless Kultur King, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Site, including, but not limited to, your User Content, any use of the Site's content, services, and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Site.</p>
<h4>ARBITRATION</h4>
<p>Using or accessing the Site constitutes your acceptance of this Arbitration provision. Please read it carefully as it provides that you and Kultur King will waive any right to file a lawsuit in court or participate in a class action for matters within the terms of the Arbitration provision.</p>
<p>The arbitrator will conduct hearings, if any, by teleconference or videoconference, rather than by personal appearances, unless the arbitrator determines upon request by you or by us that an in-person hearing is appropriate. Any in-person appearances will be held at a location which is reasonably convenient to both parties with due consideration of their ability to travel and other pertinent circumstances. If the parties are unable to agree on a location, such determination should be made by the applicable rule-making authority or by the arbitrator. The arbitrator’s decision will follow the terms of these Terms of Use and will be final and binding. The arbitrator will have authority to award temporary, interim, or permanent injunctive relief or relief providing for specific performance of these Terms of Use, but only to the extent necessary to provide relief warranted by the individual claim before the arbitrator. The award rendered by the arbitrator may be confirmed and enforced in any court having jurisdiction thereof. Notwithstanding any of the foregoing, nothing in these Terms of Use will preclude you from bringing issues to the attention of federal, state, or local agencies.</p>
<h4>WAIVER AND SEVERABILITY</h4>
<p>No waiver by Kultur King of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Kultur King to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
<p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect. </p>
<h4>ENTIRE AGREEMENT</h4>
<p>The Terms of Use and our <a href="/privacy-policy">Privacy Policy</a>, and <a href="/terms-of-service">Terms of Sale</a>, constitute the sole and entire agreement between you and Kultur King regarding the Site and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Site. </p>
<h4>YOUR COMMENTS AND CONCERNS</h4>
<p>All notices of copyright infringement claims should be sent to: <a href="mailto:info@kulturking.com">info@kulturking.com</a>.</p>
                </div>
            </Paper>
        </div>
    );
}
