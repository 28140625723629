import React from 'react'
import { Routes, Route, Navigate  } from 'react-router-dom'
import './App.css'
import AdminBrands from './pages/Dashboard/Admin/Brands/AdminBrands'
import AdminCategory from './pages/Dashboard/Admin/Category/AdminCategory'
import AdminCollections from './pages/Dashboard/Admin/Collections/AdminCollections'
import Dashboard from './pages/Dashboard/Admin/Dashboard/Dashboard'
import AdminOrders from './pages/Dashboard/Admin/Orders/AdminOrders'
import AdminProducts from './pages/Dashboard/Admin/Products/AdminProducts'
import AdminProfile from './pages/Dashboard/Admin/Profile/AdminProfile'
import AdminSize from './pages/Dashboard/Admin/Size/AdminSize'
import Subscriptions from './pages/Dashboard/Admin/Subscriptions/Subscriptions'
import AdminUsers from './pages/Dashboard/Admin/Users/AdminUsers'
import Brands from './pages/Dashboard/User/Brands/Brands'
import Cart from './pages/Dashboard/User/Cart/Cart'
import Checkout from './pages/Dashboard/User/Checkout/Checkout'
import Collections from './pages/Dashboard/User/Collections/Collections'
import CollectionsList from './pages/Dashboard/User/CollectionsList/CollectionsList'
import History from './pages/Dashboard/User/History/History'
import Home from './pages/Dashboard/User/Home/Home'
import Login from './pages/Dashboard/User/Login/Login'
import Privacy from './pages/Dashboard/User/Privacy/Privacy'
import Product from './pages/Dashboard/User/Product/Product'
import Profile from './pages/Dashboard/User/Profile/Profile'
import Register from './pages/Dashboard/User/Register/Register'
import Terms from './pages/Dashboard/User/Terms/Terms'
import router from './routePaths/routePaths'

const App = () => {
  const PrivateRoute = ({ children}) => {
    const isAdmin = JSON.parse(localStorage.getItem('isLogged'));
    if(isAdmin !== null){
      if (isAdmin.user_type === 'admin' ) {
        return children
      } 
    }
      
    return <Navigate to={router.admin} />
  }
  function MissingRoute() {
    return < Navigate to={{pathname: router.home}} />
  }

  return (
    <Routes>
      {/* <Route exact path={router.admin} element={<Login/>}/> */}
      <Route exact path={router.login} element={<Login/>}/>
      <Route exact path={router.signup} element={<Register/>}/>
      <Route exact path={router.home} element={<Home/>}/>
      <Route exact path={router.brands} element={<Brands/>}/>
      <Route exact path={router.collections} element={<Collections/>}/>
      <Route exact path={router.collectionsList+'/:id'} element={<CollectionsList/>}/>
      <Route exact path={router.product+'/:id'} element={<Product/>}/>
      <Route exact path={router.cart} element={<Cart/>}/>
      <Route exact path={router.checkout} element={<Checkout/>}/>
      <Route exact path={router.profile} element={<Profile/>}/>
      <Route exact path={router.history} element={<History/>}/>
      <Route exact path={router.privacyPolicy} element={<Privacy/>}/>
      <Route exact path={router.termsOfService} element={<Terms/>}/>
      <Route exact path={router.dashboard} element={
        <PrivateRoute>
          <Dashboard/>
        </PrivateRoute>}
      />
      <Route exact path={router.adminSize} element={
        <PrivateRoute>
          <AdminSize/>
        </PrivateRoute>}
      />
      <Route exact path={router.adminCategory} element={
        <PrivateRoute>
          <AdminCategory/>
        </PrivateRoute>}
      />
      <Route exact path={router.adminCollections} element={
        <PrivateRoute>
          <AdminCollections/>
        </PrivateRoute>}
      />
      <Route exact path={router.adminBrands} element={
        <PrivateRoute>
          <AdminBrands/>
        </PrivateRoute>}
      />
      <Route exact path={router.adminProducts} element={
        <PrivateRoute>
          <AdminProducts/>
        </PrivateRoute>}
      />
      <Route exact path={router.adminOrders} element={
        <PrivateRoute>
          <AdminOrders/>
        </PrivateRoute>}
      />
      <Route exact path={router.adminProfile} element={
        <PrivateRoute>
          <AdminProfile/>
        </PrivateRoute>}
      />
      <Route exact path={router.adminUsers} element={
        <PrivateRoute>
          <AdminUsers/>
        </PrivateRoute>}
      />
      <Route exact path={router.adminSubscriptions} element={
        <PrivateRoute>
          <Subscriptions/>
        </PrivateRoute>}
      />
      <Route path="*" element={<MissingRoute/>} />
    </Routes>
  );
}

export default App;