import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Button, Divider } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

export default function AddCategory(prop) {

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={prop.open}
        onClose={prop.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={prop.open}>
          <Box sx={style} className='width-50'>
              <Typography className='font-bold'>{prop.btn}</Typography>
              <Divider className='my-2'/>
                <form onSubmit={prop.handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-6 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Name" variant="standard" 
                                name="category_name" value={prop.inputField['category_name']} 
                                className="width-90" onChange={prop.handleChange}
                                required/>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <Button type='submit' className='btn-black mt-4'>{prop.btn}</Button>
                    </div>
                </form>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={prop.colourOpen}
        onClose={prop.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={prop.colourOpen}>
          <Box sx={style} className='width-50'>
              <Typography className='font-bold'>{prop.colourBtn}</Typography>
              <Divider className='my-2'/>
                <form onSubmit={prop.handleColourSubmit}>
                    <div className='row'>
                        <div className='col-lg-6 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Name" variant="standard" 
                                name="gender_name" value={prop.inputColour['gender_name']} 
                                className="width-90" onChange={prop.handleColourChange}
                                required/>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <Button type='submit' className='btn-black mt-4'>{prop.colourBtn}</Button>
                    </div>
                </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
