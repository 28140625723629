import AxiosServices from './axios-service';
import {endpoints} from './api-end-points';

export class DashboardService {

  getDashboard(){    
    const res = AxiosServices.get(endpoints.dashboard.getDashboard);
    return res;
  }

  addCategory(body){    
    const res = AxiosServices.post(endpoints.dashboard.addCategory, body);
    return res;
  }

  editCategory(body){    
    const res = AxiosServices.post(endpoints.dashboard.editCategory, body);
    return res;
  }

}
const dashboardService = new DashboardService();
export default dashboardService;
