import React from "react";
import { styled } from '@mui/material/styles';
import { 
    Button, Typography, Table, TableBody, TableContainer,
    TableHead, TableRow, Paper, TablePagination 
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Sidenav from "../Common/Sidenav/Sidenav";
import { DeleteForeverOutlined, EditOutlined } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

export default function CategoryView(prop) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    
    return (
        <div className="main-container my-3">
            <div className="row m-2">
                <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                    <Sidenav/>
                </div>
                <div className="col-lg-10 col-xl-10 black-corner">
                    <div className="row">
                        <div className="col-6 p-0">
                            <Typography variant="subtitle1" className="font-bold m-2 font-gray">CATEGROIES</Typography>
                        </div>
                        <div className="col-6 p-0 align-self-center text-end">
                            <Button 
                                type="button"
                                className='btn-black fit_content px-3 mx-2'
                                onClick={prop.handleOpen}
                            >
                                    Add Category
                            </Button>
                            <Button 
                                type="button"
                                className='btn-black fit_content px-3 mx-2'
                                onClick={prop.handleColourOpen}
                            >
                                    Add Gender
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 p-0 mt-2" style={{textAlign: '-webkit-center'}}>
                            <Paper elevation={0} sx={{ width: '95%', overflow: 'hidden', border: '1px solid #eee'}}>
                                <TableContainer sx={{ height: '65vh' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>#</StyledTableCell>
                                                <StyledTableCell>Category</StyledTableCell>
                                                <StyledTableCell align="right">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {prop.size
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index+1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.category_name}
                                                        </TableCell>
                                                        <TableCell  align="right">
                                                            <Button 
                                                                type="buttnon" 
                                                                onClick={()=>prop.delItem(row.id, 'categories')} 
                                                                sx={{color: '#bc0000', background: '#bc00002e', p: '2px', minWidth: 0, m: 1}}
                                                            >
                                                                <DeleteForeverOutlined />
                                                            </Button>
                                                            <Button
                                                                type="buttnon" 
                                                                onClick={()=>prop.editItem(row)}
                                                                sx={{color: '#027c92', background: '#027c924d', p: '2px', minWidth: 0, m: 1}}
                                                            >
                                                                <EditOutlined/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                                    component="div"
                                    count={prop.size.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    SelectProps={{
                                        MenuProps: { classes: "selectDropdown" }
                                    }}
                                />
                            </Paper>
                        </div>
                        <div className="col-md-6 p-0 mt-2" style={{textAlign: '-webkit-center'}}>
                            <Paper elevation={0} sx={{ width: '95%', overflow: 'hidden' , border: '1px solid #eee'}}>
                                <TableContainer sx={{ height: '65vh' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>#</StyledTableCell>
                                                <StyledTableCell>Gender</StyledTableCell>
                                                <StyledTableCell align="right">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {prop.colours
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index+1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.gender_name}
                                                        </TableCell>
                                                        <TableCell  align="right">
                                                            <Button 
                                                                type="buttnon" 
                                                                onClick={()=>prop.delItem(row.id, 'genders')} 
                                                                sx={{color: '#bc0000', background: '#bc00002e', p: '2px', minWidth: 0, m: 1}}
                                                            >
                                                                <DeleteForeverOutlined />
                                                            </Button>
                                                            <Button
                                                                type="buttnon" 
                                                                onClick={()=>prop.editColourItem(row)}
                                                                sx={{color: '#027c92', background: '#027c924d', p: '2px', minWidth: 0, m: 1}}
                                                            >
                                                                <EditOutlined/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                                    component="div"
                                    count={prop.colours.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    SelectProps={{
                                        MenuProps: { classes: "selectDropdown" }
                                    }}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
