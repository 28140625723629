import React from "react";
import { alpha, styled } from '@mui/material/styles';
import { 
    Select, Divider, Table, TableBody, TableContainer,
    TableHead, TableRow, Paper, TablePagination, InputBase,
    Collapse, IconButton, MenuItem, FormControl, InputLabel, Grid
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { KeyboardArrowDown, KeyboardArrowUp, Search } from "@mui/icons-material";

const SearchBar = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    border: '1px solid #c9c6c6',
    borderRadius: '5px'
  }));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  })); 

export default function HistoryView(prop) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState('');

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    
    return (
        <div className="container">
            <h3 className="my-4 text-center font-bold">ORDER HISTORY</h3>
            <Divider />
            <Paper elevation={1} sx={{p: 2, mb: 3}} square>
                <Grid xs={12} sm={12} md={6} lg={6} sx={{mb: 2}}>
                    <SearchBar>
                        <SearchIconWrapper>
                            <Search />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search by Order Id"
                            inputProps={{ 'aria-label': 'search' }}
                            value={prop.searched}
                            onChange={(e) => prop.requestSearch(e.target.value)}
                        />
                    </SearchBar>
                </Grid>
                <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ height: '65vh' }}>
                        <Table aria-label="collapsible table" size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell sx={{py: '16px'}}>Order Id</StyledTableCell>
                                    <StyledTableCell>Quantity</StyledTableCell>
                                    <StyledTableCell>Price</StyledTableCell>
                                    <StyledTableCell>Shipping Method</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {prop.orders
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    let products = JSON.parse(row.products);
                                    return (
                                        <>
                                            <TableRow key={row.name}>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => {
                                                            if(open !== row.id){prop.getUserDetail(row)};
                                                            setOpen((row.id === open ? "" : row.id))
                                                        }}
                                                    >
                                                        {open === row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    {row.order_id}
                                                </TableCell>
                                                <TableCell>
                                                    {products.reduce((n, {quantity}) => (n + parseInt(quantity)), 0)}
                                                </TableCell>
                                                <TableCell>
                                                    Rs. {row.total_price} \-
                                                </TableCell>
                                                <TableCell>
                                                    {(row.shipping_method === 'pay_on_delivery') ? 'Cash on delivery' : 'Paid'}
                                                </TableCell>
                                                <TableCell>
                                                    <span className={row.status === "pending" ? 'pending' : 'complete'}>{row.status}</span>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                                                        <Paper elevation={1} sx={{ margin: 1 }}>
                                                            <div className="row">
                                                                <div className="col-md-9">
                                                                    <Paper elevation={0} sx={{ margin: 1, textAlign: 'center' }}>
                                                                        <p className="font-bold my-2">SHIPPING INFORMATION</p>
                                                                        <Table size="small" aria-label="purchases">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <StyledTableCell>Name</StyledTableCell>
                                                                                    <StyledTableCell>Email</StyledTableCell>
                                                                                    <StyledTableCell>Contact</StyledTableCell>
                                                                                    <StyledTableCell>Ship to</StyledTableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell>
                                                                                        {prop.user.fname}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {prop.user.email}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {row.receiver_contact}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {row.shipping_address}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                        <p className="font-bold my-2">PRODUCT(s) DETAIL</p>
                                                                        <Table size="small" aria-label="purchases">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <StyledTableCell>Code</StyledTableCell>
                                                                                    <StyledTableCell>Name</StyledTableCell>
                                                                                    <StyledTableCell>Price</StyledTableCell>
                                                                                    <StyledTableCell>Quantity</StyledTableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {products.map((x)=>{
                                                                                    return(
                                                                                        <TableRow>
                                                                                            <TableCell>
                                                                                                kk_{x.pro_id}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {x.pro_name}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {x.pro_price}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {x.quantity}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>
                                                                        <FormControl required size="small" sx={{my:2}}>
                                                                            <InputLabel id="demo-select-small">Status</InputLabel>
                                                                            <Select
                                                                                labelId="demo-select-small"
                                                                                id="demo-select-small"
                                                                                name="status"
                                                                                value={row.status}
                                                                                onChange={(e)=>prop.handleChange(e,row.id)}
                                                                                disabled = {true}
                                                                                >
                                                                                <MenuItem value='pending'>Pending</MenuItem>
                                                                                <MenuItem value='complete'>Complete</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Paper>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className='row'>
                                                                        {products.map(x=>{
                                                                            return(
                                                                                <div className="col-12">
                                                                                    <Paper elevation={1} sx={{ margin: 1 }}>
                                                                                        <div style={{position: 'relative'}}>
                                                                                            <div className="right-top">
                                                                                                <span>kk_{x.pro_id}</span>
                                                                                            </div>
                                                                                            <img src={x.pro_image} width='100%' height={155} alt="loading"/>
                                                                                        </div>
                                                                                    </Paper>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        </Paper>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                        component="div"
                        count={prop.orders.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                            MenuProps: { classes: "selectDropdown" }
                        }}
                    />
                </Paper>
            </Paper>
        </div>
    );
}
