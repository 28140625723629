import React, {useState} from "react";
import { 
    Button, TextField, InputAdornment, IconButton, Backdrop, Modal, Fade, Box, Typography, Divider, Paper
} from "@mui/material";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
  };

export default function ProfileView(prop) {
    const [showPassword, setShowPassword] = useState(false);
    
    return (
        <div className="container">
            <h3 className="my-4 text-center font-bold">PROFILE</h3>
            <Divider />
            <Paper elevation={1} sx={{p: 2, mb: 3}} square>
                <form onSubmit={prop.handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-6 mt-3 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="First Name" variant="standard" 
                                name="fname" value={prop.inputField['fname']} 
                                className="width-90" onChange={prop.handleChange}
                                required/>
                        </div>
                        <div className='col-lg-6 mt-3 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Last Name" variant="standard" 
                                name="lname" value={prop.inputField['lname']} 
                                className="width-90" onChange={prop.handleChange}
                                required/>
                        </div>
                        <div className='col-lg-6 mt-3 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Email" variant="standard" 
                                name="email" value={prop.inputField['email']} 
                                className="width-90" onChange={prop.handleChange}
                                required/>
                        </div>
                        <div className='col-lg-6 mt-3 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Address" variant="standard" 
                                name="address" value={prop.inputField['address']} 
                                className="width-90" onChange={prop.handleChange}
                                required/>
                        </div>
                        <div className='col-lg-6 mt-3 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Phone no" variant="standard" 
                                type={'number'}
                                name="ph_no" value={prop.inputField['ph_no']} 
                                className="width-90" onChange={prop.handleChange}
                                required/>
                        </div>
                        <div className='col-lg-6 mt-3 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Password" variant="standard" 
                                type={'password'}
                                name="password" value={prop.inputField['password']} 
                                className="width-90" onChange={prop.handleChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            onClick={()=>prop.setOpen()}
                                            edge="end"
                                        >
                                            <Edit />
                                        </IconButton>
                                    </InputAdornment>,
                                    readOnly: true
                                    }}
                                required/>
                        </div>
                    </div>
                    <div className='row justify-content-end'>
                        <Button type='submit' className='btn-black fit-content px-4 mt-4'>Update Profile</Button>
                    </div>
                </form>
            </Paper>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={prop.open}
                onClose={()=>prop.setClose()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={prop.open}>
                <Box sx={style} className='width-50'>
                    <Typography className='font-bold'>Update Password</Typography>
                    <Divider className='my-2'/>
                        <form onSubmit={prop.updateSubmit}>
                            <div className='row'>
                                <div className='col-lg-6 text-center'>
                                    <TextField
                                        id="standard-basic" 
                                        label="Password" variant="standard" 
                                        type={(showPassword) ? 'text':'password'}
                                        name="password" value={prop.inputPassword['password']} 
                                        className="width-90" onChange={prop.handlePasswordChange}
                                        required
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                onClick={()=>setShowPassword(!showPassword)}
                                                edge="end"
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            }}/>
                                </div>
                                <div className='col-lg-6 text-center'>
                                    <TextField
                                        id="standard-basic" 
                                        label="Confirm Password" variant="standard" 
                                        type={(showPassword) ? 'text':'password'}
                                        name="confirm_password" value={prop.inputPassword['confirm_password']} 
                                        className="width-90" onChange={prop.handlePasswordChange}
                                        error={prop.passwordNotMatch}
                                        helperText={prop.passwordNotMatch ? 'Passord do not match' : ' '}
                                        required/>
                                </div>
                            </div>
                            <div className='row justify-content-center'>
                                <Button type='submit' className='btn-black fit-content px-4 mt-4'>Update</Button>
                            </div>
                        </form>
                </Box>
                </Fade>
            </Modal>
        </div>
    );
}
