import React, { useState, useEffect } from "react";
import { State, City }  from 'country-state-city';
import SnackAlert from "../../../../shared/SnackAlert";
import Spinner from "../../../../shared/Spinner";
import Header from "../Common/Header/Header";
import RegisterView from "./RegisterView";
import authServices from "../../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import router from "../../../../routePaths/routePaths";

export default function Register() {
    let navigate = useNavigate();
    const color = '#a2a2a2';
    const states = State.getStatesOfCountry('PK');
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [spinner, setSpinner] = useState(false);
    const [lenError, setLenError] = useState(false);
    const [cities, setCities] = useState([]);
    const [inputField, setInputField] = useState({});
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
        if(e.target.name === 'ph_no'){
            console.log(e.target.value.length)
            if(e.target.value.length !== 12){
                setLenError(true);
            }else{
                setLenError(false);
            }
        }
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        inputField['state'] = selectedState.name;
        inputField['city'] = selectedCity.name;
        console.log(inputField);
        const res = await authServices.signup(inputField);
        if(res.data.error){
            setAlert(true);
            setSeverity('error');
            setMsg(res.data.message)
        }else{
            setAlert(true);
            setSeverity('success');
            setMsg('Sign up successfully. Please login to continue')
            localStorage.setItem('isLogged', JSON.stringify(res.data[0]))
            navigate(router.home)
        }
        setSpinner(false);
    }

    const onSelectedState=(e)=>{
        setSelectedState(e)
        setCities(City.getCitiesOfState(e.countryCode,e.isoCode))
    }

    useEffect(()=>{
    },[])
    
    return (
        <div>
            <Header 
                color={color}
            />
            <RegisterView
                inputField={inputField}
                selectedState = {selectedState}
                selectedCity = {selectedCity}
                states = {states}
                cities = {cities}
                lenError = {lenError}

                handleChange = {(e)=>handleChange(e)}
                setSelectedState = {(e)=>onSelectedState(e)}
                setSelectedCity = {(e)=>setSelectedCity(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}
