import * as React from 'react';
import { useNavigate } from "react-router-dom";
import {Divider, ButtonGroup, Button, Paper} from '@mui/material';
import Carousel from "react-multi-carousel";
import router from '../../../../routePaths/routePaths';
import logoSpinner from "../../../../assets/images/logo/logo_spinner.png";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 7
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function CartMobileView(props) {
  let navigate = useNavigate();

  return (
    <div className="container my-4 d-sm-block d-xs-block d-md-block d-lg-block d-xl-none">
      <div className='row'>
        <div className="col-md-6 text-center">
          <h5>YOUR CART</h5>
        </div>
        <div className="col-md-6 text-center">
          You've got <b>{props.cartItems.length}</b> items in your cart
        </div>
      </div>
      <Divider className="my-3" sx={{height: '2px', opacity: 1}}/>
      <div className='row'>
        <div className='col-md-7 p-0'>
          {props.cartItems.map((x,index)=>{
            return(
              <div className='row'>
                  <div className="col-4 font-bold">
                    <img src={x.pro_image[0]} width="100%" height="100px" alt="loading"/>
                  </div>
                  <div className="col-8 sale-price">
                    <div style={{cursor:'pointer'}} onClick={()=>navigate(router.product+'/'+x.p_id)}>
                      <img src={x.brand_image} width={'30%'} height="40px" alt="loading"/>
                      <p className="font-bold mb-2">{x.pro_name}</p>
                      <p className='mb-1'>{x.sizeSelect.size_name} | {x.dis_price === '0' && <p style={{margin: 0}}>Rs. {x.pro_price} /- </p>}
                        {x.dis_price !== '0' && <span>Rs. {x.dis_price} /- </span>}
                        {x.dis_price !== '0' && <del>Rs. {x.pro_price} /-</del>} /- </p>
                      </div>
                    <ButtonGroup size="small" aria-label="small outlined button group">
                      <Button onClick={()=>props.updateQuantity(index,'decrease')} disabled={x.quantity === 1}>-</Button>
                      <Button>{x.quantity}</Button>
                      <Button onClick={()=>props.updateQuantity(index,'increase')}>+</Button>
                    </ButtonGroup>
                    <Button sx={{textDecoration: 'underline', fontSize: '11px'}} onClick={()=>props.removeItem(index)}>remove</Button>
                  </div>
                  <Divider className="my-3"/>
                  
                </div>
            )
          })}
        </div>
        <div className='col-md-5'>
          <Paper elevation={1} sx={{p:3}}>
            <h5>ORDER SUMMARY</h5>
            <Divider className="my-3" sx={{height: '2px', opacity: 1}}/>
            <div className="row">
              <div className="col-6">
                <h6>TOTAL</h6>
              </div>
              <div className="col-6 text-end">
                <h6>Rs. {props.cartItems.reduce((n, {total_price}) => (n + parseInt(total_price)), 0)} /-</h6>
              </div>
            </div>
            <Button 
                type="button"
                className="btn-green width-100 mt-3 font-bold"
                onClick={()=>navigate(router.checkout)}>
                    PROCEED TO CHECKOUT
            </Button>
            <Button
                type="button"
                className="btn-black width-100 mt-3 font-bold"
                onClick={()=>navigate(router.collections)}>
                    CONTINUE SHOPPING
            </Button>
          </Paper>
        </div>
      </div>
      <h4 className="font-bold black-outline m-0 my-4">
        <img src={logoSpinner} width="50px" alt='loading'/>
      </h4>
      {props.related.length > 0 && <div className="row">
        <Paper elevation={0} square>
            <Carousel 
                autoPlay={false}
                responsive={responsive}>
                    {props.related.map((y,z)=>{
                      console.log(y.dis_price)
                        return(
                            <Paper elevation={1} sx={{m: 2}} square>
                                <div className="text-center pointer" onClick={()=>navigate(router.product+"/"+y.id)}>
                                    <img src={JSON.parse(y.pro_image)[0]} width='100%' height= "200px" alt={y}/>
                                    <h5>{y.pro_name}</h5>
                                    <div className="sale-price">
                                        {y.dis_price === '0' ? <p style={{margin: 0}}>Rs. {y.pro_price} /- </p> : 
                                        <>
                                          <span>Sale Rs. {y.dis_price} /- </span>
                                          <del>Rs. {y.pro_price} /-</del>
                                        </>}
                                        {/* {y.dis_price !== 0 && <span>Sale Rs. {y.dis_price} /- </span>}
                                        {y.dis_price !== 0 && <del>Rs. {y.pro_price} /-</del>} */}
                                    </div>
                                </div>
                            </Paper>
                        )
                    })}
            </Carousel>
        </Paper>
    </div>}
    </div>
  );
}
