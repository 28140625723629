import React, {useState, useEffect} from "react";
import commonService from "../../../../services/common-service";
import ConfirmDiallog from "../../../../shared/ConfirmDiallog";
import SnackAlert from "../../../../shared/SnackAlert";
import Spinner from "../../../../shared/Spinner";
import Header from "../Common/Header/Header";
import AddCategory from "./AddCategory";
import CategoryView from "./CategoryView";

export default function AdminCategory() {
    const [spinner, setSpinner] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [colourOpen, setColourOpen] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [btn, setBtn] = React.useState('Add Category');
    const [table_name, setTableName] = React.useState('category');
    const [colourBtn, setColourbtn] = React.useState('Add Gender');
    const [editId, setEditId] = React.useState();
    const [inputField, setInputField] = useState({});
    const [inputColour, setInputColour] = useState({});
    const [size, setSize] = useState([]);
    const [colours, setColours] = useState([]);


    useEffect(()=>{
        getSize();
        getColours();
    },[])

    const getSize=async() => {
        setSpinner(true);
        const res = await commonService.get_record({table_name: 'categories'});
        setSize(res.data);
        setSpinner(false);
    }
    const getColours=async() => {
        setSpinner(true);
        const res = await commonService.get_record({table_name: 'genders'});
        setColours(res.data);
        setSpinner(false);
    }
    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleColourChange=(e) => {
        setInputColour({...inputColour, [e.target.name]: e.target.value});
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        if(btn === 'Add Category'){
            inputField['table_name'] = 'categories'
            const res = await commonService.save_record(inputField);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setSize(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setOpen(false);
                setMsg('Category added successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }else{
            inputField['id'] = editId;
            inputField['table_name'] = 'categories';
            const res = await commonService.edit_record(inputField);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setSize(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setOpen(false);
                setMsg('Category updated successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }
        setBtn('Add Category');
        setSpinner(false);
    }

    const handleColourSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        if(colourBtn === 'Add Gender'){
            inputColour['table_name'] = 'genders'
            const res = await commonService.save_record(inputColour);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setColours(res.data);
                Object.keys(inputColour).forEach(key => delete inputColour[key]);
                setColourOpen(false);
                setMsg('Gender added successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }else{
            inputColour['id'] = editId;
            inputColour['table_name'] = 'genders';
            const res = await commonService.edit_record(inputColour);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setColours(res.data);
                Object.keys(inputColour).forEach(key => delete inputColour[key]);
                setColourOpen(false);
                setMsg('Gender updated successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }
        setColourbtn('Add Gender');
        setSpinner(false);
    }

    const delItem=async()=>{
        setSpinner(true);
        const res = await commonService.del_record({id: editId, table_name: table_name});
        if(res.data.status){
            if(table_name === 'genders'){
                setColours(colours.filter(function( obj ) {
                    return obj.id !== editId;
                }))
            }else{
                setSize(size.filter(function( obj ) {
                    return obj.id !== editId;
                }))
            }
        }
        setSpinner(false);
        setOpenDialog(false);
        (table_name === 'categories') ? setMsg('Category deleted successfuly') : setMsg('Gender deleted successfuly')
        setSeverity('success')
        setAlert(true)
    }

    const editItem=(item)=>{
        inputField['category_name'] = item.category_name;
        setOpen(true);
        setBtn('Update Category');
        setEditId(item.id);
    }

    const editColourItem=(item)=>{
        inputColour['gender_name'] = item.gender_name;
        setColourOpen(true);
        setColourbtn('Update Gender');
        setEditId(item.id);
    }

    return (
        <div>
            <Header />
            <CategoryView 
                size = {size}
                colours = {colours}

                handleOpen = {() => setOpen(true)}
                handleColourOpen = {() => setColourOpen(true)}
                delItem = {(id, table_name)=>{setEditId(id); setOpenDialog(true); setTableName(table_name)}}
                delColourItem = {(id)=>{setEditId(id); setOpenDialog(true)}}
                editItem = {(item)=>editItem(item)}
                editColourItem = {(item)=>editColourItem(item)}
            />
            <AddCategory
                open = {open}
                colourOpen = {colourOpen}
                inputField = {inputField}
                inputColour = {inputColour}
                btn = {btn}
                colourBtn = {colourBtn}
                
                handleClose = {() => {setOpen(false); setColourOpen(false); setBtn('Add Category'); setColourbtn('Add Gender')}}
                handleChange = {(e)=>handleChange(e)}
                handleColourChange = {(e)=>handleColourChange(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
                handleColourSubmit = {(e)=>handleColourSubmit(e)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <ConfirmDiallog
                openDialog = {openDialog}

                closeDialog = {()=>setOpenDialog(false)}
                delItem = {()=>delItem()}
            />
        </div>
    );
}
