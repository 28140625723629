import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import { Paper, Menu, Modal, MenuItem, Box, Toolbar, Typography, IconButton, Button, InputBase, Avatar} from '@mui/material';
import {PermIdentityOutlined, ShoppingCartOutlined, Search, Close} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../../../assets/images/logo/logo.png';
import router from '../../../../../routePaths/routePaths';
import { useNavigate } from 'react-router-dom';

const modelStyle = {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -0%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
    overflow: 'auto',
    height: '100%',
  };

export default function HeaderMobileView(props) {
    let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
        <AppBar position="static" className='d-sm-block d-xs-block d-md-block d-lg-block d-xl-none'>
            <div className="container p-0">
                <Toolbar>
                    <div className='row'>
                        <div className="col-4 text-start p-0">
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon
                                    onClick={()=>props.onOpenDrawer('Men')}
                                />
                            </IconButton>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                            >
                                <Search 
                                    onClick={props.setOpenMobileSearchModal}
                                />
                            </IconButton>
                        </div>
                        <div className="col-4 align-items-center p-0">
                            <Typography variant="h6" component="div" className='text-center'>
                                <img src={logo} width='60px' alt='loading' onClick={()=>navigate(router.home)}/>
                            </Typography>
                        </div>
                        <div className="col-4 text-end p-0">
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={(e)=>setAnchorEl(e.currentTarget)}
                                color="inherit"
                            >
                                <PermIdentityOutlined />
                            </IconButton>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={()=>props.setCartDrawer()}

                                color="inherit"
                            >
                                <ShoppingCartOutlined />
                            </IconButton>
                        </div>
                    </div>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={()=>setAnchorEl(null)}
                    >
                        {props.isLogged === null && <>
                            <MenuItem onClick={()=>navigate(router.signup)}>Register</MenuItem>
                            <MenuItem onClick={()=>navigate(router.login)}>Login</MenuItem>
                        </>}
                        {props.isLogged !== null && JSON.parse(props.isLogged).user_type !== 'admin' && <>
                            <MenuItem onClick={()=>navigate(router.profile)}>Profile</MenuItem>
                            <MenuItem onClick={()=>navigate(router.history)}>Order History</MenuItem>
                            <MenuItem onClick={props.logout}>Logout</MenuItem>
                        </>}
                        {props.isLogged !== null && JSON.parse(props.isLogged).user_type === 'admin' && <>
                            <MenuItem onClick={()=>navigate(router.dashboard)}>Dashboard</MenuItem>
                            <MenuItem onClick={props.logout}>Logout</MenuItem>
                        </>}
                    </Menu>
                    <Modal
                        backdrop="static"
                        open={props.openMobileSearchModal}
                        onClose={()=>props.setCloseMobileSearchModal()}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        disableScrollLock={true}
                        disableEnforceFocus={true}
                        disableAutoFocus={true}
                        disableRestoreFocus={true}
                        // sx={{top:'50%'}}
                        BackdropProps= {{
                            classes: {
                                root: "modal-backdrop"
                            }
                        }}
                    >
                        <Box sx={modelStyle}>
                            <div className='row'>
                                <div className='col-10'>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 ,width: '100%'}}
                                        placeholder="Search brands or products"
                                        onChange={props.handleChange}
                                    />
                                </div>
                                <div className='col-2' style={{flexDirection: 'row', display: 'flex'}}>
                                    <IconButton type="submit" sx={{ p: '0px' }} aria-label="search">
                                        <Search />
                                    </IconButton>
                                    <IconButton type="submit" sx={{ p: '0px' }} aria-label="search" onClick={()=>props.setCloseMobileSearchModal()}>
                                        <Close />
                                    </IconButton>
                                </div>
                            </div>
                            {props.homeRecord.featured_week !== undefined && props.homeRecord.featured_week.length > 0 &&
                                <Typography id="modal-modal-title" variant="subtitle2" component="h2" className="font-bold my-2">
                                    POPULAR PRODUCTS
                                </Typography>
                            }
                                {props.homeRecord.featured_week !== undefined && 
                                props.homeRecord.featured_week.map((x,index)=>{
                                    return(
                                        <div className='row p-2' style={{lineHeight: 0, overflow: 'hidden'}} onClick={()=>navigate(router.product+"/"+x.id)}>
                                            <div className='col-2 p-0'>
                                                <img src={JSON.parse(x.pro_image)[0]} width='100%' height='50px' alt={index}/>
                                            </div>
                                            <div className='col-10 align-self-center' style={{overflow: 'hidden'}}>
                                                <Typography id="modal-modal-title" variant="caption" component="h2" className="text-nowrap">
                                                    {x.pro_name}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                })}
                                {props.homeRecord.fit_rotation !== undefined && 
                                props.homeRecord.fit_rotation.map((x,index)=>{
                                    return(
                                        <div className='row p-2' style={{lineHeight: 0, overflow: 'hidden'}} onClick={()=>navigate(router.product+"/"+x.id)}>
                                            <div className='col-2 p-0'>
                                                <img src={JSON.parse(x.pro_image)[0]} width='100%' height='50px' alt={index}/>
                                            </div>
                                            <div className='col-10 align-self-center' style={{overflow: 'hidden'}}>
                                                <Typography id="modal-modal-title" variant="caption" component="h2" className="text-nowrap">
                                                    {x.pro_name}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                })}
                            {props.homeRecord.brands !== undefined && props.homeRecord.brands.length > 0 &&
                                <Typography id="modal-modal-title" variant="subtitle2" component="h2" className="font-bold my-2">
                                    POPULAR BRANDS
                                </Typography>
                            }
                            <div className='row mt-1'>
                                {props.homeRecord.brands !== undefined && 
                                props.homeRecord.brands.map((x,index)=>{
                                    return(
                                        <div className="col-4 p-1">
                                            <Paper className='mt-1' sx={{cursor: 'pointer'}} onClick={()=>navigate(router.collections+"/brand="+x.id)}>
                                                <img src={x.brand_image} width='100%' height='70px' alt={index}/>
                                            </Paper>
                                        </div>
                                    )
                                })
                                }
                                <Button className="btn-black mt-3" onClick={()=>navigate(router.collections)}>
                                    VIEW ALL NEW ARRIVALS
                                </Button>
                            </div>
                        </Box>
                    </Modal>
                </Toolbar>
            </div>
            <Toolbar className="px-2" sx={{background: props.color}}>
                <div className="row">
                    <div className="col-md-4 align-self-center text-center d-none d-md-block d-lg-block d-xl-block">
                        <Button type='button' className='btn-transparent font-14'>
                            SHOP EXCLUSIVE HEAT
                        </Button>
                    </div>
                    <div className="col-md-4 align-self-center text-center">
                        <Typography variant="subtitle1" className="font-14 font-bold nowrap">
                            500+ New Arrivals Weekly. Shop Now!
                        </Typography>
                    </div>
                    <div className="col-md-4 align-self-center text-end d-none d-md-block d-lg-block d-xl-block">
                        <Button type='button' className='btn-transparent font-14'>
                            SHOP sALE
                        </Button>
                    </div>
                </div>
            </Toolbar>
            <div className="row py-2 categories-avatar bg-white d-sm-block d-xs-block d-md-block d-lg-block d-xl-none" style={{flexDirection: 'row', flexWrap: 'nowrap',overflow: 'scroll'}}>
                <ul className="m-0">
                    {props.homeRecord.collections !== undefined && 
                        props.homeRecord.collections.map((x,index)=>{
                            return(
                                <li className="text-center" onClick={()=>navigate(router.collections+"/"+x.id)}>
                                    <Avatar
                                        alt={index}
                                        src={x.col_image}
                                        sx={{ width: 56, height: 56, p: 0, border: '3px solid #c20404'}}
                                    />
                                    <span className='font-black'>{x.col_name}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </AppBar>
  );
}
