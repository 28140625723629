import * as React from 'react';
import {
  Box, TextField, Autocomplete, Button
} from '@mui/material';
import InputMask from 'react-input-mask';

export default function CheckoutForm(props) {
  
  return (
    <form className='pt-3' onSubmit={props.handleSubmit}>
      <div className="row">
        <div className='col-lg-12 p-0'>
          <TextField
            id="outlined-basic" 
            label="Email" variant="outlined" 
            name="email" value={props.inputField['email']} 
            className="width-100" onChange={props.handleChange}
            required size='small'/>
        </div>
        <div className='col-lg-12 p-0'>
          <p className='my-2 p-0'>Shipping Address</p>
        </div>
        <div className='col-lg-6'>
          <TextField
            id="outlined-basic" 
            label="First Name" variant="outlined" 
            name="fname" value={props.inputField['fname']} 
            className="width-100 mt-2" onChange={props.handleChange}
            required size='small'/>
        </div>
        <div className='col-lg-6'>
          <TextField
            id="outlined-basic" 
            label="Last Name" variant="outlined" 
            name="lname" value={props.inputField['lname']} 
            className="width-100 mt-2" onChange={props.handleChange}
            required size='small'/>
        </div>
        <div className='col-lg-6'>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={props.states}
            getOptionLabel={(option) => option.name}
            className='mt-2'
            value={props.selectedState}
            onChange={(event, newValue)=>props.setSelectedState(newValue)}
            renderInput={(params) =>
              <TextField 
                {...params} id="outlined-basic" 
                label="Province" variant="outlined" 
                className="width-100" 
                required size='small'
              />}
          />
        </div>
        <div className='col-lg-6'>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={props.cities}
            getOptionLabel={(option) => option.name}
            className='mt-2'
            value={props.selectedCity}
            onChange={(event, newValue)=>props.setSelectedCity(newValue)}
            renderInput={(params) =>
              <TextField 
                {...params} id="outlined-basic" 
                label="City" variant="outlined" 
                className="width-100" 
                required size='small'
              />}
          />
        </div>
        <div className='col-lg-12'>
          <TextField
            id="outlined-basic" 
            label="Address" variant="outlined" 
            name="address" value={props.inputField['address']} 
            className="width-100 mt-2" onChange={props.handleChange}
            required size='small'/>
        </div>
        <div className='col-lg-12'>
        <InputMask
          mask="0399-9999999"
          maskChar=""
          value={props.inputField['ph_no']}
          onChange={props.handleChange}
        >
        {() => <TextField 
          id="standard-basic" 
          label="Phone Number" 
          variant="outlined" 
          name="ph_no"
          error={props.lenError}
          helperText={props.lenError ? 'Please enter valid phone number' : ' '} 
          className="width-100 mt-2"
          required size="small"
            />}
        </InputMask>
        </div>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color="inherit"
          disabled={props.activeStep === 0}
          onClick={props.handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button type="submit" className="btn-black px-3" disabled={props.lenError}>
          Continue
        </Button>
      </Box>
    </form>
  );
}
