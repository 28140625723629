import React from 'react';
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button, Paper
} from '@mui/material';
import logoSpinner from "../../../../assets/images/logo/logo_spinner.png";
import router from "../../../../routePaths/routePaths"

export default function Invoice(props) {
  let navigate = useNavigate();

  return (
    <div className="container">
      <Paper elevation={2} sx={{my:3, p:2}} id='pdf'>
        <div>
          <div className='container text-center my-3'>
            <span className='thanks'>Thanks for shopping. INVOICE has been sent to your email</span>
          </div>
          <div className='row place-content-center py-3'>
            <img src={logoSpinner} width="100px" style={{width:'100px'}} alt="loading"/>
          </div>
          <div className='row'>
            <div className='col-4 text-center align-self-center'><h5>INVOICE</h5></div>
            <div className='col-8'>
              <div className='row'>
                <div className='col-6 font-small text-end'>Order Id:</div>
                <div className='col-6 font-small'>{props.order.order_id}</div>
              </div>
              <div className='row'>
                <div className='col-6 font-small text-end'>Order Date:</div>
                <div className='col-6 font-small'>{props.order.datetime}</div>
              </div>
              <div className='row'>
                <div className='col-6 font-small text-end'>Payment Method:</div>
                <div className='col-6 font-small'>{props.inputField.shipping_method === 'pay_on_delivery' ? 'COD' : 'Paid'}</div>
              </div>
            </div>
          </div>
          <div className='row py-3 invoice' style={{overflow: 'auto'}}>
            <table style={{width: '95%'}}>
              <tr style={{border: '1px solid black', background: 'black',color: 'aliceblue'}}>
                <th>Product Code:</th>
                <th>Product Name:</th>
                <th>Image:</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
              {props.cartItems.map((x,index)=>{
                return(
                  <tr style ={{borderBottom: '1px solid #eee'}}>
                    <td>kk_{x.p_id}</td>
                    <td>{x.pro_name}</td>
                    <td><img src={x.pro_image[0]} style={{width:'100px', height:'100px'}} alt="loading"/></td>
                    <td>{x.quantity}</td>
                    <td>Rs. {(x.dis_price === 0) ? x.pro_price*x.quantity : x.dis_price*x.quantity} /- </td>
                  </tr>
                )
              })}
            </table>
          </div>
          <div className='row' style={{justifyContent:'end'}}>
            <div className="col-md-6">
              <table style={{width: '95%'}}>
                <tr>
                  <td>Subtotal</td>
                  <td className='text_end'>
                    Rs. {props.cartItems.reduce((n, {total_price}) => (n + parseInt(total_price)), 0)} /-
                  </td>
                </tr>
                <tr>
                  <td>Delivery Charges:</td>
                  <td className='text_end'>Rs. 0.00 /-</td>
                </tr>
                <tr style={{borderTop: '1px solid', borderBottom: '1px solid'}}>
                  <th>Total</th>
                  <th className='text_end'>Rs. {props.cartItems.reduce((n, {total_price}) => (n + parseInt(total_price)), 0)} /-</th>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button type="button" className="btn-black px-3" onClick={()=>navigate(router.collections)}>
            Continue shopping
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
