import AxiosServices from './axios-service';
import {endpoints} from './api-end-points';

export class ProductsService {

  getProducts(){    
    const res = AxiosServices.get(endpoints.products.getProducts);
    return res;
  }

  addProduct(body){    
    const res = AxiosServices.post(endpoints.products.addProduct, body);
    return res;
  }

  editProduct(body){    
    const res = AxiosServices.post(endpoints.products.editProduct, body);
    return res;
  }

  editProductFeatured(body){    
    const res = AxiosServices.post(endpoints.products.editProductFeatured, body);
    return res;
  }

  getProductDetail(body){    
    const res = AxiosServices.post(endpoints.products.getProductDetail, body);
    return res;
  }

  getHomeRecord(){
    const res = AxiosServices.get(endpoints.home.getHomeRecord);
    return res;
  }

  getProductByCat(body){
    const res = AxiosServices.post(endpoints.home.getProductByCat,body);
    return res;
  }

  getCollectionsByCat(body){
    const res = AxiosServices.post(endpoints.home.getCollectionsByCat,body);
    return res;
  }

  getProductById(body){
    const res = AxiosServices.post(endpoints.products.getProductById,body);
    return res;
  }

  getProductsByCol(body){
    const res = AxiosServices.post(endpoints.products.getProductsByCol,body);
    return res;
  }

}

const productsService = new ProductsService();
export default productsService;
