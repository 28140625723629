import React from "react";
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import TermsView from "./TermsView";

export default function Terms() {
    const color = '#a2a2a2';

    return (
        <>
            <Header 
                color={color}
            />
            <TermsView
            />
            <Footer />
        </>
    );
}
