import React, {useState, useEffect} from "react";
import commonService from "../../../../services/common-service";
import Spinner from "../../../../shared/Spinner";
import Header from "../Common/Header/Header";
import SubscriptionsView from "./SubscriptionsView";

export default function Subscriptions() {
    const [spinner, setSpinner] = React.useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [searched, setSearched] = useState("");
    const [rows, setRows] = useState([]);

    useEffect(()=>{
        getUsers();
    },[])

    const getUsers=async() => {
        setSpinner(true);
        const res = await commonService.get_record({table_name: 'subscriptions'});
        setRows(res.data)
        setSubscriptions(res.data);
        setSpinner(false);
    }

    const requestSearch = (searchedVal) => {
        const filteredRows = rows.filter((row) => {
          return row.email.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setSearched(searchedVal);
        setSubscriptions(filteredRows);
    };

    return (
        <div>
            <Header />
            <SubscriptionsView 
                subscriptions = {subscriptions}
                searched = {searched}

                requestSearch = {(e)=>requestSearch(e)}
            />
            <Spinner
                spinner = {spinner}
            />
        </div>
    );
}
