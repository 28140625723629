import CryptoJS from 'crypto-js';

export class HashServices {  

  async generate(body){  
    let mapString = "";
    Object.entries(body).map(([k,v]) => mapString += `${k}=${v}&`);      
    console.log(mapString);
    let hash = (CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(mapString.substr(0, mapString.length -1)), 
    CryptoJS.enc.Utf8.parse(body.HS_ChannelId),
      {
      keySize: 128 / 8,
      iv: CryptoJS.enc.Utf8.parse(body.HS_MerchantId), mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
      }));
      console.log(hash);
    }
}
const hashServices = new HashServices();
export default hashServices;
