import React from "react";
import { useNavigate } from "react-router-dom";
import { alpha, styled } from '@mui/material/styles';
import {
    Paper, Divider, InputBase, Grid
} from '@mui/material';
import { Search } from "@mui/icons-material";
import router from "../../../../routePaths/routePaths";


const SearchBar = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
        border: '1px solid #c9c6c6',
        borderRadius: '5px'
    },
  }));

export default function BrandsView(props) {
    let navigate = useNavigate();

    return (
        <div className="container">
            <h3 className="my-4 text-center font-bold">SHOP BRANDS</h3>
            <Divider />
            <Grid xs={12} sm={12} md={6} lg={6} sx={{my: 2}}>
                <SearchBar>
                    <SearchIconWrapper>
                        <Search />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                        value={props.searched}
                        onChange={(e) => props.requestSearch(e.target.value)}
                    />
                </SearchBar>
            </Grid>
            <Paper elevation={1} sx={{p: 2, mb: 3}} square>
                <div className="row">
                    {props.brands.map((x,index)=>{
                        return(
                            <div className="col-md-3 mt-3 pointer" onClick={()=>navigate(router.collections+"/brand="+x.id)}>
                                <img src={x.brand_image} width='100%' height={'100px'} className="my-2" alt={index}/>
                            </div>
                        )
                    })}
                </div>
            </Paper>
        </div>
    );
}
