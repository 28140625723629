import * as React from 'react';
import { Link, useNavigate } from "react-router-dom";
import {Drawer,Divider,IconButton,ButtonGroup,Button} from '@mui/material';
import {Close} from '@mui/icons-material';
import router from '../../../../../routePaths/routePaths';

export default function CartDrawer(props) {
  let navigate = useNavigate();
  return (
    <div>
        <Drawer
            anchor={'right'}
            open={props.cartDrawer}
            onClose={props.setCartDrawer}
        >
          <div className='row'>
            <div className="col-6 font-bold">YOUR CART</div>
            <div className="col-6 text-end">
              <Link className="font-black" to={router.cart} sx={{marginRight: '10px'}}>VIEW MY CART</Link>
              <IconButton sx={{ p: '0px' }} className="btn-white" onClick={props.setCartDrawer}>
                  <Close />
              </IconButton>
            </div>
          </div>
          <div className="p-3">
            {props.cartItems.map((x,index)=>{
              return(
                <div className='row'>
                  <div className="col-4 font-bold">
                    <img src={x.pro_image[0]} width="100%" height="100px" alt='loading'/>
                  </div>
                  <div className="col-8">
                    <div style={{cursor:'pointer'}} onClick={()=>navigate(router.product+'/'+x.p_id)}>
                      <img src={x.brand_image} width={'30%'} height="40px" alt='loading'/>
                      <p className='red-label my-2'>Limited Supply</p>
                      <p className="font-bold mb-2">{x.pro_name}</p>
                      <p className='mb-1'>{x.sizeSelect.size_name} | Rs. {(x.dis_price === '0') ? x.pro_price : x.dis_price} /- </p>
                    </div>
                    <ButtonGroup size="small" aria-label="small outlined button group">
                      <Button onClick={()=>props.updateQuantity(index,'decrease')} disabled={x.quantity === 1}>-</Button>
                      <Button>{x.quantity}</Button>
                      <Button onClick={()=>props.updateQuantity(index,'increase')}>+</Button>
                    </ButtonGroup>
                    <Button sx={{textDecoration: 'underline', fontSize: '11px'}} onClick={()=>props.removeItem(index)}>remove</Button>
                  </div>
                  {index === props.cartItems.length-1 && <Divider className="mt-3" sx={{height: '2px', opacity: 1}}/>}
                  {index !== props.cartItems.length-1 && <Divider className="mt-3"/>}
                </div>
              )
            })}
            <div className='row'>
              <div className='col-6'>
                <h6>TOTAL</h6>
              </div>
              <div className='col-6 text-end'>
                <h6>Rs. {props.cartItems.reduce((n, {total_price}) => (n + parseInt(total_price)), 0)} /-</h6>
              </div>
            </div>
            <Button 
                type="button"
                className="btn-green width-100 mt-3 font-bold"
                onClick={()=>navigate(router.checkout)}>
                    PROCEED TO CHECKOUT
            </Button>
            <Button
                type="button"
                className="btn-white width-100 mt-3 font-bold"
                onClick={()=>navigate(router.cart)}>
                    VIEW MY CART
            </Button>
            <Button
                type="button"
                className="btn-black width-100 mt-3 font-bold"
                onClick={props.setCartDrawer}>
                    CONTINUE SHOPPING
            </Button>
          </div>
        </Drawer>
    </div>
  );
}
