import React, { useState, useEffect } from "react";
import { State, City }  from 'country-state-city';
import SnackAlert from "../../../../shared/SnackAlert";
import Spinner from "../../../../shared/Spinner";
import Header from "../Common/Header/Header";
import CheckoutView from "./CheckoutView";
import CheckoutForm from "./CheckoutForm";
import orderService from "../../../../services/order-service";
import hashService from "../../../../services/hash-service";
import Invoice from "./Invoice";
import Footer from "../Common/Footer/Footer";
import { useSearchParams } from "react-router-dom";

export default function Checkout() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [activeStep, setActiveStep] = React.useState(0);
    const color = '#a2a2a2';
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [spinner, setSpinner] = useState(false);
    const [invoice, setInvoice] = useState(false);
    const [lenError, setLenError] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const states =  State.getStatesOfCountry('PK');
    const [cities, setCities] = useState([]);
    const [order, setOrder] = useState({});
    
    const [inputField, setInputField] = useState({});
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
        if(e.target.name === 'ph_no'){
            console.log(e.target.value.length)
            if(e.target.value.length !== 12){
                setLenError(true);
            }else{
                setLenError(false);
            }
        }
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleCheckout=async(e) => {
        e.preventDefault();
        setSpinner(true);
        let products = []
        cartItems.map((x)=>{
            let obj = {
                pro_id : x.p_id,
                pro_name : x.pro_name,
                quantity : x.quantity,
                pro_price : x.total_price,
                pro_image : x.pro_image[0],
                size : x.pro_image[0],
                colour : x.pro_image[0],
            }
            products.push(obj);
        });
        inputField['products'] = JSON.stringify(products);
        inputField['state'] = selectedState.name;
        inputField['city'] = selectedCity.name;
        inputField['total_price'] = cartItems.reduce((n, {total_price}) => (n + parseInt(total_price)), 0)
        const res = await orderService.checkout(inputField);
        if(res.data.error){
            setAlert(true);
            setSeverity('error');
            setMsg(res.data.message)
        }else{
            setOrder(res.data);
            setInvoice(true);
            localStorage.removeItem('cartItems');
        }
        setSpinner(false);
    }

    const getCartItem=()=>{
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if(items !== null){
            setCartItems(items);
        }
        let user = JSON.parse(localStorage.getItem('isLogged'));
        if(user !== null){
            setInputField(user);
            let filterState = states.filter(x=>x.name === user.state)
            setSelectedState(filterState[0]);
            let cities = City.getCitiesOfState(filterState[0].countryCode,filterState[0].isoCode)
            let filterCity = cities.filter(x=>x.name === user.city)
            setSelectedCity(filterCity[0]);
        }
    }

    const onSelectedState=(e)=>{
        setSelectedState(e)
        setCities(City.getCitiesOfState(e.countryCode,e.isoCode))
    }

    useEffect(()=>{
        getCartItem();
        console.log(searchParams.get("success"), searchParams.get("AuthToken"),searchParams.get("ReturnURL"))
        let obj = {
            "HS_ChannelId":"1002"
            ,"HS_MerchantId":"17805"
            ,"HS_StoreId":"023282"
            ,"HS_ReturnURL":"https://ca6f-39-52-197-2.ngrok.io/checkout"
            ,"HS_MerchantHash":"OUU362MB1up3/9C82u9LiFAj94tTZ5Sz5nSgw/GGOHseysiTfQaCpbsxM1aQkBDlbxc5OMqhewg="      
            ,"HS_MerchantUsername":"tugoxo"
            ,"HS_MerchantPassword":"TudeAWzJQVNvFzk4yqF7CA=="
            ,"HS_TransactionReferenceNumber":"1234"
            ,"HS_RequestHash":""
        }
        hashService.generate(obj);
    },[])
    
    return (
        <div>
            <Header 
                color={color}
            />
            {!invoice && <CheckoutView
                activeStep = {activeStep}
                cartItems={cartItems}
                inputField={inputField}

                handleChange = {(e)=>handleChange(e)}
                handleCheckout = {(e)=>handleCheckout(e)}
                handleBack = {()=>setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                form = {<CheckoutForm
                    cartItems={cartItems}
                    inputField={inputField}
                    selectedState = {selectedState}
                    selectedCity = {selectedCity}
                    states = {states}
                    cities = {cities}
                    lenError = {lenError}
                    activeStep = {activeStep}

                    setSelectedState = {(e)=>onSelectedState(e)}
                    setSelectedCity = {(e)=>setSelectedCity(e)}
                    handleChange = {(e)=>handleChange(e)}
                    handleSubmit = {(e)=>handleSubmit(e)}
                    handleBack = {()=>setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                />}
            />}
            {invoice && <Invoice
                inputField={inputField}
                order={order}
                cartItems={cartItems}
                spinner={()=>setSpinner(!spinner)}
            />}
            <Footer />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}
