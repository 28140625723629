import React, {useState, useEffect} from "react";
import collectionsService from "../../../../services/collections-service";
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import CollectionsView from "./CollectionsView";
import Spinner from "../../../../shared/Spinner";

export default function Collections() {
    const color = '#a2a2a2';
    const [spinner, setSpinner] = useState(false);
    const [collections, setCollections] = useState([]);
    let start = 0;
    let totalRecord = 0;
    let isLoading = false;

    useEffect(async()=>{
        setSpinner(true);
        getCollections(start);
    },[])

    const getCollections=async(body) =>{
        isLoading = true;
        const res = await collectionsService.getCollectionPagination({start: body});
        let data = await mapCollection(res.data.collections)
        setCollections(() => ([...data]));
        totalRecord = parseInt(res.data.count[0].count);
        start = body;
        isLoading = false;
        setSpinner(false);
    }

    const mapCollection = async(data)=>{
        let unique = [...new Set(data.map(item => item.c_id))];
        let products = [];
        unique.map(x=>{
            let filteredCol = data.filter(y=>y.c_id === x);
            let obj = {
                c_id : filteredCol[0].c_id,
                col_image : filteredCol[0].col_image,
                products : filteredCol
            }
            products.push(obj);
        })
        const mergedArray = collections
        await products.map(x=>{
            mergedArray.push(x);
        })
        return mergedArray;
    }

    const bottomReached = (e) => {
        let body = parseInt(start)+3
        if(!isLoading && totalRecord > body){
            getCollections(body);
        }
    };

    return (
        <>
            <Header 
                color={color}
            />
            <CollectionsView
                collections = {collections}
                isLoading = {isLoading}

                bottomReached = {()=>bottomReached()}
            />
            <Footer />
            <Spinner
                spinner = {spinner}
            />
        </>
    );
}
