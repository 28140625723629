import * as React from 'react';
import {AppBar, Toolbar, Badge, IconButton, MenuItem, Menu} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Logout, ShoppingCartOutlined,  } from '@mui/icons-material';
import router from '../../../../../routePaths/routePaths';
import { useNavigate } from 'react-router-dom';


export default function HeaderView(prop) {
    let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
        <AppBar position="static">
            <Toolbar>
                <div className='row'>
                    <div className="col-8 inline-flex align-items-center">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={prop.setOpenMobileNav}
                            edge="start"
                            className="d-lg-none d-xl-none d-md-block d-sm-block d-xs-block"
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <IconButton 
                            aria-label="cart"
                            onClick={(e)=>{setAnchorEl(e.currentTarget); prop.updateNotiStatus();}}
                            aria-haspopup="true"
                            >
                            <Badge badgeContent={prop.notiLength} color="secondary">
                                <ShoppingCartOutlined sx={{color: 'white'}} />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={()=>prop.logout()}
                            color="inherit"
                        >
                            <Logout />
                        </IconButton>
                    </div>
                </div>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={()=>setAnchorEl(null)}
                >
                    {prop.notifications.map(x=>{
                        return(
                        <MenuItem onClick={()=>navigate(router.adminOrders)}>{x.fname} has placed order at {x.datetime}</MenuItem>
                        )
                    })}
                </Menu>
            </Toolbar>
        </AppBar>
    </>
  );
}
