import React, {useState, useEffect} from "react";
import commonHelper from "../../../../helper/common_helper";
import productsService from "../../../../services/products-service";
import commonService from "../../../../services/common-service";
import ConfirmDiallog from "../../../../shared/ConfirmDiallog";
import SnackAlert from "../../../../shared/SnackAlert";
import Spinner from "../../../../shared/Spinner";
import Header from "../Common/Header/Header";
import AddProduct from "./AddProduct";
import ProductsView from "./ProductsView";

export default function AdminProducts() {
    const [spinner, setSpinner] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [btn, setBtn] = React.useState('Add Product');
    const [editId, setEditId] = React.useState();
    const [inputField, setInputField] = useState({});
    const [selectedCol, setSelectedCol] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedSize, setSelectedSize] = useState([]);
    const [selectedColour, setSelectedColour] = useState([]);
    const [selectedCat, setSelectedCat] = useState(null);
    const [selectedGen, setSelectedGen] = useState(null);
    const [images, setImages] = useState([]);
    const [productDetail, setProductDetail] = useState([]);
    const [products, setProducts] = useState([]);
    const [searched, setSearched] = useState("");
    const [rows, setRows] = useState([]);
    const [collections, setCollections] = useState([]);
    const [brands, setBrands] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [colours, setColours] = useState([]);
    const [categories, setCategories] = useState([]);
    const [genders, setGenders] = useState([]);


    useEffect(()=>{
        getProducts();
    },[])

    const getProducts=async() => {
        setSpinner(true);
        const res = await productsService.getProducts();
        setProducts(res.data.products);
        setRows(res.data.products);
        setCollections(res.data.collections);
        setBrands(res.data.brands);
        setSizes(res.data.size);
        setColours(res.data.colours);
        setCategories(res.data.categories)
        setGenders(res.data.genders)
        setSpinner(false);
    }

    const getProductDetail=async(item) => {
        setSpinner(true);
        const res = await productsService.getProductDetail({id: item});
        let sizeIds = JSON.parse(res.data[0].size_id);
        let colourIds = JSON.parse(res.data[0].colour_id);
        let productSizes = sizes.filter(item => sizeIds.includes(item.id));
        let productColours = colours.filter(item => colourIds.includes(item.id));
        res.data[0]['size'] = productSizes;
        res.data[0]['colours'] = productColours;

        setProductDetail(res.data);
        setSpinner(false);
    }
    const handleChange=(e) => {    
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        let formData = new FormData();
        if(btn === 'Add Product'){
            if(images.length === 0){
                setMsg('Please Select image');
                setSeverity('error');
                setAlert(true);
                return;
            }
            let sizeIds = selectedSize.map(function(item) {
                return item['id'];
              });
            let colourIds = selectedColour.map(function(item) {
                return item['id'];
            });
            inputField['collection_id'] = selectedCol.id;
            inputField['category_id'] = selectedCat.id;
            inputField['gender_id'] = selectedGen.id;
            inputField['brand_id'] = selectedBrand.id;
            inputField['size_id'] = JSON.stringify(sizeIds);
            inputField['colour_id'] = JSON.stringify(colourIds);
            formData = commonHelper.createFormData(formData,inputField);
            for (let i = 0; i < images.length; i++) {
                formData.append(`pro_image[${i}]`, images[i])
            }
            formData.append('pro_sale', 0);
            formData.append('pro_releases', 0);
            formData.append('pro_featured', 0);
            const res = await productsService.addProduct(formData);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setProducts(res.data);
                setRows(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setImages([]);
                setOpen(false);
                setMsg('Product added successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }else{
            let sizeIds = selectedSize.map(function(item) {
                return item['id'];
            });
            let colourIds = selectedColour.map(function(item) {
                return item['id'];
            });
            let obj = {};
            obj['id'] = editId;
            obj['pro_name'] = inputField.pro_name;
            obj['pro_description'] = inputField.pro_description;
            obj['pro_price'] = inputField.pro_price;
            obj['dis_price'] = inputField.dis_price;
            obj['items_in_stock'] = inputField.items_in_stock;
            obj['pro_sale'] = inputField.pro_sale;
            obj['pro_releases'] = inputField.pro_releases;
            obj['item_purchased'] = inputField.item_purchased;
            obj['pro_featured'] = inputField.pro_featured;
            obj['collection_id'] = selectedCol.id;
            obj['category_id'] = selectedCat.id;
            obj['gender_id'] = selectedGen.id;
            obj['brand_id'] = selectedBrand.id;
            obj['size_id'] = JSON.stringify(sizeIds);
            obj['colour_id'] = JSON.stringify(colourIds);
            formData = commonHelper.createFormData(formData,obj);
            for (let i = 0; i < images.length; i++) {
                formData.append(`pro_image[${i}]`, images[i])
            }
            const res = await productsService.editProduct(formData);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setProducts(res.data);
                setRows(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setImages([]);
                setOpen(false);
                setMsg('Product updated successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }
        setSelectedBrand(null);
        setSelectedCol(null);
        setSelectedCat(null);
        setSelectedGen(null);
        setSelectedSize([]);
        setSelectedColour([]);
        setSpinner(false);
    }

    const resetFields = () => {
        Object.keys(inputField).forEach(key => delete inputField[key]);
        setImages([]);
        setSelectedBrand(null);
        setSelectedCol(null);
        setSelectedCat(null);
        setSelectedGen(null);
        setSelectedSize([]);
        setSelectedColour([]);
    }

    const delItem=async()=>{
        setSpinner(true);
        const res = await commonService.del_record({id: editId, table_name: 'products'});
        if(res.data.status){
            setProducts(products.filter(function( obj ) {
                return obj.id !== editId;
            }))
            setRows(products.filter(function( obj ) {
                return obj.id !== editId;
            }));
        }
        setSpinner(false);
        setOpenDialog(false);
        setMsg('Product deleted successfuly')
        setSeverity('success')
        setAlert(true)
    }

    const editItem=async(item)=>{
        console.log(item)
        setSpinner(true);
        const res = await productsService.getProductDetail({id: item.id});
        let brand = brands.filter(x => x.brand_name == res.data[0].brand_name);
        let category = categories.filter(x => x.category_name === res.data[0].category_name);
        let gender = genders.filter(x => x.gender_name === res.data[0].gender_name);
        let collection = collections.filter(x => x.col_name === res.data[0].col_name);
        let size = sizes.filter(item => JSON.parse(res.data[0].size_id).includes(item.id));
        let colour = colours.filter(item => JSON.parse(res.data[0].colour_id).includes(item.id));
        setSelectedBrand(brand[0]);
        setSelectedCol(collection[0]);
        setSelectedCat(category[0]);
        setSelectedGen(gender[0]);
        setSelectedSize(size);
        setSelectedColour(colour);
        setInputField(res.data[0]);
        setOpen(true);
        setSpinner(false);
        setBtn('Update Product');
        setEditId(item.id);
    }

    const changeFeature=async(e,id)=>{
        if(e.target.checked === true){
            setInputField({ ...inputField, [e.target.name]: e.target.checked });
            let false_id = products.filter(x=>x.pro_featured === 1);
            setSpinner(true);
            let res;
            if(false_id.length > 0){
                res = await productsService.editProductFeatured({
                    true_id : id,
                    [e.target.name] : e.target.checked,
                    false_id : false_id[0].id,
        
                });
            }else{
                res = await commonService.edit_record({
                    id : id,
                    [e.target.name] : e.target.checked,
                    table_name : 'products'
                });
            }
            setProducts(res.data);
            setRows(res.data);
            setSpinner(false);
            setInputField({})
        }else{
            setMsg('This is already featured, Please select another one to disable it.')
            setSeverity('error')
            setAlert(true)
        }
    }

    const changeSwitch=async(e,id)=>{
        setSpinner(true);
        let res = await commonService.edit_record({
                id : id,
                [e.target.name] : e.target.checked,
                table_name : 'products'
            });
        setProducts(res.data);
        setRows(res.data)
        setSpinner(false);
        setInputField({})
    }

    const fileSelectedHandler = (e) => {
        setImages(e.target.files)
    }

    const requestSearch = (searchedVal) => {
        const filteredRows = rows.filter((row) => {
          return row.order_id.toLowerCase().includes(searchedVal.toLowerCase()) 
          || row.shipping_method.toLowerCase().includes(searchedVal.toLowerCase())
          || row.status.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setSearched(searchedVal);
        setProducts(filteredRows);
    };

    return (
        <div>
            <Header />
            <ProductsView 
                products = {products}
                inputField = {inputField}
                productDetail = {productDetail}
                searched = {searched}

                handleOpen = {() => {setOpen(true); resetFields()}}
                delItem = {(id) => {setEditId(id); setOpenDialog(true)}}
                editItem = {(item) => editItem(item)}
                changeFeature = {(e,id) => changeFeature(e,id)}
                changeSwitch = {(e,id) => changeSwitch(e,id)}
                getProductDetail = {(item) => getProductDetail(item)}
                requestSearch = {(e)=>requestSearch(e)}
            />
            <AddProduct
                collections = {collections}
                brands = {brands}
                sizes = {sizes}
                colours = {colours}
                categories = {categories}
                genders = {genders}
                selectedBrand = {selectedBrand}
                selectedCat = {selectedCat}
                selectedGen = {selectedGen}
                selectedCol = {selectedCol}
                selectedSize = {selectedSize}
                selectedColour = {selectedColour}
                open = {open}
                inputField = {inputField}
                btn = {btn}
                
                fileSelectedHandler = {(e)=>fileSelectedHandler(e)}
                setSelectedCol = {(e)=>setSelectedCol(e)}
                setSelectedSize = {(e)=>setSelectedSize(e)}
                setSelectedColour = {(e)=>setSelectedColour(e)}
                setSelectedBrand = {(e)=>setSelectedBrand(e)}
                setSelectedCat = {(e)=>setSelectedCat(e)}
                setSelectedGen = {(e)=>setSelectedGen(e)}
                handleClose = {() => {setOpen(false); setBtn('Add Product')}}
                handleChange = {(e)=>handleChange(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <ConfirmDiallog
                openDialog = {openDialog}

                closeDialog = {()=>setOpenDialog(false)}
                delItem = {()=>delItem()}
            />
        </div>
    );
}
