import AxiosServices from './axios-service';
import {endpoints} from './api-end-points';

export class SearchService {

  searchBrandProducts(body){    
    const res = AxiosServices.post(endpoints.search.searchBrandProducts,body);
    return res;
  }

}

const searchService = new SearchService();
export default searchService;
