import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import router from '../routePaths/routePaths';
import { useNavigate } from 'react-router-dom';

export default function TabHeader(prop) {
  let navigate = useNavigate();
  return (
    <>
        <div className="text-end d-sm-block d-xs-block d-md-none d-lg-none d-xl-none">
            <IconButton 
            type="button"
            sx={{ position: 'absolute', right: '15px', top: '80px', padding: '2px' }}
            className="btn-black"
            onClick={prop.setLeftDrawer}>
                <ArrowCircleLeftIcon />
            </IconButton>
        </div>
        <Paper
            component="form"
            sx={{ display: 'flex', alignItems: 'center'}}
            className='search-bar'
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                className="d-none d-xl-block"
                placeholder="Search brands or products"
                onClick={prop.setOpenSearchModal}
            />
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                className="d-sm-block d-xs-block d-md-block d-lg-block d-xl-none"
                placeholder="Search brands or products"
                onClick={prop.setOpenMobileSearchModal}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
        {prop.tabData.category !== undefined && prop.tabData.category.length > 0 && 
          prop.tabData.category.map((x,index)=>{
            return(
              <Paper 
                className='mt-3 pointer'
                sx={{backgroundImage: 'url('+JSON.parse(x.pro_image)[0]+')', height:'100px'}}
                onClick={()=>navigate(router.collections+"/"+x.collection_id)}
              >
                  <div className='row'>
                  <div className="col-6 align-self-center font-bold" style={{color: '#fff'}}>{x.col_name}</div>
                  <div className="col-6 text-end">
                      <img src={JSON.parse(x.pro_image)[0]}  width='100%' height='100px' alt="loading"/>
                  </div>
                  </div>
              </Paper>
            )
          })
        }
        {prop.tabData.featured_brand !== undefined && prop.tabData.featured_brand.length > 0 && 
          <Typography variant="subtitle1" component="h2" className='mt-3 font-bold'>
              FEARURED BRANDS
          </Typography>}
        <div className='row mt-1'>
        {prop.tabData.featured_brand !== undefined && prop.tabData.featured_brand.length > 0 && 
          prop.tabData.featured_brand.map((x,index)=>{
            return(
              <div className="col-6 mt-2 pl-0">
                <Paper className='mt-1 pointer'
                  onClick={()=>navigate(router.collections+"/brand="+x.id)}
                >
                  <img src={x.brand_image} width='100%' alt={index} height= '80px'/>
                </Paper>
              </div>
            )
          })
        }
          <Button className="btn-black mt-3" onClick={()=>navigate(router.brands)}>
            VIEW ALL BRANDS
          </Button>
        </div>
    </>
  );
}
