import React, { useState, useEffect } from "react";
import productsService from "../../../../services/products-service";
import Spinner from "../../../../shared/Spinner";
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import CartMobileView from "./CartMobileView";
import CartView from "./CartView";

export default function Cart() {
    const quantity = 1;
    const color = '#a2a2a2';
    const [cartItems, setCartItems] = useState([]);
    const [related, setRelated] = useState([]);
    const [spinner, setSpinner] = useState([]);


    const getCartItem=()=>{
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if(items !== null){
            setCartItems(items);
        }
    }

    const getRelatedPro=async()=>{
        // setSpinner(true);
        let items = JSON.parse(localStorage.getItem('cartItems'));
        let unique = [...new Set(items.map(item => parseInt(item.c_id)))];
        const res = await productsService.getProductsByCol({id: unique})
        setRelated(res.data);
        setSpinner(false);
    }

    const removeItem=(index)=>{
        let array = cartItems;
        array.splice(index, 1)
        localStorage.setItem('cartItems',JSON.stringify(array));
        getCartItem();
    }

    const updateQuantity=(index,value)=>{
        let array = cartItems;
        array[index].quantity = (value === 'increase') ? array[index].quantity + 1 : array[index].quantity - 1
        array[index].total_price = (array[index].dis_price !== 0) ? 
            parseInt(array[index].dis_price)*array[index].quantity : 
            parseInt(array[index].pro_price)*array[index].quantity;
        localStorage.setItem('cartItems',JSON.stringify(array));
        getCartItem();
    }

    useEffect(()=>{
        getCartItem();
        getRelatedPro();
    },[])
    
    return (
        <div>
            <Header 
                color={color}
            />
            <CartView
                cartItems={cartItems}
                quantity={quantity}
                related={related}
                
                updateQuantity={(index,value)=>updateQuantity(index,value)}
                removeItem={(index)=>{removeItem(index)}}
            />
            <CartMobileView
                cartItems={cartItems}
                quantity={quantity}
                related={related}
                
                updateQuantity={(index,value)=>updateQuantity(index,value)}
                removeItem={(index)=>{removeItem(index)}}
            />
            <Footer/>
            <Spinner
                spinner = {spinner}
            />
        </div>
    );
}
