import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {
    Paper, Button,TextField,Autocomplete,
    ListItemButton,List,ListItemText,Collapse,
    Table, TableBody,TableHead, TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Facebook,Twitter,Instagram,Pinterest} from '@mui/icons-material';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as Slider } from 'react-responsive-carousel';
import Carousel from "react-multi-carousel";
import router from "../../../../routePaths/routePaths";
import logoSpinner from "../../../../assets/images/logo/logo_spinner.png";

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export default function ProductView(props) {
    let navigate = useNavigate();
    const [isOpen, setIsOpen] = useState([
        {id:1, isOpen: true},
        {id:2, isOpen: false},
        {id:3, isOpen: false},
        {id:4, isOpen: false},
        {id:5, isOpen: false},
        {id:6, isOpen: false},
    ])

    const handleClick = (item) =>{
        setIsOpen(isOpen.map(e => e.id === item.id ? ({...e, isOpen: !e.isOpen} ) : (e)))
        }
    useEffect(async()=>{

    },[])

    return (
        <div className="container py-3" style={{height: (props.product.length === 0) ? '100vh' : ''}}>
            {props.product.length > 0 && <div className="row">
                <div className="col-md-8">
                    <Slider>
                            {props.product.length > 0 && props.product[0].pro_image.map((x,y)=>{
                                return(
                                    <div>
                                        <img src={x} height="500px" alt="laoding"/>
                                        <p className="legend">{props.product[0].brand_name}</p>
                                    </div>
                                )
                            })}
                    </Slider>
                </div>
                <div className="col-md-4">
                    <img src={props.product[0].brand_image} width={'30%'} height="50px" alt="laoding"/>
                    <h5 className="font-bold black-outline">{props.product[0].pro_name}</h5>
                    <div className="sale-price">
                        {props.product[0].dis_price === '0' && <p style={{margin: 0}}>Rs. {props.product[0].pro_price} /- </p>}
                        {props.product[0].dis_price !== '0' && <span>Rs. {props.product[0].dis_price} /- </span>}
                        {props.product[0].dis_price !== '0' && <del>Rs. {props.product[0].pro_price} /-</del>}
                        {props.product[0].items_in_stock === '0' && <p className="sold my-1 fit-content">SOLD OUT</p>}
                    </div>
                    <form onSubmit={props.addToCart}>
                        <div className="row">
                            <div className='col-12 text-center p-0'>
                                <Autocomplete
                                    id="combo-box-demo" size="small"
                                    options={props.product[0].colours}
                                    getOptionLabel={(option) => option.colour_name}
                                    name="colour_name"
                                    className='mt-2'
                                    value={props.selectedColour}
                                    onChange={(event, newValue)=>props.setSelectedColour(newValue)}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // variant="standard"
                                        label="Colours"
                                        placeholder="Colours"
                                    />
                                    )}
                                />
                            </div>
                            <div className='col-12 text-center p-0'>
                            <Autocomplete
                                id="combo-box-demo" size="small"
                                options={props.product[0].sizes}
                                getOptionLabel={(option) => option.size_name}
                                name="size_name"
                                className='mt-2'
                                value={props.selectedSize}
                                onChange={(event, newValue)=>props.setSelectedSize(newValue)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    // variant="standard"
                                    label="Size"
                                    placeholder="Size"
                                    required
                                />
                                )}
                            />
                            </div>
                            {/* <span>Get an in instant refund if you return</span> */}
                        </div>
                        <Button 
                            type="submit"
                            className="btn-green width-100 mt-3 font-bold"
                            disabled={props.product[0].items_in_stock === "0"}>
                                ADD TO CART
                        </Button>
                        <Button
                            type="submit"
                            className="btn-black width-100 mt-3 font-bold"
                            disabled={props.product[0].items_in_stock === "0"}
                            onClick={props.buyNow}>
                                BUY IT NOW
                        </Button>
                    </form>
                    <Paper elevation={1} sx={{mt:2}}>
                        <ListItemButton onClick={() => handleClick(isOpen[0])}>
                            <ListItemText primary={'Detail'} />
                            {isOpen[0].isOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={isOpen[0].isOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ul style={{listStyle: 'none', margin: 0, padding: 0, fontSize: '13px'}}>
                                        <li>{props.product[0].pro_description}</li>
                                        <li>Brand: {props.product[0].brand_name}</li>
                                        <li>Collection: {props.product[0].col_name}</li>
                                        <li>
                                            Available Size: {props.product[0].sizes.map((y,index)=>{
                                                return(
                                                    <>
                                                        <i>{y.size_name}</i>
                                                        {index !== props.product[0].sizes.length-1 && <i>, </i>}
                                                    </>
                                                )
                                            })}
                                        </li>
                                        <li>
                                            Available Colors: {props.product[0].colours.map((y,index)=>{
                                                return(
                                                    <>
                                                        <i>{y.colour_name}</i>
                                                        {index !== props.product[0].colours.length-1 && <i>, </i>}
                                                    </>
                                                )
                                            })}
                                        </li>
                                    </ul>
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => handleClick(isOpen[1])}>
                            <ListItemText primary={'Delivery'} />
                            {isOpen[1].isOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={isOpen[1].isOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>SHIPPING METHOD</StyledTableCell>
                                            <StyledTableCell>COST</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{fontSize:'13px'}}>
                                                Pay by <i>VISA</i> or <i>MASTER</i> Card
                                            </TableCell>
                                            <TableCell sx={{fontSize:'13px'}}>
                                                Rs. 0.00 /-
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{fontSize:'13px'}}>
                                                Cash on Delivery
                                            </TableCell>
                                            <TableCell sx={{fontSize:'13px'}}>
                                                Rs. 0.00 /- 
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => handleClick(isOpen[2])}>
                            <ListItemText primary={'Share'} />
                            {isOpen[2].isOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={isOpen[2].isOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton>
                                    <Facebook sx={{fontSize: '17px'}}/> <Twitter sx={{fontSize: '17px'}}/> <Pinterest sx={{fontSize: '17px'}}/> <Instagram sx={{fontSize: '17px'}}/>
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton>
                            <ListItemText primary={'Product Code: kk-'+props.product[0].p_id} />
                        </ListItemButton>
                    </Paper>
                </div>
            </div>}
            <h4 className="font-bold black-outline m-0 my-4">
                <img src={logoSpinner} width="50px" alt='loading'/>
            </h4>
            {props.product.length > 0 && <div className="row">
                <Paper elevation={0} square>
                    <Carousel 
                        autoPlay={false}
                        responsive={responsive}>
                            {props.product[0].related.map((y,z)=>{
                                return(
                                    <Paper elevation={1} sx={{m: 2}} square>
                                        <div className="text-center pointer" onClick={()=>navigate(router.product+"/"+y.id)}>
                                            <div className="center-image" style={{height: "200px"}}>
                                                <img src={JSON.parse(y.pro_image)[0]} width='100%' alt={y}/>
                                            </div>
                                            <div className="p-2">
                                                <h5 className="text-nowrap text-truncate">{y.pro_name}</h5>
                                                <div className="sale-price">
                                                    {y.dis_price === '0' && <p style={{margin: 0}}>Rs. {y.pro_price} /- </p>}
                                                    {y.dis_price !== '0' && <span>Sale Rs. {y.dis_price} /- </span>}
                                                    {y.dis_price !== '0' && <del>Rs. {y.pro_price} /-</del>}
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                )
                            })}
                    </Carousel>
                </Paper>
            </div>}
        </div>
    );
}
