import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {
    Paper, Chip, InputLabel, MenuItem, FormControl, 
    Select, Button, IconButton, Modal, Box,
    InputBase,  Divider, Stack, CircularProgress,Typography
} from '@mui/material';
import {FilterList } from '@mui/icons-material';
import Filter from "./Filter";
import logoSpinner from "../../../../assets/images/logo/logo_spinner.png"
import router from "../../../../routePaths/routePaths";

const modelStyle = {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -0%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
    height:'100%'
  };

export default function CollectionsListView(props) {
    let navigate = useNavigate();
    const [sortBy, setSortBy] = useState(0);
    const [openFilterModal, setOpenFilterModal] = useState(false);

    const handleChange = (e) => {
        setSortBy(e.target.value);
        props.handleSorted(e.target.value)
    };

    useEffect(async()=>{
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    },[])

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
    
    const onScroll = e => {
        const wrappedElement = document.getElementById('scrollDiv');
        if (isBottom(wrappedElement)) {
            props.bottomReached();
        }
    };

    return (
        <div className="container">
            {props.products.length > 0 && <h3 className="my-4 text-center font-bold">
                {(props.id === 'sale') ? 'SHOP SALE' : (props.id === 'exclusive_heat') ? 'SHOP EXLUSIVE HEAT' : (props.id.includes("=")) ? 'SHOP BRANDS' : props.products[0].col_name}
            </h3>}
            <Divider />
            <div className="row">
                <div className="col-md-3 d-none d-md-block d-lg block d-xl-block">
                    <Filter 
                        id = {props.id}
                        filtersTable = {props.filtersTable}
                        handleFilter = {props.handleFilter}
                    />
                </div>
                <div className="col-md-9 my-3" id='scrollDiv'>
                    <div className="d-none d-md-block d-lg block d-xl-block">
                        <div className="row">
                            <div className="col-8 align-self-center">
                                {/* <p className="text-end" style={{lineHeight: 3}}>123123 items found</p> */}
                                <Stack direction="row" spacing={1}>
                                    {props.filterOptions.length > 0 && props.filterOptions.map((x)=>{
                                        return(
                                            <Chip label={x.name} variant="outlined" onDelete={()=>props.handleDelete(x)} />
                                        )
                                    })}
                                    {props.filterOptions.length > 0 && 
                                        <Chip label="Clear All" variant="outlined" onDelete={()=>props.clearFilter()} />
                                    }
                                </Stack>
                            </div>
                            <div className="col-4">
                                <FormControl sx={{width:'300px'}} size="small">
                                    <InputLabel id="demo-simple-select-label">Sort By:</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={sortBy}
                                        label="Sort By:"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>Date: New to Old</MenuItem>
                                        <MenuItem value={1}>Date: Old to New</MenuItem>
                                        <MenuItem value={2}>Price: Low to High</MenuItem>
                                        <MenuItem value={3}>Price: High to Low</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end d-sm-block d-xs-block d-md-none d-lg-none d-xl-none">
                        <Paper
                            component="form"
                            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Filter & Sort"
                                onClick={()=>setOpenFilterModal(true)}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} onClick={()=>setOpenFilterModal(true)}>
                                <FilterList />
                            </IconButton>
                        </Paper>
                    </div>
                    <div className="row text-center">
                        {props.products.map((x,index)=>{
                            return(
                                <div className="col-md-4 mt-3 pointer" onClick={()=>navigate(router.product+'/'+x.p_id)}>
                                    <div style={{height: '320px'}} className="center-image">
                                        <div className="center-left">
                                            <ul>
                                                {x.sizes.map(y=>{
                                                    return(
                                                        <li>{y.size_name}</li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        {x.dis_price !== "0" && <div className="right-top">
                                            <span>SAVE {(x.pro_price-(x.dis_price/x.pro_price*100)).toFixed(1)}%</span>
                                        </div>}
                                        {x.items_in_stock === "0" && <div className="right-left">
                                            <span>SOLD OUT</span>
                                        </div>}
                                        <img 
                                            src={JSON.parse(x.pro_image)[0]} 
                                            onMouseOver={e => (e.currentTarget.src = (JSON.parse(x.pro_image)[1] || JSON.parse(x.pro_image)[0]))}
                                            onMouseOut={e => (e.currentTarget.src = JSON.parse(x.pro_image)[0])} 
                                            width='100%' 
                                            alt='loading'
                                        />
                                    </div>
                                    <div className="center-image my-2" style={{height: '80px'}}>
                                        <img src={x.brand_image} width='50%' alt='loading'/>
                                    </div>
                                    <h6 className="text-nowrap text-truncate">{x.pro_name}</h6>
                                    <div className="sale-price">
                                        {x.dis_price === '0' && <p style={{margin: 0}}>Rs. {x.pro_price} /- </p>}
                                        {x.dis_price !== '0' && <span>Sale Rs. {x.dis_price} /- </span>}
                                        {x.dis_price !== '0' && <del>Rs. {x.pro_price} /-</del>}
                                    </div>
                                    <Button 
                                        className="btn-black width-90" 
                                        onClick={()=>navigate(router.product+'/'+x.p_id)}
                                        disabled={x.items_in_stock === "0"}>
                                        ADD TO CART
                                    </Button>
                                </div>
                            )
                        })}
                    </div>
                    {props.isLoading && <div className="text-center" style={{width:'100%'}}>
                        <Box position="relative" display="inline-flex">
                            <CircularProgress sx={{color: '#734d03'}}/>
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="caption" component="div" color="textSecondary">
                                    <img src={logoSpinner} width= '50px' alt='loading'/>
                                </Typography>
                            </Box>
                        </Box>
                    </div>}
                </div>
            </div>
            <Modal
                backdrop="static"
                open={openFilterModal}
                onClose={()=>setOpenFilterModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock={true}
                disableEnforceFocus={true}
                disableAutoFocus={true}
                disableRestoreFocus={true}
                // sx={{top:'50%'}}
            >
                <Box sx={modelStyle}>
                    <Filter
                        filtersTable = {props.filtersTable}
                        handleFilter = {props.handleFilter}
                        id = {props.id}
                        sortBy = {sortBy}

                        setOpenFilterModal = {()=>setOpenFilterModal(false)}
                        handleChange = {(e)=>handleChange(e)}
                    />
                </Box>            
            </Modal>
        </div>
    );
}
